import {useCallback, useEffect, useState} from 'react';
import { useLocale } from './useLocale';
import { useApiRepository } from './useApiRepository';

export function useRecord(entity: CameraRecord): [string, boolean, IArchiveEntry | null] {
  const [archiveEntry, setArchiveEntry] = useState<IArchiveEntry | null>(null);
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const locale = useLocale()
  const { api } = useApiRepository()

  const setArchiveEntity = useCallback((record: CameraRecord): IArchiveEntry => {
    return {
      entity: record,
      getDvrTimelines: () => new Promise(() => [{start: 0, duration: 1}])
    }
  }, [])

  useEffect(() => {
    let mounted = true
    setLoading(true)

    const updateArchiveEntry = (record: CameraRecord) => {
      if (!mounted) return

      if (!record) {
        setError(locale.archiveIsUnavailable)
        setArchiveEntry(null)
        return
      }

      setArchiveEntry(setArchiveEntity(record))
    }

    if (!('getEntitiesWithGlobalBatch' in api)) {
      setError(locale.archiveIsUnavailable)
      setArchiveEntry(null)
      throw new Error('api-repo method "getEntitiesWithGlobalBatch" in not defined')
    }

    api
      .getEntitiesWithGlobalBatch(
        { id: entity.id, type: entity.type },
        ['streamUrl'],
        'player-archive',
      )
      .then((res) => updateArchiveEntry({ ...entity, ...res }))
      .finally(() => mounted && setLoading(false))

    return () => {
      mounted = false
    }
  }, [api, entity, locale, setArchiveEntity])

  return [error, loading, archiveEntry];
}
