/** @jsx jsx */
import {jsx} from '@emotion/react';
import {makeButton} from './makeButton';

export const Time = makeButton((props) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16 4C9.3832 4 4 9.3832 4 16C4 22.6168 9.3832 28 16 28C22.6168 28 28 22.6168 28 16C28 9.3832 22.6168 4 16 4ZM16 25.6C10.7068 25.6 6.4 21.2932 6.4 16C6.4 10.7068 10.7068 6.4 16 6.4C21.2932 6.4 25.6 10.7068 25.6 16C25.6 21.2932 21.2932 25.6 16 25.6Z"
        fill="white"
      />
      <path d="M17.2 10H14.8V17.2H22V14.8H17.2V10Z" fill="#959DA8" />
    </svg>
  );
});
