/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {FC, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {useGetColor} from './useColors';
import {IEventModel} from './models';
import {cx} from '../../utils/cx';
import {get} from 'lodash-es';
import {useStore} from '../../hooks/useStore';
import {secondsToHms} from '../controls/utils';
import { E2EModule } from '../../__test__';

type EventDescriptionProps = JSX.IntrinsicElements['div'] & {
  event: IEventModel;
  selected?: boolean;
  useGroups: boolean;
  eventExtraProps: {property: string; title: string}[];
};

export const EventDescription: FC<EventDescriptionProps> = observer(function EventDescription({
  event,
  eventExtraProps = [],
  selected = false,
  useGroups,
  ...rest
}) {
  const store = useStore();
  const getColor = useGetColor();
  let {eventType, group, status, timestamp, endAt} = event;
  const startDate = useMemo(() => new Date(timestamp), [timestamp]);
  const endDate = useMemo(() => (endAt ? new Date(endAt) : undefined), [endAt]);
  const extras = eventExtraProps.map(({property, title}) => {
    const value = get(event, `originalEvent.${property}`);
    return (
      <div key={property} title={`${title}: ${value}`} css={[$extraProps, $ellipsis]}>
        {title}: <b>{value}</b>
      </div>
    );
  });
  let time = '';
  if (store.isDateTape) {
    time = !!endDate
      ? `${startFormatter.format(startDate)} - ${endFormatter.format(endDate)}`
      : startFormatter.format(startDate);
  } else {
    time = !!endAt
      ? `${secondsToHms(timestamp / 1000)} - ${secondsToHms(endAt / 1000)}`
      : secondsToHms(timestamp / 1000);
  }
  return (
    <div data-cy={E2EModule.attributes.eventItem} {...rest} css={$container} data-selected={selected} data-role={'button'}>
      <div css={$header}>
        <div>
          <div title={eventType.title} css={[$eventType, $ellipsis]}>
            {eventType.title}
          </div>
          {useGroups && (
            <div
              title={group?.title}
              css={[$groupTitle, $ellipsis]}
              style={group?.id ? {color: getColor(group?.id)} : undefined}>
              {group?.title || 'Аналитика 1'}
            </div>
          )}
          {extras}
        </div>
        <div>
          <i className={cx([eventType.icon, 'mdi-24px'])} css={$eventIcon} />
        </div>
      </div>
      <div css={$footer}>
        <div title={status.title} css={[$status, $ellipsis]} style={{color: status.color}}>
          {status.title}
        </div>
        <div css={$time}>{time}</div>
      </div>
    </div>
  );
});

const $container = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  position: relative;
  padding: calc(10rem / var(--bfs)) calc(15rem / var(--bfs));
  background: var(--surface-d);
  border-radius: var(--border-radius-sm);

  box-shadow: var(--shadow-raised);

  color: var(--text-color-secondary);

  & > div:first-of-type {
    flex-grow: 1;
  }

  &:hover {
    color: var(--text-color);
    cursor: pointer;
    padding: calc(8rem / var(--bfs)) calc(13rem / var(--bfs));
    border: calc(2rem / var(--bfs)) solid var(--primary-color);
  }

  &[data-selected='true'] {
    color: var(--text-color);
    background: var(--surface-c);
    padding: calc(8rem / var(--bfs)) calc(13rem / var(--bfs));
    border: calc(2rem / var(--bfs)) solid var(--primary-color);
  }
`;

const $header = css`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
`;

const $footer = css`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const $ellipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const $eventType = css`
  font-size: var(--font-size);
  margin-bottom: calc(var(--spacer-xs) / 2);
`;

const $eventIcon = css`
  height: var(--spacer-sm);
`;

const $groupTitle = css`
  font-size: var(--font-size-sm);
  opacity: 0.87;
  margin-bottom: var(--spacer-xs);
`;

const $extraProps = css`
  font-size: var(--font-size-sm);
  margin-bottom: calc(var(--spacer-xs) / 2);

  font-weight: 400;

  & b {
    font-weight: 500;
  }
`;

const $status = css`
  font-size: var(--font-size-sm);
  opacity: 0.87;
  margin-top: calc(var(--spacer-xs) / 2);
`;

const $time = css`
  font-size: calc(var(--font-size-sm) - 2rem / var(--bfs));
`;

const startFormatter = new Intl.DateTimeFormat('ru-ru', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric'
});

const endFormatter = new Intl.DateTimeFormat('ru-ru', {
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric'
});
