import React, {createElement, FC} from 'react';
import {IStore} from '../StoreModel';

const storeContext = React.createContext<IStore>(null!);

export function useStore() {
  return React.useContext(storeContext);
}

export const StoreProvider: FC<{value: IStore}> = ({value, children}) => {
  return createElement(storeContext.Provider, {value}, children);
};
