/** @jsx createElement */
import {createElement, ReactElement, useEffect, useRef, useState} from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export const SimpleVirtualList: <T>(props: {
  className?: string;
  items: T[];
  size: number;
  children: (item: T, top: number) => ReactElement | null;
}) => ReactElement = ({className, items, size, children}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [window, setWindow] = useState({top: 0, bottom: 0});
  const isEmpty = items.length === 0;
  useEffect(() => {
    if (isEmpty) {
      return undefined;
    }
    const el = ref.current;
    if (!el) {
      return undefined;
    }
    let rafId = 0;
    const updateWindow = () => {
      if (rafId !== 0) {
        cancelAnimationFrame(rafId);
      }
      rafId = requestAnimationFrame(() => {
        rafId = 0;
        setWindow({top: el.scrollTop, bottom: el.scrollTop + el.clientHeight});
      });
    };
    el.addEventListener('scroll', updateWindow, {passive: true});
    const resizeOb = new ResizeObserver(updateWindow);
    resizeOb.observe(el);
    return () => {
      resizeOb.unobserve(el);
      resizeOb.disconnect();
      // @ts-ignore
      el.removeEventListener('scroll', updateWindow, {passive: true});
    };
  }, [isEmpty]);
  const startIndex = Math.floor(window.top / size);
  const endIndex = Math.ceil(window.bottom / size);
  return (
    <div className={className} ref={ref} style={{overflow: 'scroll', height: '100%'}}>
      <div style={{position: 'relative', height: items.length * size}}>
        {items.slice(startIndex, endIndex).map((item, index) => children(item, (startIndex + index) * size))}
      </div>
    </div>
  );
};
