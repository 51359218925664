import {createCamerasConnection, IEntity, listEntities} from '@netvision/lib-api-gateway';

export const loadEntitiesAsMap = <E extends IEntity>(
  type: E['type'],
  getKey: (en: E) => string,
  refine?: (en: E) => E
): Promise<Map<string, E>> => {
  return listEntities<E>(createCamerasConnection(), {type, limit: 1000})
    .then((res) => {
      let entities = res.results;
      if (typeof refine === 'function') {
        entities = res.results.map(refine);
      }
      return new Map(entities.map((en) => [getKey(en), en]));
    })
    .catch(() => new Map());
};
