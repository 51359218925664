import {IBboxTrack} from '../models';

export const findTrack = (tracks: IBboxTrack[], time: number) => {
  const maxIndex = binaryFindClosestRight(tracks, compare(time));
  if (maxIndex === -1) {
    return undefined;
  } else if (maxIndex === 0 || maxIndex === 1) {
    return tracks[maxIndex];
  } else {
    const min = tracks[maxIndex - 1];
    const max = tracks[maxIndex];
    return Math.abs(max.timestamp - time) < Math.abs(min.timestamp - time) ? max : min;
  }
};

const compare = (time: number) => (track: IBboxTrack) => track.timestamp - time;

const binaryFindClosestRight = <T>(array: T[], compare: (a: T) => number) => {
  let start = 0;
  let end = array.length - 1;

  let middle = -1;

  while (start <= end) {
    middle = Math.floor((start + end) / 2);
    let res = compare(array[middle]);

    if (res === 0) {
      break;
    } else if (res < 0) {
      start = middle + 1;
    } else {
      end = middle - 1;
    }
  }

  return middle;
};
