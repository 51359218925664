/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {FC, Fragment, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '../../hooks/useStore';
import {Blur} from '../controls/tape/Blur';
import {useGetColor} from './useColors';
import {useHelperStore} from './useHelperStore';
import {IEventModel} from './models';

const AGG_THRESHOLD = 40;

export const EventMarks: FC<{
  events: Pick<IEventModel, 'id' | 'timestamp' | 'endAt' | 'group'>[];
}> = observer(function EventMarks({events}) {
  const store = useStore();
  const {tapeOffset, scale} = store;
  const items = useMemo(() => {
    return aggregateEventsByStart(events, (ev) => (ev.timestamp - tapeOffset) / scale, AGG_THRESHOLD);
  }, [events, tapeOffset, scale]);
  const getColor = useGetColor();
  const {hovered, selected} = useHelperStore();
  return (
    <Fragment>
      {items.map((events) => {
        const firstEvent = events[0];
        const start = (firstEvent.timestamp - tapeOffset) / scale;
        const longestEvent = events.slice().sort((a, b) => (b.endAt || b.timestamp) - (a.endAt || a.timestamp))[0];
        const end = calcEventEnd(longestEvent, tapeOffset, scale, AGG_THRESHOLD / 4);
        const width = Math.max(end - start, AGG_THRESHOLD / 4);
        const highlighted = hovered !== selected && events.findIndex((ev) => hovered === ev.id) !== -1;
        const color = getGroups(events).length > 1 ? 'var(--warning-color)' : getColor(firstEvent.group?.id ?? '');
        return (
          <div
            css={eventMarkStyle}
            data-highlighted={highlighted}
            key={firstEvent.id + events.length}
            style={{
              left: start,
              width
            }}>
            {events.length > 1 && <div data-role={'counter'}>{events.length}</div>}
            <Blur color={color} />
          </div>
        );
      })}
    </Fragment>
  );
});

const getGroups = (events: Pick<IEventModel, 'group'>[]) => {
  return Array.from(
    events.reduce((acc, ev) => {
      acc.add(ev.group?.id ?? '');
      return acc;
    }, new Set())
  );
};

function aggregateEventsByStart<P>(
  events: P[],
  calculateStart: (event: P) => number,
  threshold: number
): Array<Array<P>> {
  let prevStart = -Infinity;
  return events.reduce((acc, ev) => {
    const currentStart = calculateStart(ev);
    if (currentStart - prevStart < threshold) {
      acc[acc.length - 1].push(ev);
    } else {
      acc.push([ev]);
      prevStart = currentStart;
    }
    return acc;
  }, [] as Array<P[]>);
}

function calcEventEnd(
  event: Pick<IEventModel, 'timestamp' | 'endAt'>,
  tapeOffset: number,
  scale: number,
  baseWidth: number
) {
  return !!event.endAt ? (event.endAt - tapeOffset) / scale : (event.timestamp - tapeOffset) / scale + baseWidth;
}

// language=SCSS
const eventMarkStyle = css`
  & {
    position: absolute;
    height: 100%;
    pointer-events: none;
  }
  &[data-highlighted='true'] {
    box-shadow: 0 0 0 calc(1rem / var(--bfs)) var(--primary-color);
  }
  & > div[data-role='counter'] {
    position: absolute;
    top: calc(4rem / var(--bfs));
    left: 50%;
    transform: translateX(-50%);

    background: rgba(var(--primary-color-raw), 0.3);
    border: calc(1rem / var(--bfs)) solid var(--primary-color);
    height: calc(20rem / var(--bfs));
    width: calc(20rem / var(--bfs));
    border-radius: 50%;

    font-size: calc(10rem / var(--bfs));
    font-weight: 600;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
