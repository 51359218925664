import {defaultLocale, Locale} from '../locale';
import React, {FC, useContext} from 'react';

const LocaleContext = React.createContext(defaultLocale);
export function useLocale(): Locale {
  return useContext(LocaleContext);
}

export const LocaleProvider: FC<{value?: Locale}> = ({value = defaultLocale, children}) => {
  return React.createElement(LocaleContext.Provider, {value}, children);
};
