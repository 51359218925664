/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useEvents} from './useEvents';
import {useStore} from '../../hooks/useStore';
import {EventMarks} from './EventMarks';
import {ColorProvider} from './useColors';
import {createPortal} from 'react-dom';
import {EventDescription} from './EventDescription';
import {observer} from 'mobx-react-lite';
import emptyListImg from '../../assets/empty-list.svg';
import {useLocale} from '../../hooks/useLocale';
import {WithPopup} from '../ui/WithPopup';
import {rem} from '../../utils/rem';
import {SimpleVirtualList} from '../ui/SimpleVirtualList';
import {HelperStoreProvider, useHelperStore} from './useHelperStore';
import {reaction} from 'mobx';
import {Button} from 'primereact/button';
import {useCanvas} from './canvas/useCanvas';
import {IEventExtraPropDesc, IEventModel} from './models';
import {useEventFilters} from './useEventFilters';
import {cx} from '../../utils/cx';
import {ProgressBar} from 'primereact/progressbar';
import {selectAdapter} from './eventAdapters/selectAdapter';
import { E2EModule } from '../../__test__';

export interface IEventsProps {
  cameraId: string;
  marksPortalNode: HTMLElement;
  eventExtraProps: Array<IEventExtraPropDesc>;
  eventEntityType: string;
  cameraField: string;
  entity: IArchiveEntry['entity'];
}

export const Events: FC<IEventsProps> = observer(
  ({marksPortalNode, cameraId, cameraField, eventEntityType, eventExtraProps, entity}) => {
    const store = useStore();
    const locale = useLocale();
    const eventAdapter = useMemo(() => {
      return selectAdapter(eventEntityType)(locale.eventStatusLocales[eventEntityType]);
    }, [eventEntityType, locale]);
    const [loading, allEvents] = useEvents(store, eventAdapter, {
      cameraId,
      cameraField,
      eventEntityType,
      entityType: entity.type
    });
    const {filtersActive, filteredEvents, filterViews} = useEventFilters(allEvents, eventExtraProps);
    const {
      totalVisibleRange: [visibleStart, visibleEnd]
    } = store;
    const visibleEvents = useMemo(() => {
      return filteredEvents.filter((ev) => visibleStart < (ev.endAt ? ev.endAt : ev.timestamp) && ev.timestamp < visibleEnd);
    }, [filteredEvents, visibleStart, visibleEnd]);
    const filterOpen = useState(false);
    const [, setOpen] = filterOpen;
    useEffect(() => {
      if (visibleEvents.length === 0 && filterViews.length === 0) {
        setOpen(false);
      }
    }, [visibleEvents.length, filterViews.length, setOpen]);
    let emptyMessage = null;
    if (!loading && visibleEvents.length === 0) {
      emptyMessage = (
        <div css={$emptyMsg}>
          {/* <img alt={''} src={emptyListImg} /> */}
          <h3 className={cx(['p-mt-3', 'p-pt-3'])}>{locale.panelLocale.emptyEvents}</h3>
        </div>
      );
    }
    return (
      <HelperStoreProvider>
        <ColorProvider>
          <header css={$header}>
            <h3 data-cy={E2EModule.attributes.eventWrapperTitle}>{locale.panelLocale.eventsHeader}</h3>
            <WithPopup
              css={$block}
              button={
                <Button
                  data-cy={E2EModule.attributes.filterEventButton}
                  className={cx(['p-button-outlined', !filtersActive && 'p-button-secondary'])}
                  css={$filterButton}
                  icon={'mdi mdi-20px mdi-filter-variant'}
                  label={locale.panelLocale.filterLabel}
                  disabled={allEvents.length === 0 || loading}
                />
              }
              popup={
                <div className={'p-raised p-fluid p-input-filled'} css={$popup}>
                  {filterViews}
                </div>
              }
              state={filterOpen}
            />
          </header>
          {loading && <ProgressBar mode="indeterminate" css={$progressBar} />}
          <EventList
            eventExtraProps={eventExtraProps}
            emptyMessage={emptyMessage}
            events={visibleEvents}
            useGroups={eventAdapter.settings.useGroups}
          />
          {createPortal(<EventMarks events={visibleEvents} />, marksPortalNode)}
        </ColorProvider>
      </HelperStoreProvider>
    );
  }
);

const $header = css`
  display: flex;
  align-items: center;
  margin-bottom: var(--spacer-sm);
  margin-right: var(--input-height);

  & > h3 {
    flex-grow: 1;
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: var(--font-size-h3);
    color: var(--text-color);
  }

  & > * {
    margin-right: var(--spacer-sm);
  }
`;

const $filterButton = css`
  &[data-active='true'] {
    background: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
    color: var(--text-color-active) !important;
  }
`;

const $block = css`
  display: block;
`;

const $popup = css`
  width: calc(270rem / var(--bfs));
  position: absolute;
  z-index: 2;
  padding: calc(20rem / var(--bfs));
  top: calc(100% + 5rem / var(--bfs));
  right: calc(-60rem / var(--bfs));
  border-radius: var(--border-radius);
  background: var(--surface-b);

  > .p-field {
    margin-bottom: var(--spacer-sm);
  }
  > .p-field:last-child {
    margin-bottom: 0;
  }
`;

const EventList: FC<{
  emptyMessage: ReactNode;
  events: IEventModel[];
  eventExtraProps: IEventExtraPropDesc[];
  useGroups: boolean;
}> = observer(({emptyMessage, events, eventExtraProps, useGroups}) => {
  const store = useStore();
  const helperStore = useHelperStore();
  const prevScaleRef = useRef(store.scale);
  const onClick = useCallback(
    (e) => {
      e.preventDefault();
      const id = e.currentTarget.dataset['id'];
      if (helperStore.selected === id) {
        helperStore.setSelected('');
        store.setScale(prevScaleRef.current);
      } else {
        prevScaleRef.current = store.scale;
        helperStore.setSelected(id);
      }
    },
    [store, helperStore]
  );
  const {selected} = helperStore;
  const event = events.find((ev) => ev.id === selected);
  useCanvas(event);
  useEffect(() => {
    const event = events.find((ev) => ev.id === selected);
    if (event) {
      const start = new Date(event.timestamp).getTime();
      const end = event.endAt && new Date(event.endAt).getTime();
      if (store.findRangeIndex(start) !== -1) {
        store.setScale(end ? (end - start) / (store.width / 4) : 25);
      }
      store.setCurrentTime(start);
      if (store.isExportable) {
        store.createExportRegion(start);
        store.setExportEnd(end ?? start + 2000);
        return reaction(
          () => store.exportStart,
          () => {
            helperStore.setSelected('');
          }
        );
      }
    } else {
      store.setSelectionMode(false);
    }
    return undefined;
    // eslint-disable-next-line
  }, [selected]);
  useEffect(() => {
    return reaction(
      () => store.selectionMode,
      (mode) => {
        if (!mode) {
          helperStore.setSelected('');
        }
      }
    );
  }, [store, helperStore]);
  const onPointerEnter = useCallback(
    (e) => {
      e.preventDefault();
      const id = e.currentTarget.dataset['id'];
      helperStore.setHovered(id);
    },
    [helperStore]
  );
  const onPointerLeave = useCallback(
    (e) => {
      e.preventDefault();
      const id = e.currentTarget.dataset['id'];
      if (helperStore.hovered === id) {
        helperStore.setHovered('');
      }
    },
    [helperStore]
  );
  const eventDescriptionHeight = (useGroups ? 92 : 69) + eventExtraProps.length * 18;
  return (
    <main style={{height: store.height - rem(eventDescriptionHeight + 30)}}>
      {emptyMessage}
      <SimpleVirtualList css={$list} items={events} size={rem(eventDescriptionHeight)}>
        {(item, top) => (
          <EventDescription
            useGroups={useGroups}
            eventExtraProps={eventExtraProps}
            key={item.id}
            data-id={item.id}
            selected={selected === item.id}
            onClick={onClick}
            event={item}
            style={{position: 'absolute', zIndex: 0, top}}
            onPointerEnter={onPointerEnter}
            onPointerLeave={onPointerLeave}
          />
        )}
      </SimpleVirtualList>
    </main>
  );
});

const $list = css`
  mask-image: linear-gradient(rgb(0, 0, 0) 61.8%, rgba(0, 0, 0, 0) 100%);
  width: calc(100% + 40rem / var(--bfs));
  margin: calc(-5rem / var(--bfs)) calc(-16rem / var(--bfs)) 0 calc(-20rem / var(--bfs));
  padding: calc(10rem / var(--bfs)) calc(16rem / var(--bfs)) calc(80rem / var(--bfs)) calc(20rem / var(--bfs));
`;

const $emptyMsg = css`
  height: 0;
  & > h3 {
    margin: 0;
    padding: 0;
    font-size: calc(14rem / var(--bfs));
    font-weight: 500;
    color: var(--text-color-secondary);
    text-align: center;
  }
  & > img {
    display: block;
    position: relative;
    width: 60%;
    left: 20%;
  }
`;

const $progressBar = css`
  position: absolute;
  width: calc(100% - 40rem / var(--bfs));
  height: calc(1rem / var(--bfs));
  left: calc(20rem / var(--bfs));
  top: calc(74rem / var(--bfs));
`;
