import {createContext, createElement, FC, useContext} from 'react';
import {IWidgetProps, OverlayParams} from '../../../IWidgetProps';
import {ICanvasOverrideStyles} from './defaults';

export type ICanvasMountParams = OverlayParams &
  Pick<IWidgetProps, 'mountChildren' | 'areas'> & {canvasStyles?: ICanvasOverrideStyles};

const CanvasMountParamsContext = createContext<() => ICanvasMountParams>(null!);

export const CanvasMountParamsProvider: FC<{value: () => ICanvasMountParams}> = (props) => {
  return createElement(CanvasMountParamsContext.Provider, props);
};

export const useCanvasMountParams = () => {
  return useContext(CanvasMountParamsContext);
};
