import { getStreamParams } from '../../requests/stream'
import { createDisposers } from '../../utils/disposers'
import { debounce } from 'lodash-es'
import { onAction } from 'mobx-state-tree'
import { reaction } from 'mobx'
import { IStore } from '../../StoreModel'
import { convertToStoreTime } from './utils'
import Hls from 'hls.js'
import Plyr from 'plyr'

type Range = { start: number; duration: number }

const createHls = (
  video: HTMLVideoElement,
  hlsStreamUrl: string | undefined,
  entity: IArchiveEntry['entity'],
  range: Range,
) => {
  const { src } = getStreamParams(entity, range, hlsStreamUrl)
  const hls = new Hls({
    autoStartLoad: false,
  })
  hls.once(Hls.Events.MEDIA_ATTACHED, () => {
    hls.loadSource(src)
  })
  hls.on(Hls.Events.ERROR, (_, data) => {
    console.warn('Hls error', data)
  })
  hls.attachMedia(video)
  return hls
}

export const attachTboStreamHandler = (params: {
  entity: IArchiveEntry['entity']
  store: IStore
  video: HTMLVideoElement
  plyr: Plyr
  getPreviewImage: (time: number, signal: AbortSignal) => Promise<Blob | null>
  hlsStreamUrl?: string
}): (() => void) => {
  const { plyr, entity, store, video, getPreviewImage, hlsStreamUrl } = params

  const disposers = createDisposers()
  let previewCleanUp = createDisposers()
  disposers.add(() => previewCleanUp.flush())
  const updatePreview = debounce((time: number, signal: AbortSignal) => {
    getPreviewImage(time, signal)
      .then((blob) => {
        if (blob) {
          if (video.poster) {
            window.URL.revokeObjectURL(video.poster)
          }
          video.poster = window.URL.createObjectURL(blob)
        }
      })
      .finally(() => {
        if (!signal.aborted) {
          store.setIsWaiting(false)
        }
      })
  }, 500)
  disposers.add(
    onAction(store, (action) => {
      if (action.name === 'setCurrentTime' && action.args?.[1] !== 'player') {
        store.pause()

        previewCleanUp.flush()
        previewCleanUp = createDisposers()

        const abr = new AbortController()
        const signal = abr.signal
        store.setIsWaiting(true)
        updatePreview(store.currentTime, signal)
        previewCleanUp.add(() => {
          abr.abort()
          store.setIsWaiting(false)
        })
      }
    }),
  )
  store.setCurrentTime(store.currentTime)

  let hlsCleanUp = createDisposers()
  disposers.add(() => hlsCleanUp.flush())
  disposers.add(
    reaction(
      () => store.isPlaying,
      (isPlaying) => {
        if (isPlaying) {
          hlsCleanUp.flush()

          const startTime = store.currentTime

          hlsCleanUp = createDisposers()

          const hls = createHls(video, hlsStreamUrl, entity, {
            start: Math.round(startTime / 1000),
            duration: Infinity,
          })

          hls.once(Hls.Events.MANIFEST_PARSED, () => {
            hls.startLoad()
            hls.once(Hls.Events.FRAG_LOADED, () => {
              plyr.play()
            })
          })

          store.setIsWaiting(true)
          const onCanPlay = () => {
            store.setIsWaiting(false)
          }
          plyr.once('play', onCanPlay)
          hlsCleanUp.add(() => {
            store.setIsWaiting(false)
            plyr.off('play', onCanPlay)
          })

          const onTimeUpdate = () => {
            const newTime = convertToStoreTime(startTime, plyr.currentTime, 0)
            store.setCurrentTime(newTime, 'player')
          }
          plyr.on('timeupdate', onTimeUpdate)
          hlsCleanUp.add(() => {
            plyr.off('timeupdate', onTimeUpdate)
          })

          hlsCleanUp.add(() => {
            hls.destroy()
          })
        } else {
          hlsCleanUp.flush()
          store.setCurrentTime(store.currentTime)
        }
      },
    ),
  )
  return disposers.flush
}
