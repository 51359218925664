import {useCallback, useState} from 'react';

export function useBoolean(initialValue: boolean) {
  const [value, setValue] = useState(initialValue);
  const setTrue = useCallback(() => {
    setValue(true);
  }, []);
  const setFalse = useCallback(() => {
    setValue(false);
  }, []);
  const toggle = useCallback(() => {
    setValue((prev) => !prev);
  }, []);
  return {value, toggle, setTrue, setFalse, setValue};
}
