import { LibApiRepository } from '@netvision/lib-api-repo/dist/src/repositories/LibApiRepository'

export class AccessTokenAdapter {
  static ACCESS_TOKEN_KEY_STORAGE = 'netvision:access_token'
  static async tokenFetcher(api: LibApiRepository) {
    if (AccessTokenAdapter.getToken()) return

    // @ts-ignore
    const { access_token } = await api.getAccessToken()
    AccessTokenAdapter.setToken(access_token)
  }

  static setToken(token: string) {
    sessionStorage.setItem(AccessTokenAdapter.ACCESS_TOKEN_KEY_STORAGE, token)
  }

  static getToken() {
    return sessionStorage.getItem(AccessTokenAdapter.ACCESS_TOKEN_KEY_STORAGE)
  }
}
