export interface IDisposer {
  (): void;
}

export interface IDisposers {
  add(disposer: IDisposer): void;
  remove(disposer: IDisposer): void;
  flush(): void;
  readonly isFlushed: boolean;
}

export function createDisposers(): IDisposers {
  const ds = new Set<IDisposer>();
  let flushed = false;
  return {
    add: (disposer: IDisposer) => {
      if (flushed) {
        disposer();
      } else {
        ds.add(disposer);
      }
    },
    remove: (disposer: IDisposer) => {
      ds.delete(disposer);
    },
    flush: () => {
      if (!flushed) {
        flushed = true;
        [...ds].reverse().forEach((d) => d());
        ds.clear();
      }
    },
    get isFlushed() {
      return flushed;
    }
  };
}
