/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { FC, useEffect, useMemo, useState } from 'react'
import { isEqual } from 'lodash'
import { Calendar } from 'primereact/calendar'
import { observer } from 'mobx-react-lite'
import { useLocale } from '../../../hooks/useLocale'
import { useStore } from '../../../hooks/useStore'
import { Time } from '../../ui/buttons/Time'
import { WithPopup } from '../../ui/WithPopup'
import { IncrementalInput } from './IncrementalInput'
import { Button } from 'primereact/button'

const makeFormattedValue = (date: Date) => {
  return date.toLocaleDateString('ru-RU', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  })
}

export const DateSelector: FC<{ compact?: boolean; archiveEntry: IArchiveEntry }> = observer(
  ({ compact, archiveEntry }) => {
    const locale = useLocale()
    const store = useStore()
    const [openState, setOpenState] = useState(false)
    const { currentTime, globalStart, globalEnd } = store
    const [timeValue, setTimeValue] = useState<[number, number, number]>([0, 0, 0])
    const [dateValue, setDateValue] = useState<Date>(new Date(currentTime))
    const [focusedInput, setFocusedInput] = useState<'hours' | 'minutes' | 'seconds' | null>(null)
    const minDate = new Date(globalStart)
    const maxDate = new Date(globalEnd)
    const formattedValue = makeFormattedValue(new Date(store.currentTime))

    const button = (
      <Time css={s.button} tooltip={compact ? formattedValue : undefined} tooltipAlign={'left'}>
        {!compact && (
          <div data-role={'value'} data-dimmable="">
            {formattedValue}
          </div>
        )}
      </Time>
    )

    const canShowStreamInfo = archiveEntry.streams && archiveEntry.streams?.length > 1

    const streamsInfo = (
      <div className="p-d-flex p-flex-column p-ai-center p-jc-center">
        {archiveEntry.streams?.map((stream) => {
          const { title, dvrTimelines, id } = stream
          const [start] = dvrTimelines
          const end = dvrTimelines[dvrTimelines.length - 1]

          const secToMS = (sec: number) => sec * 1000
          const dateStart = new Date(secToMS(start.start))
          const dateEnd = new Date(secToMS(end.start + end.duration))

          return (
            <div
              css={s.streamInfo}
              key={id}
              onClick={() => archiveEntry?.setActiveStream && archiveEntry.setActiveStream(stream)}
              className={`p-pb-1 ${archiveEntry.entity.id === id && 'active'}`}>
              {`${title} с ${dateStart.toLocaleDateString('ru-RU')} по
              ${dateEnd.toLocaleDateString('ru-RU')}`}
            </div>
          )
        })}
      </div>
    )

    const applyTimeFilter = () => {
      const time = dateValue.getTime()
      if (store.currentTime !== time) {
        store.setCurrentTime(time)
      }
    }

    const updateDateValue = (value: Date | [number, number, number]) => {
      if (value instanceof Date) {        
        value.setHours(...timeValue)
        setDateValue(value)
      } else {
        const tempDateValue = new Date(dateValue.getTime())
        tempDateValue.setHours(...value)
        setDateValue(tempDateValue)
        setTimeValue(value)
      }
    }

    const isOnDatesRange = useMemo(() => {
      const restOfTimeToEndMS = maxDate.getTime() - dateValue.getTime()
      const restOfTimeFromStartMS = dateValue.getTime() - minDate.getTime()

      const hoursStart = Math.floor(restOfTimeFromStartMS / 3_600_000) > 0
      const minutesStart = hoursStart || (Math.floor(restOfTimeFromStartMS / 60_000) > 0)
      const secondsStart = minutesStart || (Math.floor(restOfTimeFromStartMS / 1000) > 60)
      const hoursEnd = Math.floor(restOfTimeToEndMS / 3_600_000) > 0
      const minutesEnd = hoursEnd || (Math.floor(restOfTimeToEndMS / 60_000) > 0)
      const secondsEnd = minutesEnd || (Math.floor(restOfTimeToEndMS / 1000) > 60)

      return {
        hoursStart,
        minutesStart,
        secondsStart,
        hoursEnd,
        minutesEnd,
        secondsEnd
      }
    }, [dateValue, maxDate, minDate])

    const hoursRange: [number, number] = useMemo(() => {
      if (isOnDatesRange.hoursStart && isOnDatesRange.hoursEnd) {
        return [0, 23]
      } else if (isOnDatesRange.hoursStart && !isOnDatesRange.hoursEnd) {
        return [0, maxDate.getHours()]
      } else if (isOnDatesRange.hoursEnd && !isOnDatesRange.hoursStart) {
        return [minDate.getHours(), 23]
      }
      return [0, 0]
    }, [timeValue, isOnDatesRange])

    const minutesRange: [number, number] = useMemo(() => {
      if (isOnDatesRange.minutesStart && isOnDatesRange.minutesEnd) {
        return [0, 59]
      } else if (isOnDatesRange.minutesStart && !isOnDatesRange.minutesEnd) {
        return [0, maxDate.getMinutes()]
      } else if (isOnDatesRange.minutesEnd && !isOnDatesRange.minutesStart) {
        return [minDate.getMinutes(), 59]
      }
      return [0, 0]
    }, [timeValue, isOnDatesRange])

    const secondsRange: [number, number] = useMemo(() => {
      if (isOnDatesRange.secondsStart && isOnDatesRange.secondsEnd) {
        return [0, 59]
      } else if (isOnDatesRange.secondsStart && !isOnDatesRange.secondsEnd) {
        return [0, maxDate.getSeconds()]
      } else if (isOnDatesRange.secondsEnd && !isOnDatesRange.secondsStart) {
        return [minDate.getSeconds(), 59]
      }
      return [0, 0]
    }, [timeValue, isOnDatesRange])

    const getSelectedTime = (date: Date): [number, number, number] => {
      return [
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      ]
    }

    const resetDateToDefault = (date: Date) => {
      setTimeValue(getSelectedTime(date))
      setDateValue(date)
    }

    useEffect(() => {
      resetDateToDefault(new Date(store.currentTime))
    }, [])

    useEffect(() => {
      const time: [number, number, number] = [...timeValue]
      if (!isOnDatesRange.hoursStart) time[0] = minDate.getHours()
      if (!isOnDatesRange.minutesStart) time[1] = minDate.getMinutes()
      if (!isOnDatesRange.secondsStart) time[2] = minDate.getSeconds()
      if (!isOnDatesRange.hoursEnd) time[0] = maxDate.getHours()
      if (!isOnDatesRange.minutesEnd) time[1] = maxDate.getMinutes()
      if (!isOnDatesRange.secondsEnd) time[2] = maxDate.getSeconds()

      if (!isEqual(time, timeValue)) {
        const tempDateValue = new Date(dateValue.getTime())
        tempDateValue.setHours(...time)
        setDateValue(tempDateValue)
        setTimeValue(time)
      }
    }, [dateValue, timeValue])

    useEffect(() => {
      const actualDateTime = new Date(currentTime)
      const time: [number, number, number] = [
        actualDateTime.getHours(),
        actualDateTime.getMinutes(),
        actualDateTime.getSeconds()
      ]
      setDateValue(actualDateTime)
      setTimeValue(time)
    }, [currentTime])

    useEffect(() => {
      if (!openState) {
        resetDateToDefault(new Date(store.currentTime))
      }
    }, [openState])

    const content = (
      <div css={s.popup}>
        <Calendar
          inline
          locale={locale.primeCalendar}
          dateFormat={'dd/mm/yy'}
          showSeconds
          value={dateValue}
          onChange={({ value }) => updateDateValue(value as Date)}
          minDate={minDate}
          maxDate={maxDate}
        />
        <div css={dateFilterActionsCSS}>
        <div
          css={timeSelectorCSS}
          style={{
            opacity: !dateValue ? 0.25 : 1,
            pointerEvents: !dateValue ? 'none' : 'initial' 
          }}
        >
          <IncrementalInput
            value={timeValue[0]}
            range={hoursRange}
            digits={2}
            isFocused={focusedInput === 'hours'}
            apply={applyTimeFilter}
            setValue={(value) => {
              updateDateValue([value, timeValue[1], timeValue[2]])
            }}
            switchInput={() => setFocusedInput('minutes')}
            resetFocusState={() => setFocusedInput(null)}
          />
          <span>:</span>
          <IncrementalInput
            value={timeValue[1]}
            range={minutesRange}
            digits={2}
            isFocused={focusedInput === 'minutes'}
            apply={applyTimeFilter}
            setValue={(value) => {
              updateDateValue([timeValue[0], value, timeValue[2]])
            }}
            switchInput={() => setFocusedInput('seconds')}
            resetFocusState={() => setFocusedInput(null)}
          />
          <span>:</span>
          <IncrementalInput
            value={timeValue[2]}
            range={secondsRange}
            digits={2}
            isFocused={focusedInput === 'seconds'}
            apply={applyTimeFilter}
            setValue={(value) => {
              updateDateValue([timeValue[0], timeValue[1], value])
            }}
            switchInput={() => setFocusedInput('hours')}
            resetFocusState={() => setFocusedInput(null)}
          />
        </div>
        <Button
          label={locale.applyLable}
          onClick={applyTimeFilter}
          style={{ width: '100%' }}
        />
      </div>
        {canShowStreamInfo ? streamsInfo : null}
      </div>
    )
    return <WithPopup button={button} popup={content} state={[openState, setOpenState]} />
  },
)

// language=SCSS
const s = {
  button: css`
    &[aria-expanded='true'] > div[data-role='tooltip'] {
      opacity: 0;
    }
    & {
      position: relative;

      display: flex;
      align-items: center;
      width: auto;

      > div[data-role='value'] {
        margin-left: calc(10rem / var(--bfs));
        width: calc(240rem / var(--bfs));
        color: var(--text-color-secondary);
        font-weight: 500;
        font-size: calc(14rem / var(--bfs));
        transition: color 200ms ease;
        text-align: left;
      }

      :hover > div {
        color: var(--text-color);
      }
    }
  `,
  popup: css`
    & {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(-100%);
      background: var(--surface-b);
      border-radius: var(--border-radius);

      box-shadow: var(--shadow-raised);

      .p-datepicker.p-component {
        padding-bottom: calc(10rem / var(--bfs));
      }
    }
  `,
  streamInfo: css`
    cursor: pointer;
    color: var(--text-color);
    &.active {
      cursor: default;
      color: var(--primary-color);
    }
  `,
}

const dateFilterActionsCSS = css`
  padding: 0 var(--spacer) var(--spacer);
`

const timeSelectorCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--spacer-sm);
`
