/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { FC, useEffect, useRef, useState } from 'react'
import { useWidgetProps } from '../Root'
import { loadPreview } from '../requests/loadPreview'
import { Spinner } from './controls/Spinner'
import { useLocale } from '../hooks/useLocale'
import { attachZoomHandler } from '@netvision/front-utils/lib/common/handleZoom'
import { createDisposers } from '../utils/disposers'
import { useApiRepository } from '../hooks/useApiRepository'

export interface PreviewProps {
  initialTime: number
}

export const PreviewOnly: FC<PreviewProps> = ({ initialTime, children }) => {
  const {
    props: { entity, height, width = '100%', overlayMountHooks },
  } = useWidgetProps()
  const { api } = useApiRepository()
  const locale = useLocale()
  const videoRef = useRef<HTMLVideoElement>(null)
  const overlayRef = useRef<HTMLDivElement>(null)
  const [preview, setPreview] = useState<null | { type: 'image' | 'video'; url: string }>(null)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const disposers = createDisposers()
    const video = videoRef.current
    const overlayContainer = overlayRef.current
    if (video && overlayContainer && preview) {
      if (preview.type === 'image') {
        if (overlayMountHooks) {
          const img = new Image()
          img.src = preview.url
          img.onload = () => {
            overlayMountHooks.mount({
              overlayContainer,
              overlayBase: video,
              naturalHeight: img.naturalHeight,
              naturalWidth: img.naturalWidth,
            })
          }
          disposers.add(() => overlayMountHooks.unmount())
        }
      } else if (preview.type === 'video') {
        if (overlayMountHooks) {
          const onLoad = () => {
            overlayMountHooks.mount({
              overlayContainer,
              overlayBase: video,
              naturalHeight: video.videoHeight,
              naturalWidth: video.videoWidth,
            })
          }
          video.addEventListener('loadstart', onLoad, { once: true })
          disposers.add(() => {
            video.removeEventListener('loadstart', onLoad)
            overlayMountHooks.unmount()
          })
        }
      }
    }
    return disposers.flush
  }, [preview, overlayMountHooks])

  useEffect(() => {
    const video = videoRef.current
    const videoParent = video?.parentElement
    const overlayContainer = overlayRef.current
    if (!video || !videoParent || !overlayContainer) {
      return undefined
    }
    return attachZoomHandler(video.parentElement!, [video, overlayContainer])
  }, [])

  useEffect(() => {
    const video = videoRef.current
    if (!video || !entity) return undefined
    const abr = new AbortController()
    setLoading(true)
    loadPreview(
      api,
      entity,
      initialTime,
      abr.signal,
    )
      .then((blob) => {
        if (blob && !abr.signal.aborted) {
          setPreview({
            type: blob.type.startsWith('video') ? 'video' : 'image',
            url: window.URL.createObjectURL(blob),
          })
        }
      })
      .finally(() => {
        if (!abr.signal.aborted) {
          setLoading(false)
        }
      })

    return () => {
      abr.abort()
    }
  }, [api, entity, initialTime])
  useEffect(() => {
    if (!preview) return undefined
    return () => {
      window.URL.revokeObjectURL(preview.url)
    }
  }, [preview])
  const src = preview?.type === 'video' ? preview.url : undefined
  const poster = preview?.type === 'image' ? preview.url : undefined
  return (
    <div css={previewCss} style={{ width, height }}>
      <video ref={videoRef} src={src} poster={poster} style={{ width, height }} />
      <div ref={overlayRef} data-name={'overlay'} />
      {loading && <Spinner />}
      {loading && <div data-name={'info'}>{locale.loadingPreview}</div>}
      {children}
    </div>
  )
}

// language=SCSS
const previewCss = css`
  & {
    position: relative;
    background: black;
    overflow: hidden;

    video {
      display: block;
      pointer-events: none;
    }

    [data-name='info'] {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(60px);
      color: white;
    }

    [data-name='overlay'] {
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;

      canvas {
        display: block;
      }
    }
  }
`
