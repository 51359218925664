import {findTrack} from './findTrack';
import {ICanvasPoint, ICanvasPolygon, IPolygon, IRenderer} from './models';
import {ICanvasOverrideStyles, mergeStyles} from './defaults';
import {IBbox, ITrackableEvent} from '../models';

export const getTracksRenderer = (event: ITrackableEvent, styles: ICanvasOverrideStyles): IRenderer => {
  const mergedStyles = mergeStyles(styles);
  const area =
    event.trackableArea &&
    ({
      id: 'area',
      editable: false,
      style: mergedStyles.area,
      points: polygonToPoints(event.trackableArea?.points)
    } as const);
  const {timestamp, endAt, tracks} = event;
  return {
    getScene: (time: number) => {
      const items: ICanvasPolygon[] = [];
      if (area) {
        items.push(area);
      }
      if (timestamp <= time && time <= endAt) {
        const track = findTrack(tracks, time);
        if (track) {
          items.push({
            id: event.id,
            editable: false,
            style: mergedStyles.fallback,
            points: bboxToPoints(track.bbox)
          });
        }
      }
      return {
        polygonStore: {
          editableLimit: 0,
          items
        },
        lineStore: {
          editableLimit: 0,
          items: []
        }
      };
    }
  };
};

const polygonToPoints = (p: IPolygon): ICanvasPoint[] => {
  return p?.map(([x, y], index) => ({x, y, index})) || [];
};

const bboxToPoints = ([x, y, width, height]: IBbox): ICanvasPoint[] => {
  return [
    {x, y},
    {x: x + width, y},
    {x: x + width, y: y + height},
    {x: x, y: y + height}
  ].map((val, index) => Object.assign(val, {index}));
};
