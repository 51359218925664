import {IEventModel, ITrackableEvent} from '../models';

export type IPolygon = Array<[x: number, y: number]>;

export type ICanvasPoint = {x: number; y: number; index: number};
export type ICanvasPolygon = {
  id: string;
  editable: false;
  style: Record<string, string>;
  points: ICanvasPoint[];
};

export const isTrackable = (v: IEventModel): v is ITrackableEvent => typeof v.endAt === 'number' && 'tracks' in v;

export interface IRenderer {
  getScene: (time: number) => {
    polygonStore: {
      editableLimit: 0;
      items: ICanvasPolygon[];
    };
    lineStore: {
      editableLimit: 0;
      items: [];
    };
  };
}
