import { TUnionRepo } from '@netvision/lib-api-repo'
import { isFile, isRecord } from '../utils/basicValidators'

export const PREVIEW_INTERVAL = 3

const getPreviewTime = (ms: number): number => {
  const seconds = Math.floor(ms / 1000)
  const div = seconds % PREVIEW_INTERVAL
  return seconds - div
}

export const loadPreview = (
  api: TUnionRepo,
  entity: IArchiveEntry['entity'],
  ms: number,
  signal: AbortSignal,
  useCache = true,
): Promise<Blob | null> => {
  const entityId = isFile(entity) || isRecord(entity) ? entity.id : entity.cameraId
  // TODO: &attachmentType=scene||vehicle for assignmetnEvent

  if (!('getPreview' in api)) {
    console.error('method getPreview does not exist')
    return Promise.resolve(null)
  }

  return api.getPreview({
    signal,
    timestamp: getPreviewTime(ms),
    id: entityId,
    useCache,
    eventName: entity.type,
  })
}
