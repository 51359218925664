import { isFile, isRecord } from '../utils/basicValidators'

export const getStreamParams = (
  entity: IArchiveEntry['entity'],
  range: { start: number; duration: number },
  hlsUrl: string = '',
): { src: string } => {
  const isFileOrRecord = isFile(entity) || isRecord(entity)

  if (isFileOrRecord && !entity.streamUrl) {
    console.error(entity, 'does noe exist streamUrl')
  }

  return {
    src: isFileOrRecord ? entity.streamUrl! : streamUrlBuilder(hlsUrl, range),
  }
}

const streamUrlBuilder = (hlsUrl: string, range: { start: number; duration: number }) => {
  const separator = hlsUrl[hlsUrl.length - 1] === '?' ? '' : '&'
  return range.duration === Infinity
    ? `${hlsUrl}${separator}start=${range.start}`
    : `${hlsUrl}${separator}start=${range.start}&duration=${range.duration}`
}
