const eventStatusLocales: Partial<Record<string, Record<string, string>>> = {
  ['TboEvent']: {
    ['NotHandled']: 'Не обработано',
    ['Handled']: 'Обработано',
    ['ViolationDetected']: 'Нарушение зафиксировано'
  },
  ['AssignmentEvent']: {
    ['Alert']: 'Тревога',
    ['FalsePositive']: 'Ложное',
    ['Handled']: 'Угрозы нет',
    ['NotHandled']: 'Не обработано'
  }
};

const eventMarksLocale = {
  eventFilters: 'Фильтры событий:',
  allAssignments: 'Все аналитики',
  anyStatus: 'Любой статус',
  anyPlate: 'Любой номер',
  anyBrand: 'Любая марка',
  anyModel: 'Любая модель',
  anySpeed: 'Любая скорость',
  speedSuffix: 'км/ч',
  speedUnknown: 'Не определена',
  modelUnknown: 'Не определена',
  brandUnknown: 'Не определена',
  speedLabel: 'Скорость',
  brandLabel: 'Марка',
  modelLabel: 'Модель',
  plateLabel: 'Номер',
  showEventMarks: 'Показывать метки событий'
};

const saveDialogLocale = {
  descriptionLabel: 'Описание',
  exportGapsWarning: 'Сохраняемый фрагмент содержит пустые промежутки. Он будет разбит на несколько видеозаписей.',
  header: 'Сохранение фрагмента',
  save: 'Сохранить',
  cancel: 'Отмена',
  success: 'Фрагмент сохраняется и будет добавлен в сохраненные записи в ближайшее время',
  error: 'Не удалось произвести сохранение по неизвестной причине'
};

const plyrLocale = {
  restart: 'Сначала',
  rewind: 'Назад на {seektime} секунд',
  play: 'Начать проигрывание',
  pause: 'Пауза',
  fastForward: 'Вперед на {seektime} секунд',
  seek: 'Поиск',
  seekLabel: '{currentTime} от {duration}',
  played: 'Проиграно',
  buffered: 'Буффер',
  currentTime: 'Текущее время',
  duration: 'Длительность',
  volume: 'Громкость',
  mute: 'Отключить звук',
  unmute: 'Включить звук',
  enableCaptions: 'Включить субтитры',
  disableCaptions: 'Выключить субтитры',
  download: 'Загрузить',
  enterFullscreen: 'Полноэкранный режим',
  exitFullscreen: 'Покинуть полноэкранный режим',
  frameTitle: 'Проигрыватель для {title}',
  captions: 'Субтитры',
  settings: 'Настройки',
  pip: 'PIP',
  menuBack: 'Назад',
  speed: 'Скорость',
  normal: 'Обычная',
  quality: 'Качество',
  loop: 'Зациклить',
  start: 'Начало',
  end: 'Конец',
  all: 'Все',
  reset: 'Сбросить',
  disabled: 'Отключено',
  enabled: 'Включено',
  advertisement: 'Реклама',
  qualityBadge: {
    2160: '4K',
    1440: 'HD',
    1080: 'HD',
    720: 'HD',
    576: 'SD',
    480: 'SD'
  }
};

const primeCalendar = {
  firstDayOfWeek: 1,
  dayNames: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
  dayNamesShort: ['Вос', 'Пон', 'Вто', 'Сре', 'Чет', 'Пят', 'Суб'],
  dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  monthNames: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
  ],
  monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Нов', 'Дек'],
  today: 'Сегодня',
  clear: 'Очистить',
  weekHeader: 'Неделя'
};

const panelLocale = {
  buttonLabels: {
    select: 'Выбрать фрагмент',
    removeSelect: 'Убрать выделение',
    refresh: 'Обновить',
    forward: '+ {{}} секунд',
    backward: '- {{}} секунд',
    play: 'Старт',
    pause: 'Пауза',
    speed: 'Скорость',
    zoomMinus: 'Уменьшить масштаб',
    zoom: 'Масштаб',
    zoomPlus: 'Увеличить масштаб',
    volume: 'Громкость',
    enterFullscreen: 'Полноэкранный режим',
    exitFullscreen: 'Покинуть полноэкранный режим'
  },
  attention: 'Внимание',
  exportBlocked: 'Невозможно экспортировать фрагмент с заблокированным участком!',
  filterLabel: 'Фильтрация',
  normalSpeed: 'Обычная',
  saveLabel: 'Сохранить',
  unselectLabel: 'Убрать',
  download: 'Скачать',
  saveScreenshot: 'Сохранить кадр',
  resetLabel: 'Сбросить',
  eventsHeader: 'События',
  emptyEvents: 'Текущий временной промежуток не содержит событий.',
  emptyFilteredEvents: 'Текущий временной промежуток не содержит событий с текущими параметрами фильтрации.'
};

const buttonActions = {
  ok: 'Ок'
}

export const defaultLocale = {
  archiveLabel: 'Архив',
  blockedChunk: 'Участок Архива Заблокирован',
  missingTime: 'Для этого события архивная запись недоступна',
  archiveIsUnavailable: 'Не удалось загрузить архив',
  tooEarly: 'Синхронизация архива...',
  loadingArchive: 'Загрузка архива...',
  loadingPreview: 'Загрузка кадра...',
  applyLable: 'Применить',
  eventPropsLocale: {
    eventType: {
      title: 'Тип события',
      anyLabel: 'Любой',
      noneLabel: 'Отсутствует'
    },
    status: {
      title: 'Статус',
      anyLabel: 'Любой',
      noneLabel: 'Отсутствует'
    },
    groupTitle: {
      title: 'Аналитика',
      anyLabel: 'Любое',
      noneLabel: 'Отсутствует'
    }
  },
  buttonActions,
  panelLocale,
  eventStatusLocales,
  eventMarksLocale,
  saveDialogLocale,
  plyrLocale,
  primeCalendar
};

export type Locale = typeof defaultLocale;
