/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { useStore } from '../../../hooks/useStore'
import { observer } from 'mobx-react-lite'
import { FC, Fragment, useCallback } from 'react'
import { IStore } from '../../../StoreModel'
import { ExportRegionCreateArea } from './ExportRegionCreateArea'

export const AvailableRanges: FC = observer(function AvailableRanges() {
  const store = useStore()
  return (
    <Fragment>
      <WholeRange store={store} />
      {store.gluedAvailableRanges.map((range) => {
        if (range.end < store.visibleTimeRange[0]) {
          return null
        } else if (range.start > store.visibleTimeRange[1]) {
          return null
        } else {
          return <AvailableRange store={store} key={range.start} range={range} />
        }
      })}
    </Fragment>
  )
})

const WholeRange: FC<{ store: IStore }> = observer(({ store }) => {
  const limits = store.visibleTimeRange
  const start = Math.max(limits[0], store.globalStart)
  const end = Math.min(limits[1], store.globalEnd)
  const offset = (start - store.tapeOffset) / store.scale
  const width = (end - start) / store.scale
  const style = {
    left: offset,
    width,
  }
  return (
    <div css={wholeRangeContainerStyle} style={style}>
      <div css={wholeRangeLineStyle} />
    </div>
  )
})
// language=SCSS
const wholeRangeContainerStyle = css`
  & {
    position: absolute;
    top: 0;
    height: 100%;
  }
`
// language=SCSS
const wholeRangeLineStyle = css`
  & {
    position: absolute;
    background: var(--error-color);
    cursor: not-allowed;
    bottom: 0;
    height: calc(2rem / var(--bfs));
    border-radius: calc(1rem / var(--bfs));
    width: 100%;
  }
`

const AvailableRange: FC<{
  range: { start: number; end: number; streamId: string }
  store: IStore
}> = observer(({ range, store }) => {
  const onClick = useCallback(
    (e) => {
      const { currentTarget, clientX } = e
      const { left } = currentTarget.getBoundingClientRect()
      const offset = clientX - left
      const limits = store.visibleTimeRange
      const start = Math.max(limits[0], range.start)
      store.setCurrentTime(start + offset * store.scale)
    },
    [range, store],
  )
  const limits = store.visibleTimeRange
  const start = Math.max(limits[0], range.start)
  const end = Math.min(limits[1], range.end)
  const offset = (start - store.tapeOffset) / store.scale
  const width = (end - start) / store.scale
  const style = {
    left: offset,
    width,
  }

  const isBlockedRange =
    store.isEnableLockingFeature &&
    store.archiveType === 'Stream' &&
    (range.streamId === '' || store.activeStreamId !== range.streamId)

  return (
    <div css={availableRangeContainerStyle} style={style}>
      <div
        className={isBlockedRange ? 'blocked-range' : ''}
        css={availableRangeLineStyle}
        onClick={onClick}
      />
      <ExportRegionCreateArea startTime={start} />
    </div>
  )
})

// language=SCSS
const availableRangeContainerStyle = css`
  & {
    position: absolute;
    top: 0;
    height: 100%;
  }
`
// language=SCSS
const availableRangeLineStyle = css`
  position: absolute;
  background: var(--text-color);
  cursor: pointer;
  bottom: 0;
  height: calc(3rem / var(--bfs));
  border-radius: calc(1rem / var(--bfs));
  width: 100%;

  &.blocked-range {
    background: var(--warning-color);
  }

  &.selected {
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(60, 114, 255);
      filter: blur(2px);
    }
  }
`
