/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {FC, useState} from 'react';
import {Slider} from 'primereact/slider';
import {Volume} from '../../ui/buttons/Volume';
import {WithPopup} from '../../ui/WithPopup';
import {useLocale} from '../../../hooks/useLocale';
import {Muted} from '../../ui/buttons/Muted';
import {E2EModule} from '../../../__test__';

export interface VolumeControlProps {
  /** number from 0 to 1 */
  value: number;
  onChange: (newValue: number) => void;
  muted: boolean;
  toggleMuted: () => void;
}

export const VolumeControl: FC<VolumeControlProps> = ({value, onChange, muted, toggleMuted}) => {
  const openState = useState(false);
  const {buttonLabels} = useLocale().panelLocale;
  return (
    <WithPopup
      state={openState}
      button={
        muted ? (
          <Muted
            data-cy={E2EModule.attributes.soundToggleButton}
            onDoubleClick={toggleMuted}
            tooltip={buttonLabels.volume}
            css={$button}
          />
        ) : (
          <Volume
            data-cy={E2EModule.attributes.soundToggleButton}
            onDoubleClick={toggleMuted}
            tooltip={buttonLabels.volume}
            css={$button}
          />
        )
      }
      popup={
        <div css={$popup}>
          <Slider
            value={muted ? 0 : value * 100}
            min={0}
            max={100}
            onChange={(val) => {
              if (typeof val.value === 'number') onChange(val.value / 100);
            }}
            orientation={'vertical'}
          />
        </div>
      }
    />
  );
};

// language=SCSS
const $button = css`
  &[aria-expanded='true'] > div[data-role='tooltip'] {
    opacity: 0;
  }
`;

// language=SCSS
const $popup = css`
  & {
    position: absolute;
    padding: calc(12rem / var(--bfs)) calc(8rem / var(--bfs));
    top: 0;
    left: 50%;
    border-radius: calc(4rem / var(--bfs));
    background: var(--surface-b);
    transform: translateY(-100%) translateX(-50%);
    box-shadow: var(--shadow-raised);
  }
`;
