export const attributes = {
  archivePlayerWrapper: 'player-archive',
  selectFragment: 'select-fragment',
  selectedFragment: 'selected-fragment',
  timeLineMoveArea: 'time-line-move-area',
  currentTime: 'current-time',
  openSaveFragmentModal: 'open-save-fragment-modal',
  saveFragmentButton: 'save-fragment-button',
  saveFragmentNameInput: 'save-fragment-name-input',
  eventItem: 'event-item',
  eventWrapperTitle: 'event-wrapper-title',
  filterEventButton: 'filter-event-button',
  hideEventListButton: 'hide-event-list-button',
  showEventListButton: 'show-event-list-button',
  playerScreen: 'player-screen',
  datePicker: 'date-picker',
  updateButton: 'update-button',
  tenSecSwipeBackButton: 'ten-sec-swiper-back-button',
  tenSecSwipeNextButton: 'ten-sec-swiper-next-button',
  playButton: 'play-button',
  pauseButton: 'pause-button',
  changeSpeedButton: 'change-speed-button',
  changeSpeedList: 'change-speed-list',
  zoomOutTimeLineButton: 'zoom-out-timeline-button',
  zoomInTimeLineButton: 'zoom-in-timeline-button',
  zoomRangeTimeLine: 'zoom-range-timeline',
  soundToggleButton: 'sound-toggle-button',
  fullScreenButton: 'full-screen-button',
  fullScreenExitButton: 'full-screen-exit-button',
};
