import { useEffect, useState } from 'react'
import { useApiRepository } from '.'
import { LibApiRepository } from '@netvision/lib-api-repo/dist/src/repositories/LibApiRepository'

interface User {
  firstName: string
  lastName: string
  fullName: string
}

export const useGetUserInfo = () => {
  const [user, setUser] = useState<User | null>(null)
  const { api } = useApiRepository()

  useEffect(() => {
    ;(api as LibApiRepository).getUserInfo().then(setUser).catch(console.error)
  }, [])

  return user
}
