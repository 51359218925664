import React, {createContext, FC, useContext, useEffect, useState} from 'react';
import {destroy, Instance, types} from 'mobx-state-tree';

const HelperStoreModel = types
  .model({
    hovered: types.optional(types.string, ''),
    selected: types.optional(types.string, '')
  })
  .actions((self) => {
    return {
      setSelected(id: string) {
        self.selected = id;
      },
      setHovered(id: string) {
        self.hovered = id;
      }
    };
  });

const HelperStoreCtx = createContext<Instance<typeof HelperStoreModel>>(null!);

export const HelperStoreProvider: FC = ({children}) => {
  const [store] = useState(() => HelperStoreModel.create());
  useEffect(() => {
    return () => {
      destroy(store);
    };
  }, [store]);
  return <HelperStoreCtx.Provider value={store}>{children}</HelperStoreCtx.Provider>;
};

export const useHelperStore = () => useContext(HelperStoreCtx);
