/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {FC, Fragment, ReactNode, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {IEventExtraPropDesc, IEventModel} from './models';
import {get, isEqual} from 'lodash-es';
import {MultiSelect} from 'primereact/multiselect';
import {isArray, isNumber, isString, refine} from '../../utils/basicValidators';
import {useLocale} from '../../hooks/useLocale';
import {InputText} from 'primereact/inputtext';
import {Slider} from 'primereact/slider';
import {genId} from '../../utils/genId';

export const useEventFilters = (
  allEvents: IEventModel[],
  extraProps: IEventExtraPropDesc[]
): {filtersActive: boolean; filterViews: ReactNode[]; filteredEvents: IEventModel[]} => {
  const {eventPropsLocale} = useLocale();
  const [filterDefaults, setFilterDefaults] = useState<IFilterState>({});
  const [filtersState, setFiltersState] = useState<IFilterState>({});
  const setFilterValue = useCallback<IPropertyUpdater>((property, updater) => {
    setFiltersState((prev) => {
      const prevValue = prev[property];
      const nextValue = typeof updater === 'function' ? updater(prevValue) : updater;
      if (isEqual(prev, nextValue)) {
        return prev;
      } else {
        return {
          ...prev,
          [property]: nextValue
        };
      }
    });
  }, []);
  const prevFDRef = useRef<null | IFilterDesc[]>(null);
  const FDs = useMemo(() => {
    const propsDescription: IEventExtraPropDesc[] = [
      {
        property: 'eventType.title',
        title: eventPropsLocale.eventType.title,
        filter: {
          type: 'enum',
          anyLabel: eventPropsLocale.eventType.anyLabel,
          noneLabel: eventPropsLocale.eventType.noneLabel
        }
      },
      {
        property: 'status.title',
        title: eventPropsLocale.status.title,
        filter: {
          type: 'enum',
          anyLabel: eventPropsLocale.status.anyLabel,
          noneLabel: eventPropsLocale.status.noneLabel
        }
      },
      {
        property: 'group.title',
        title: eventPropsLocale.groupTitle.title,
        filter: {
          type: 'enum',
          anyLabel: eventPropsLocale.groupTitle.anyLabel,
          noneLabel: eventPropsLocale.groupTitle.noneLabel
        }
      },
      ...extraProps.map((desc) => {
        return {
          ...desc,
          property: `originalEvent.${desc.property}`
        };
      })
    ];
    const fd: IFilterDesc[] = propsDescription.map(({title, property, filter}) => {
      const {noneLabel, anyLabel} = filter;
      let desc: IFilterDesc;
      const base: IBaseDesc = {
        title,
        property,
        noneLabel,
        anyLabel
      };
      switch (filter.type) {
        case 'enum':
          desc = Object.assign(base, {
            type: filter.type,
            options: new Set<string>()
          });
          break;
        case 'range':
          desc = Object.assign(base, {
            type: filter.type,
            min: Infinity,
            max: -Infinity,
            template: filter.template,
            precision: filter.precision
          });
      }
      return desc;
    });
    allEvents.forEach((ev) => {
      fd.forEach((desc) => {
        const value = get(ev, desc.property);
        if (desc.type === 'range' && isNumber(value)) {
          desc.min = Math.min(value, desc.min);
          desc.max = Math.max(value, desc.max);
        } else if (desc.type === 'enum') {
          if (isString(value)) {
            desc.options.add(value);
          } else {
            desc.options.add(null);
          }
        }
      });
    });
    let res = fd
    // .filter((desc) => {
    //   switch (desc.type) {
    //     case 'enum':
    //       return desc.options.size > 1;
    //     case 'range':
    //       return desc.min < desc.max;
    //   }
    // });
    if (prevFDRef.current !== null && isEqual(res, prevFDRef.current)) {
      res = prevFDRef.current;
    }
    prevFDRef.current = res;
    return res;
  }, [allEvents, eventPropsLocale, extraProps]);
  useEffect(() => {
    const defaults = FDs.reduce((acc, desc) => {
      if (desc.type === 'range') {
        acc[desc.property] = undefined;
      } else if (desc.type === 'enum') {
        acc[desc.property] = [];
      }
      return acc;
    }, {} as IFilterState);
    setFilterDefaults(defaults);
    setFiltersState((prev) => {
      const next = {...defaults};
      for (const property of Object.keys(next)) {
        if (property in prev) {
          next[property] = prev[property];
        }
      }
      return isEqual(prev, next) ? prev : next;
    });
  }, [FDs]);
  const filteredEvents = useMemo(
    () =>
      allEvents.filter((ev) => {
        for (const property of Object.keys(filtersState)) {
          const filterValue = filtersState[property];
          const eventValue = get(ev, property);
          if (isStringArray(filterValue) && filterValue.length > 0 && !filterValue.includes(eventValue)) {
            return false;
          } else if (isNumber(filterValue) && filterValue !== eventValue) {
            return false;
          }
        }
        return true;
      }),
    [allEvents, filtersState]
  );
  const filterViews = FDs.map((fd) => {
    switch (fd.type) {
      case 'range':
        return <RangeFilter key={fd.property} state={filtersState} updateProperty={setFilterValue} description={fd} />;
      case 'enum':
      default:
        return <EnumFilter key={fd.property} state={filtersState} updateProperty={setFilterValue} description={fd} />;
    }
  });
  return {
    filtersActive: useMemo(() => !isEqual(filterDefaults, filtersState), [filtersState, filterDefaults]),
    filterViews: filterViews,
    filteredEvents: filteredEvents
  };
};

const isStringArray = isArray(isString);
const isNumberRange = refine(isArray(isNumber), (v): v is [number, number] => v.length === 2 && v[0] <= v[1]);

type Val =
  // range values
  | ([number, number] | null)
  // enum values
  | Array<string | null>
  // not set
  | undefined;
type IFilterState<T = Val> = Record<string, T>;
type IPropertyUpdater<T = Val> = (property: string, updater: T | ((prev: T) => T)) => void;

type IFilterDesc = IEnumDesc | IRangeDesc;

type IBaseDesc = {
  property: string;
  title: string;
  anyLabel: string;
  noneLabel: string;
};

type IEnumDesc = IBaseDesc & {
  type: 'enum';
  options: Set<null | string>;
};

type IRangeDesc = IBaseDesc & {
  type: 'range';
  min: number;
  max: number;
  template?: string;
  precision?: number;
};

type IFilterView<T extends IFilterDesc> = FC<{
  state: IFilterState;
  updateProperty: IPropertyUpdater;
  description: T;
}>;

const EnumFilter: IFilterView<IEnumDesc> = ({
  state,
  updateProperty,
  description: {title, property, options, anyLabel, noneLabel}
}) => {
  const value = useMemo(() => {
    const v = state[property];
    return isStringArray(v) ? v : [];
  }, [state, property]);
  const onChange = useCallback(
    ({value}) => {
      if (isStringArray(value)) {
        updateProperty(property, value);
      } else if (isString(value)) {
        updateProperty(property, [value]);
      } else {
        updateProperty(property, []);
      }
    },
    [property, updateProperty]
  );
  const selectOptions = useMemo(
    () =>
      [...options]
        .sort((a, b) => (a ?? '').localeCompare(b ?? ''))
        .map((value) => {
          return {
            value,
            label: value === null ? noneLabel : value
          };
        }),
    [options, noneLabel]
  );
  const [id] = useState(genId);
  return (
    <div className={'p-field'}>
      <label htmlFor={id}>{title}</label>
      <MultiSelect
        filter={selectOptions.length > 4}
        disabled={selectOptions.length <= 1}
        placeholder={anyLabel}
        value={selectOptions.length === 1 ? [selectOptions[0].value] : value}
        onChange={onChange}
        options={selectOptions}
        css={$multiselect}
      />
    </div>
  );
};

const $multiselect = css`
  .p-multiselect .p-multiselect-panel {
    max-width: 100%;

    .p-multiselect-item {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const RangeFilter: IFilterView<IRangeDesc> = ({
  state,
  updateProperty,
  description: {title, property, min, max, anyLabel, noneLabel, template, precision}
}) => {
  const [valueAsString, normalized]: [string, [number, number]] = useMemo(() => {
    const v = state[property];
    if (!isNumberRange(v)) {
      switch (v) {
        case null:
          return [noneLabel, [min, min]];
        case undefined:
        default:
          return ['', [min, max]];
      }
    } else {
      const [start, end] = v;
      return [
        (template ?? '{{}} - {{}}')
          .replace('{{}}', precision && precision > 0 ? start.toFixed(precision) : start.toString())
          .replace('{{}}', precision && precision > 0 ? end.toFixed(precision) : end.toString()),
        [start, end]
      ];
    }
  }, [state, property, noneLabel, min, max, template, precision]);
  const onChange = useCallback(
    ({value}) => {
      if (isNumberRange(value)) {
        const [start, end] = value;
        if (start === min && end === min) {
          updateProperty(property, null);
        } else if (start === min && end === max) {
          updateProperty(property, undefined);
        } else {
          updateProperty(property, value);
        }
      } else {
        updateProperty(property, undefined);
      }
    },
    [property, updateProperty, min, max]
  );
  const [id] = useState(genId);
  return (
    <div className={'p-field'}>
      <label htmlFor={id}>{title}</label>
      <InputText id={id} placeholder={anyLabel} readOnly disabled value={valueAsString} />
      {min < max && (
        <Slider
          range
          value={normalized}
          min={min}
          max={max}
          step={precision ? Math.pow(10, -precision) : 1}
          onChange={onChange}
        />
      )}
    </div>
  );
};
