/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useWidgetProps } from '../../Root'
import { IStore, StoreModel } from '../../StoreModel'
import Plyr from 'plyr'
import Hls from 'hls.js'
import 'plyr.css'
import { useLocale } from '../../hooks/useLocale'
import { destroy, onAction } from 'mobx-state-tree'
import ResizeObserver from 'resize-observer-polyfill'
import { reaction } from 'mobx'
import ReactDOM from 'react-dom'
import {
  ICanvasMountParams,
  CanvasMountParamsProvider,
} from '../events/canvas/useCanvasMountParams'
import { CustomControls } from '../controls/CustomControls'
import { PlayerSpinner } from '../controls/Spinner'
import { loadPreview } from '../../requests/loadPreview'
import { attachZoomHandler } from '@netvision/front-utils/lib/common/handleZoom'
import { createDisposers } from '../../utils/disposers'
import { genId } from '../../utils/genId'
import { extractImageFromVideo } from '../../utils/extractImageFromVideo'
import { attachTboStreamHandler } from '../streamHandlers/tbo'
import { attachStandardStreamHandler } from '../streamHandlers/standard'
import { getPermissionsByIdsMap } from '@netvision/lib-api-gateway'
import { isFile, isNumber, isRecord, isStream } from '../../utils/basicValidators'
import { $blockScreen, $controls, $root, $top, containerPlayer } from './styles'
import {
  createOverlay,
  getAvailableScaleByWindowSize,
  removeDbClick,
  isIntegraStream,
  INTEGRA_BREAK_LIMIT_SECONDS,
} from './helpers'
import { E2EModule } from '../../__test__'
import { useApiRepository } from '../../hooks/useApiRepository'

export const Player: FC<{ archiveEntry: IArchiveEntry }> = ({ archiveEntry }) => {
  const {
    mountChildren,
    areas,
    props: {
      initialTime,
      canvasStyles,
      width,
      height,
      ratio,
      streamType = 'standard',
      overlayMountHooks,
      className,
      initialOffset = 60 * 1000,
      breakLimit = 3600,
      maxRecordDuration,
      features = {},
    },
  } = useWidgetProps()

  const { lockingEnabled = false } = features
  const { api } = useApiRepository()
  const locale = useLocale()
  const videoRef = useRef<HTMLVideoElement>(null)
  const i18n = locale.plyrLocale
  const rootRef = useRef<HTMLDivElement | null>(null)
  const sideRef = useRef<HTMLDivElement | null>(null)
  const canvasParametersRef = useRef<(() => ICanvasMountParams) | null>(null)

  const [store, setStore] = useState<IStore | null>(null)
  const [sidePanelNode, setSidePanelNode] = useState<HTMLDivElement | null>(null)
  const [showBlockPoster, setShowBlocPoster] = useState(false)

  const canvasParameters = canvasParametersRef.current

  const dvrTimelines = (
    archiveEntry.entity.type === 'File'
      ? [{ start: 0, duration: archiveEntry!.entity.duration ?? 1 }]
      : archiveEntry.entity.dvrTimelines
  ) as Ranges

  useLayoutEffect(
    function createPlayer() {
      if (!Hls.isSupported()) {
        console.error('Your browser cannot play this video')
        return undefined
      }

      const root = rootRef.current

      if (!root) return undefined
      if (root.id === '') root.id = genId()

      const disposers = createDisposers()
      const video = root.getElementsByTagName('video')[0]

      const breakLimitValue = isIntegraStream(archiveEntry)
        ? INTEGRA_BREAK_LIMIT_SECONDS
        : breakLimit
      const store = StoreModel.create({
        breakLimit: breakLimitValue,
        archiveType: archiveEntry.entity.type,
        activeStreamId: archiveEntry.entity.id,
        isDateTape: archiveEntry.entity.type !== 'File',
        width: root.clientWidth,
        height: root.clientHeight,
        scale: (1 * 60 * 1000) / 20, // 1min in 5 pixel
      })

      dvrTimelines && store.setLiveInterval(dvrTimelines)
      store.setCurrentTime(isNumber(initialTime) ? initialTime : store.globalEnd - initialOffset)
      store.setScale(getAvailableScaleByWindowSize(dvrTimelines, store.width))

      setStore(store)
      disposers.add(() => {
        setStore(null)
        destroy(store)
      })

      store.setIsExportable(true)
      // getPermissionsByIdsMap([archiveEntry.entity.id]).then((map) => {
      //   if (!disposers.isFlushed) {
      //     const scopes = map.get(archiveEntry.entity.id)
      //     store.setIsExportable(scopes?.includes('CreateStreamRecord') ?? false)
      //   }
      // })

      store.setIsEnableLockingFeature(lockingEnabled)

      const plyr = new Plyr(video, {
        ratio,
        i18n,
        hideControls: false,
        loadSprite: false,
        storage: {
          enabled: false,
        },
        clickToPlay: false,
        displayDuration: false,
        muted: true,
        controls: [],
        fullscreen: { container: `#${root.id}` } as any,
      })

      const plyrContainer = root.firstElementChild?.firstElementChild as HTMLElement
      const topContainer = root.firstChild as HTMLElement
      store.setVideoRef({ current: video })

      // Size binding
      const onResize: ResizeObserverCallback = ([entry]: ResizeObserverEntry[]) => {
        const { width, height } = entry.contentRect
        store.setWidth(width)
        const videoHeight = height - entry.target.lastElementChild!.getBoundingClientRect().height
        store.setHeight(videoHeight)
        requestAnimationFrame(() => {
          ;[topContainer, video, spinnerContainer, internalOverlay, externalOverlay].forEach(
            (el) => {
              el.style.setProperty('height', videoHeight + 'px')
            },
          )
        })
      }

      const resizeObserver = new ResizeObserver(onResize)
      resizeObserver.observe(root)
      disposers.add(() => resizeObserver.disconnect())

      removeDbClick(plyr)

      store.setSelectionLimit(
        // @ts-ignore
        (maxRecordDuration || window.__NV_CONSTANTS__?.maxRecordDuration || 120) * 1000,
      )

      // Speed
      store.setSpeed(plyr.speed)
      plyr.on('ratechange', () => {
        store.setSpeed(plyr.speed)
      })
      disposers.add(
        reaction(
          () => store.speed,
          (speed) => {
            plyr.speed = speed
          },
        ),
      )

      // Volume
      store.setVolume(plyr.volume, 'player')
      store.setIsMuted(plyr.muted, 'player')
      plyr.on('volumechange', () => {
        store.setVolume(plyr.volume, 'player')
        store.setIsMuted(plyr.muted, 'player')
      })
      disposers.add(
        onAction(store, (actionCall) => {
          const { name, args } = actionCall
          if (name === 'setVolume' && args !== undefined) {
            const [volume, source] = args
            if (source !== 'player') {
              plyr.volume = volume
            }
          }
        }),
      )
      disposers.add(
        onAction(store, (actionCall) => {
          const { name, args } = actionCall
          if (name === 'setIsMuted' && args !== undefined) {
            const [muted, source] = args
            if (source !== 'player') {
              plyr.muted = muted
            }
          }
        }),
      )

      // Fullscreen
      plyr.on('enterfullscreen', () => {
        store.enterFullScreen('plyr')
      })
      plyr.on('exitfullscreen', () => {
        store.exitFullScreen('plyr')
        // TODO: remove this resize mock after bug is fixed for chrome and safari
        // bug: resize is not triggered after fullscreen is left using ESC button
        onResize(
          [
            {
              target: root,
              contentRect: root.getBoundingClientRect(),
            } as unknown as ResizeObserverEntry,
          ],
          resizeObserver,
        )
      })
      disposers.add(
        onAction(store, (action) => {
          let enter = undefined
          switch (action.name) {
            case 'enterFullScreen':
              enter = true //
              break
            case 'exitFullScreen':
              enter = false
              break
          }
          if (typeof enter === 'boolean') {
            if (action.args) {
              const [source] = action.args
              if (source !== 'plyr') {
                if (enter) {
                  plyr.fullscreen.enter()
                } else {
                  plyr.fullscreen.exit()
                }
              }
            }
          }
        }),
      )

      // Loading/Waiting binding
      plyr.on('waiting', () => {
        store.setIsWaiting(true)
      })
      plyr.on('canplay', () => {
        store.setIsWaiting(false)
      })
      plyr.on('seeking', () => {
        store.setIsWaiting(true)
      })
      plyr.on('seeked', () => {
        store.setIsWaiting(false)
      })
      // Dim video on loading
      disposers.add(
        reaction(
          () => store.isWaiting,
          (isWaiting) => {
            if (isWaiting) {
              video.style.filter = 'brightness(0.9)'
            } else {
              video.style.filter = 'unset'
            }
          },
        ),
      )

      plyr.once('canplay', async () => {
        if (store.isPlaying) {
          await plyr.play()
        }
      })
      // Play/pause binding
      plyr.on('playing', async () => {
        if (plyr.playing) {
          await store.play()
        }
      })
      plyr.on('pause', async () => {
        await store.pause()
      })

      disposers.add(
        reaction(
          () => store.isPlaying,
          async (isPlaying) => {
            if (isPlaying) {
              await plyr.play()
            } else {
              await plyr.pause()
            }
          },
        ),
      )

      // naturalSize
      plyr.on('canplay', () => {
        store.setNaturalSize(video.videoWidth, video.videoHeight)
      })
      plyr.on('play', () => {
        store.setNaturalSize(video.videoWidth, video.videoHeight)
      })

      // Play export logic
      disposers.add(
        reaction(
          () => store.isExportPlaying,
          (isExportPlaying) => {
            if (isExportPlaying) {
              const { exportRangesStart } = store
              if (exportRangesStart) {
                store.pause()
                store.setCurrentTime(exportRangesStart)
                plyr.play()
              }
            } else {
              plyr.pause()
            }
          },
        ),
      )

      disposers.add(
        reaction(
          () => [store.exportStart, store.exportEnd],
          () => store.stopPlayingExport(),
        ),
      )

      disposers.add(
        reaction(
          () => store.isHideScreen,
          (isHideScreen) => {
            setShowBlocPoster(isHideScreen)
          },
        ),
      )

      disposers.add(
        reaction(
          () => [store.exportRangesEnd, store.currentTime] as const,
          ([end, current]) => {
            if (typeof end === 'number' && end <= current) {
              store.stopPlayingExport()
            }
          },
        ),
      )
      plyr.once('pause', () => store.stopPlayingExport())

      // Spinner
      const spinnerContainer = document.createElement('div')
      spinnerContainer.dataset.role = 'spinner'
      spinnerContainer.style.position = 'absolute'
      spinnerContainer.style.top = '0'
      spinnerContainer.style.left = '0'
      spinnerContainer.style.width = '100%'
      spinnerContainer.style.height = '160px'
      plyrContainer.appendChild(spinnerContainer)
      ReactDOM.render(<PlayerSpinner store={store} />, spinnerContainer)

      // Mount external overlay
      const externalOverlay = createOverlay()
      externalOverlay.dataset.role = 'external-overlay'
      plyrContainer.appendChild(externalOverlay)
      const getPreviewImage = (time: number, signal: AbortSignal) =>
        loadPreview(api, archiveEntry.entity, time, signal).then((blob) => {
          if (!blob || signal.aborted) return null
          if (blob!.type.startsWith('image')) return blob
          return extractImageFromVideo(blob!)
        })

      if (isNumber(initialTime)) {
        const abr = new AbortController()
        disposers.add(() => abr.abort())
        getPreviewImage(initialTime, abr.signal).then((imageBlob) => {
          if (!imageBlob || abr.signal.aborted) return
          if (!imageBlob!.type.startsWith('image')) return
          const src = window.URL.createObjectURL(imageBlob!)
          video.setAttribute('poster', src)
          const img = new Image()
          img.src = src
          img.onload = () => {
            store.setNaturalSize(img.naturalWidth, img.naturalHeight)
            overlayMountHooks?.mount({
              overlayContainer: externalOverlay,
              overlayBase: video,
              naturalHeight: img.naturalHeight,
              naturalWidth: img.naturalWidth,
            })
            store.setIsWaiting(false)
            disposers.add(() => overlayMountHooks?.unmount())
          }
          disposers.add(() => {
            video.removeAttribute('poster')
            window.URL.revokeObjectURL(src)
          })
        })
      }

      // Used to display events by itself
      const internalOverlay = createOverlay()
      internalOverlay.dataset.role = 'internal-overlay'
      plyrContainer.appendChild(internalOverlay)

      // on scroll, zoom video and overlays
      disposers.add(attachZoomHandler(plyrContainer, [video, internalOverlay, externalOverlay]))

      canvasParametersRef.current = () => ({
        mountChildren,
        areas,
        canvasStyles,
        overlayContainer: internalOverlay,
        overlayBase: video,
        naturalHeight: video.videoHeight,
        naturalWidth: video.videoWidth,
      })

      const attachToDisposers = async (hlsStreamUrl?: string) => {
        switch (streamType) {
          case 'tbo':
            disposers.add(
              attachTboStreamHandler({
                entity: archiveEntry.entity,
                hlsStreamUrl,
                store,
                video,
                plyr,
                getPreviewImage,
              }),
            )
            break
          case 'standard':
          default:
            disposers.add(
              attachStandardStreamHandler({
                archiveEntry,
                hlsStreamUrl,
                store,
                video,
                plyr,
                breakLimit: breakLimitValue,
                api,
              }),
            )
        }
      }

      if (isFile(archiveEntry.entity) || isRecord(archiveEntry.entity)) {
        attachToDisposers(archiveEntry.entity.streamUrl)
      }

      if (isStream(archiveEntry.entity)) {
        attachToDisposers(archiveEntry.entity.hlsStreamUrl)
        archiveEntry.entity.exportStreamUrl &&
          store.setExportStreamUrl(archiveEntry.entity.exportStreamUrl)
      }

      return () => {
        ReactDOM.unmountComponentAtNode(spinnerContainer)
        spinnerContainer.remove()
        disposers.flush()
        store.setVideoRef({})
        setShowBlocPoster(false)
        if (overlayMountHooks) {
          overlayMountHooks.unmount()
        }
        plyr.destroy()
      }
    },

    // recreated only on archiveEntry change
    // eslint-disable-next-line
    [archiveEntry],
  )

  useEffect(() => {
    setSidePanelNode(sideRef.current)
  }, [])

  return (
    <div
      data-cy={E2EModule.attributes.archivePlayerWrapper}
      css={$root}
      className={className}
      ref={rootRef}
      style={{ height, width }}>
      <div data-cy={E2EModule.attributes.playerScreen} css={$top}>
        <div id="loader" css={containerPlayer}>
          <video ref={videoRef} />
          {showBlockPoster && <div css={$blockScreen}>{locale.blockedChunk}</div>}
        </div>
        <aside ref={sideRef} />
      </div>
      {sidePanelNode && store && canvasParameters && (
        <div css={$controls}>
          <CanvasMountParamsProvider value={canvasParameters}>
            <CustomControls
              sidePanelNode={sidePanelNode}
              archiveEntry={archiveEntry}
              store={store}
            />
          </CanvasMountParamsProvider>
        </div>
      )}
    </div>
  )
}
