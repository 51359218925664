import {memoize} from '@netvision/lib-api-gateway';
import {createContext, createElement, FC, useContext, useState} from 'react';

const colors = ['--alt-color-1', '--alt-color-2', '--alt-color-3', '--alt-color-4', '--alt-color-5'];
const colorGenerator = () => {
  let i = -1;
  return memoize((key: string) => {
    i++;
    return `var(${colors[i % colors.length]})`;
  });
};

const ColorContext = createContext(colorGenerator());

export function useGetColor() {
  return useContext(ColorContext);
}

export const ColorProvider: FC = ({children}) => {
  const [getColor] = useState(colorGenerator);
  return createElement(ColorContext.Provider, {value: getColor}, children);
};
