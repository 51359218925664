/** @jsx jsx */
import {jsx} from '@emotion/react';
import {makeButton} from './makeButton';

export const Refresh = makeButton((props) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M23.5337 8.46846C25.467 10.403 26.667 13.058 26.667 16.0065C26.667 21.9035 21.8937 26.6665 16.0003 26.6665C10.107 26.6665 5.33366 21.9035 5.33366 16.0065C5.33366 11.0301 8.73366 6.88081 13.3337 5.6934L13.3337 8.46846C10.227 9.56248 8.00032 12.5243 8.00032 16.0065C8.00032 20.4226 11.587 24.0115 16.0003 24.0115C20.4137 24.0115 24.0003 20.4226 24.0003 16.0065C24.0003 13.7918 23.0803 11.8172 21.627 10.3763L17.3337 14.6723L17.3337 5.33317L26.667 5.33317L23.5337 8.46846Z"
        fill="#959DA8"
      />
    </svg>
  );
});
