/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {FC, Fragment, useEffect, useMemo, useRef, useState} from 'react';
import {Tape} from './tape/Tape';
import {StoreProvider, useStore} from '../../hooks/useStore';
import {IStore} from '../../StoreModel';
import {Panel} from './panel/Panel';
import {createPortal} from 'react-dom';
import {rem} from '../../utils/rem';
import {observer} from 'mobx-react-lite';
import {useBoolean} from '../../hooks/useBoolean';
import {Button} from 'primereact/button';
import {reaction} from 'mobx';
import {useWidgetProps} from '../../Root';
import {Events} from '../events';
import {SingleEvent} from '../events/SingleEvent';
import { E2EModule } from '../../__test__';

const createBreakpoints = <T,>(breakpoints: [number, T][], initial: T) => {
  const sorted = breakpoints.slice().sort(([a], [b]) => a - b);
  return (size: number): T => {
    const index = sorted.findIndex(([n]) => size < n);
    if (index === -1) {
      return initial;
    } else {
      return breakpoints[index][1];
    }
  };
};

export const CustomControls: FC<{
  archiveEntry: IArchiveEntry;
  store: IStore;
  sidePanelNode: HTMLElement;
}> = ({archiveEntry, store, sidePanelNode}) => {
  const {
    props: {eventExtraProps = [], showEvents = true, event, eventCameraField = 'cameraId', eventEntityType = 'TboEvent'}
  } = useWidgetProps();
  const showSidePanel = !event && showEvents;
  const marksNodeRef = useRef<HTMLDivElement | null>(null);
  const [marksPortalNode, setMarksPortalNode] = useState<HTMLDivElement | null>(null);
  useEffect(() => {
    setMarksPortalNode(marksNodeRef.current);
  }, []);

  return (
    <StoreProvider value={store}>
      <Tape>
        <div ref={marksNodeRef} />
      </Tape>
      <Panel archiveEntry={archiveEntry} />
      {showSidePanel && marksPortalNode && (
        <SidePanel sidePanelNode={sidePanelNode}>
          <Events
            entity={archiveEntry.entity}
            eventEntityType={eventEntityType}
            cameraId={archiveEntry.entity.type === 'Stream' ? archiveEntry.entity.cameraId : archiveEntry.entity.id}
            cameraField={eventCameraField}
            marksPortalNode={marksPortalNode}
            eventExtraProps={eventExtraProps}
          />
        </SidePanel>
      )}
      {event && marksPortalNode && <SingleEvent event={event} marksPortalNode={marksPortalNode} />}
    </StoreProvider>
  );
};

const SidePanel: FC<{
  sidePanelNode: HTMLElement;
}> = observer(({sidePanelNode, children}) => {
  const store = useStore();
  const {value: isOpen, setTrue: open, setFalse: close} = useBoolean(true);
  const sidePanelBreakpoints = useMemo(
    () =>
      createBreakpoints(
        [
          [rem(1280), 'sm'],
          [rem(1440), 'md']
        ],
        'lg'
      ),
    []
  );
  useEffect(() => {
    return reaction(
      () => store.fullScreen,
      (fullscreen) => {
        if (fullscreen) {
          close();
        }
      }
    );
  }, [close, store]);
  return createPortal(
    <Fragment>
      {isOpen && (
        <div css={sidePanelCss} data-mode={sidePanelBreakpoints(store.width)} data-open={isOpen}>
          {children}
        </div>
      )}
      {isOpen ? (
        <Button
          onClick={close}
          data-cy={E2EModule.attributes.hideEventListButton}
          className={'p-button-outlined p-button-rounded p-button-secondary'}
          icon={'mdi mdi-24px mdi-chevron-right'}
          style={{width: 'var(--input-height)', height: 'var(--input-height)'}}
          css={$showEvents}
        />
      ) : (
        <Button
          data-cy={E2EModule.attributes.showEventListButton}
          className={'p-button-blurred'}
          icon={'mdi mdi-20px mdi-format-list-bulleted'}
          onClick={open}
          css={$showEvents}
        />
      )}
    </Fragment>,
    sidePanelNode
  );
});

// language=SCSS
const sidePanelCss = css`
  & {
    padding: calc(20rem / var(--bfs)) calc(20rem / var(--bfs)) 0;
  }
  &[data-mode='sm'] {
    width: calc(360rem / var(--bfs));
  }
  &[data-mode='md'] {
    width: calc(400rem / var(--bfs));
  }
  &[data-mode='lg'] {
    width: calc(450rem / var(--bfs));
  }
`;

// language=SCSS
const $showEvents = css`
  & {
    position: absolute;
    top: var(--spacer-sm);
    right: var(--spacer-sm);
    z-index: 2;
    width: calc(48rem / var(--bfs));
  }
`;
