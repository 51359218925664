/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {useStore} from '../../../hooks/useStore';
import {Fragment, FC, useRef, memo} from 'react';
import {observer} from 'mobx-react-lite';
import {range, secondsToHms} from '../utils';
import {Blur} from './Blur';
import {Segment} from './Segment';

export const VirtualTape: FC = observer(function VirtualTape({children}) {
  const store = useStore();
  const {
    currentTime,
    tapeOffset,
    dTime: [dTime, bigD],
    scale,
    width,
    isPlaying,
    isDateTape
  } = store;
  const dLength = dTime / scale;
  const N = width / dLength;
  const midIndex = Math.floor((currentTime - tapeOffset) / dTime);
  const marks = range(midIndex - Math.floor(N / 2), midIndex + Math.ceil(N / 2) + 1).map((index) => {
    return (
      <Mark
        key={index}
        isDateTape={isDateTape}
        index={index}
        tapeOffset={tapeOffset}
        dLength={dLength}
        dTime={dTime}
        bigD={bigD}
      />
    );
  });
  const offset = (tapeOffset - currentTime) / scale;
  const left = offset + width / 2;

  // smooth playing transition
  const previousValueRef = useRef(currentTime);
  const previousValue = previousValueRef.current;
  previousValueRef.current = currentTime;
  const diff = Math.abs(previousValue - currentTime);
  const transition = isPlaying && diff < 500 ? `left ${diff}ms linear` : undefined;

  return (
    <div css={virtualTapeStyle} style={{left, transition}}>
      <div>{marks}</div>
      <DeadZone />
      {children}
    </div>
  );
});

// language=SCSS
const virtualTapeStyle = css`
  & {
    height: 100%;
    position: absolute;
  }
`;

const Mark: FC<{index: number; isDateTape: boolean; tapeOffset: number; dLength: number; dTime: number; bigD: number}> =
  memo(({index, tapeOffset, isDateTape, dLength, dTime, bigD}) => {
    const offset = index * dLength;
    const style = {left: offset};

    let time = null;
    let cssProp = [markStyle];
    const timestamp = tapeOffset + index * dTime;

    if (index % bigD === 0) {
      if ((timestamp - new Date().getTimezoneOffset() * 60 * 1000) % (24 * 3600 * 1000) === 0) {
        cssProp.push(largeMarkStyle);
        time = isDateTape
          ? new Date(timestamp).toLocaleDateString('ru-ru', {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric'
            })
          : timestamp > 0
          ? secondsToHms(timestamp / 1000)
          : '';
      } else {
        cssProp.push(bigMarkStyle);
        time = isDateTape
          ? new Date(timestamp).toLocaleTimeString('ru-ru', {
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric'
            })
          : timestamp > 0
          ? secondsToHms(timestamp / 1000)
          : '';
      }
    }
    return timestamp > 0 ? (
      <div style={style} css={cssProp}>
        {time && <span css={markerTextStyle}>{time}</span>}
      </div>
    ) : (
      <div></div>
    );
  });

// language=SCSS
const markStyle = css`
  & {
    width: calc(2rem / var(--bfs));
    height: calc((100% - 30rem / var(--bfs)) / 3);
    background: var(--text-color-secondary);
    position: absolute;
    bottom: 0;
    opacity: 0.4;
  }
`;

// language=SCSS
const bigMarkStyle = css`
  & {
    height: calc(100% - 30rem / var(--bfs));
    background: var(--text-color-secondary);
    opacity: 0.6;
  }
`;

// language=SCSS
const largeMarkStyle = css`
  & {
    height: calc(100% - 10rem / var(--bfs));
    background: var(--text-color-secondary);
    opacity: 0.8;
  }
`;

// language=SCSS
const markerTextStyle = css`
  & {
    position: absolute;
    color: var(--text-color-secondary);
    font-weight: 500;
    font-size: calc(12rem / var(--bfs));
    top: calc(0rem / var(--bfs));
    left: calc(10rem / var(--bfs));
  }
`;

const boxShadow = `0 0 calc(60rem / var(--bfs)) calc(30rem / var(--bfs)) var(--surface-a), 0 0 calc(30rem / var(--bfs)) calc(15rem / var(--bfs)) var(--surface-a)`;

const DeadZone = () => {
  const store = useStore();
  const limits = store.visibleTimeRange;
  return (
    <Fragment>
      <Segment start={limits[0]} end={store.globalStart}>
        <div css={deadZoneLine} />
        <Blur boxShadow={boxShadow} />
      </Segment>
      <Segment start={store.globalEnd} end={limits[1]}>
        <div css={deadZoneLine} />
        <Blur boxShadow={boxShadow} />
      </Segment>
    </Fragment>
  );
};

// language=SCSS
const deadZoneLine = css`
  & {
    position: absolute;
    background: var(--surface-a);
    bottom: 0;
    height: calc(2rem / var(--bfs));
    border-radius: calc(1rem / var(--bfs));
    width: 100%;
  }
`;
