/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { useStore } from '../../../hooks/useStore'
import { FC, useCallback, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useHoldAndMove } from '../utils'
import { Blur } from './Blur'
import { rem } from '../../../utils/rem'
import { E2EModule } from '../../../__test__'
import { IRange, IStore } from '../../../StoreModel'

const formatTime = (ms: number) => {
  return new Date(ms).toLocaleTimeString('ru-ru', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  })
}

const formatDuration = (ms: number) => {
  return new Date(ms).toLocaleTimeString('ru-ru', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZone: 'UTC',
  })
}

export function binarySearch(ranges: IRange[], target: number) {
  let left = 0
  let right = ranges.length - 1
  let iterations = 0
  while (left <= right) {
    iterations++
    const mid = Math.floor((left + right) / 2)
    const range = ranges[mid]
    if (range.start <= target && target <= range.end) {
      return mid
    } else if (target < range.start) {
      right = mid - 1
    } else {
      left = mid + 1
    }
  }

  return -1
}

export const getAllRangesFromStartEnd = (
  start: number,
  end: number,
  startTime: number,
  width: number,
  store: IStore,
) => {
  if (start !== -1 && end !== -1) {
    return store.availableRanges.slice(start, end + 1)
  }

  if (start !== -1 && end === -1) {

    const startRange = store.availableRanges[start]
    let probablyIntervals = 1
    let indexPosition = start + 1
    let tmpWidth = width - startRange.duration
    while (tmpWidth >= 0) {
      const el = store.availableRanges[indexPosition]
      if (!el) break
      tmpWidth =- el.duration
      indexPosition++
      probablyIntervals++
    }

    const endTime = startTime + width
    const data = [startRange]
    for (let i = start + 1; i <= start + probablyIntervals; i++) {
      const element = store.availableRanges[i]
      if (!element) break
      if (element?.end <= endTime) data.push(element)
    }
    return data
  }

  if (start === -1 && end !== -1) {
    const endRange = store.availableRanges[end]
    let probablyIntervals = 1
    let indexPosition = end - 1
    let tmpWidth = width - endRange.duration
    while (tmpWidth >= 0) {
      const el = store.availableRanges[indexPosition]
      if (!el) break
      tmpWidth =- el.duration
      indexPosition--
      probablyIntervals++
    }
    const data = [endRange]
    for (let i = end - 1; i >= end - probablyIntervals; i--) {
      const element = store.availableRanges[i]
      if (element?.start >= startTime) data.unshift(element)
    }
    return data
  }

  return []
}

export const ExportRegion = observer(function ExportRegion() {
  const store = useStore()

  const handleSetUp = useCallback(
    (e) => {
      e.stopPropagation()
      const start = e.currentTarget.dataset.name === 'left'
      return {
        start,
        value: start ? store.exportStart : store.exportEnd,
        end: start ? store.exportEnd : store.exportStart,
      }
    },
    [store],
  )

  const handleOnMove = useCallback(
    (diff, initialData) => {
      const newExportTime = initialData.value - diff * store.scale
      if (!store.isEnableLockingFeature) {
        initialData.start ? store.setExportStart(newExportTime) : store.setExportEnd(newExportTime)
        return
      }

      const oldWidth = Math.abs(initialData.end - initialData.value)
      const newWidth = Math.abs(
        initialData.start
          ? initialData.end - newExportTime
          : newExportTime - initialData.end,
      )

      const startRangeIndex = binarySearch(store.availableRanges, initialData.start ? initialData.value : initialData.value - oldWidth)
      const startRangeIndexEnd = binarySearch(
        store.availableRanges,
        initialData.start ? initialData.value + oldWidth : initialData.value
      )
      const startRanges: IRange[] = getAllRangesFromStartEnd(
        startRangeIndex,
        startRangeIndexEnd,
        initialData.value,
        oldWidth,
        store,
      )

      const newExportEndTime = initialData.start
        ? newExportTime + newWidth
        : newExportTime - newWidth
      const newRangeIndex = binarySearch(store.availableRanges, initialData.start ? newExportTime : initialData.end)
      const newRangeIndexEnd = binarySearch(store.availableRanges, initialData.start ? newExportEndTime : newExportTime)
      const newRanges: IRange[] = getAllRangesFromStartEnd(
        newRangeIndex,
        newRangeIndexEnd,
        newExportTime,
        newWidth,
        store,
      )

      const startIntervalWithNonBlock =
        startRanges.length && startRanges.every((r) => r.streamId === store.activeStreamId)
      const startIntervalWithOnlyBlock =
        startRanges.length && startRanges.every((r) => r.streamId !== store.activeStreamId)
      const newIntervalWithBlock =
        newRanges.length && newRanges.some((r) => r.streamId !== store.activeStreamId)
      const newIntervalWithNonBlock =
        newRanges.length && newRanges.some((r) => r.streamId == store.activeStreamId)

      if (newRanges.length === 0 || startRanges.length === 0) return
      if (startIntervalWithNonBlock && newIntervalWithBlock) return
      if (startIntervalWithOnlyBlock && newIntervalWithNonBlock) return

      initialData.start ? store.setExportStart(newExportTime) : store.setExportEnd(newExportTime)
    },
    [store],
  )

  const setUp = useCallback(() => {
    return {
      start: store.exportStart,
      end: store.exportEnd,
    }
  }, [store])

  const onMove = useCallback(
    (diff, initialData, update) => {
      const newExportTime = initialData.start - diff * store.scale
      if (!store.isEnableLockingFeature) {
        store.moveExportRegion(newExportTime)
        return
      }

      const delta = 10
      const oldWidth = initialData.end - initialData.start
      const newWidth = store.exportEnd - store.exportStart
      const startRangeIndex = binarySearch(store.availableRanges, initialData.start)
      const startRangeIndexEnd = binarySearch(store.availableRanges, initialData.start + oldWidth)

      const startRanges: IRange[] = getAllRangesFromStartEnd(
        startRangeIndex,
        startRangeIndexEnd,
        initialData.start,
        oldWidth,
        store,
      )
      const newRangeIndex = binarySearch(store.availableRanges, newExportTime)
      const newRangeIndexEnd = binarySearch(store.availableRanges, newExportTime + newWidth)
      const newRanges: IRange[] = getAllRangesFromStartEnd(
        newRangeIndex,
        newRangeIndexEnd,
        newExportTime,
        newWidth,
        store,
      )

      const startIntervalWithNonBlock =
        startRanges.length && startRanges.every((r) => r.streamId === store.activeStreamId)
      const startIntervalWithOnlyBlock =
        startRanges.length && startRanges.every((r) => r.streamId !== store.activeStreamId)
      const newIntervalWithBlock =
        newRanges.length && newRanges.some((r) => r.streamId !== store.activeStreamId)
      const newIntervalWithNonBlock =
        newRanges.length && newRanges.some((r) => r.streamId == store.activeStreamId)

      if (startIntervalWithNonBlock && newIntervalWithBlock) {
        const { start: blockStart, end: blockEnd, duration } = newRanges.find(
          (r) => r.streamId !== store.activeStreamId,
        )!

        const threshold = oldWidth * 0.33
        const isNarrow = duration <= threshold
        const actualThreshold = isNarrow ? (duration / 2) : threshold
        if (blockStart > initialData.start) {
          const diff = Math.abs(blockStart - (newExportTime + oldWidth))
          if (diff > actualThreshold + delta) {
            const newTime = newExportTime + threshold + diff - delta
            store.createExportRegion(isNarrow ? blockStart : newTime > blockStart ? newTime : blockStart)
            update()
          }
          return
        }

        const diff = Math.abs(newExportTime - blockEnd)
        if (diff > threshold) {
          const newTime = newExportTime - threshold - diff
          store.createExportRegion(isNarrow ? blockStart : newTime)
          update()
        }
        return
      }

      if (startIntervalWithOnlyBlock && newIntervalWithNonBlock) {
        const { start, end, duration } = newRanges.find((r) => r.streamId === store.activeStreamId)!
        const threshold = oldWidth * 0.33
        const isNarrow = duration <= threshold
        const actualThreshold = isNarrow ? (duration / 2) : threshold
        if (start > initialData.start) {
          const diff = Math.abs(start - (newExportTime + oldWidth))
          if (diff > actualThreshold + delta) {
            const newTime = newExportTime + diff + threshold - delta
            store.createExportRegion(isNarrow ? start : newTime > start ? newTime : start)
            update()
          }
          return
        }

        const diff = end - newExportTime
        if (diff > actualThreshold) {
          const newTime = newExportTime - threshold - diff
          store.createExportRegion(isNarrow ? start : newTime)
          update()
        }
        return
      }

      store.moveExportRegion(newExportTime)
    },
    [store],
  )

  const onPointerDown = useHoldAndMove(onMove, setUp)
  const handleOnPointerDown = useHoldAndMove(handleOnMove, handleSetUp)

  const playExport = useCallback(() => {
    store.playExport()
  }, [store])

  const deleteExport = useCallback(() => {
    store.setSelectionMode(false)
  }, [store])

  // <-- render -->
  const offset = (store.exportStart - store.tapeOffset) / store.scale
  const width = (store.exportEnd - store.exportStart) / store.scale
  const isTiny = width < rem(75)
  const style = {
    left: offset,
    width,
  }
  const duration = formatDuration(store.exportEnd - store.exportStart)
  return (
    <div css={exportRegionStyle} style={style} data-cy={E2EModule.attributes.selectedFragment}>
      <Blur boxShadow={boxShadow} />
      <div css={exportRegionFillStyle} onPointerDown={onPointerDown}>
        {store.exportRanges.map(({ start, end }, index, arr) => {
          const width = (end - start) / store.scale
          return (
            <div
              css={exportIncludesStyle}
              style={{
                width: index === arr.length - 1 ? width : Math.floor(width),
                left: (start - store.exportStart) / store.scale,
              }}
              key={`${start}:${end}`}
            />
          )
        })}
        {!isTiny && <span>{duration}</span>}
      </div>
      <div
        data-name={'left'}
        onPointerDown={handleOnPointerDown}
        css={exportRegionHandleStyle}
        style={{ left: 0 }}>
        <Handle />
      </div>
      <div
        data-name={'right'}
        onPointerDown={handleOnPointerDown}
        css={exportRegionHandleStyle}
        style={{ left: '100%' }}>
        <Handle />
      </div>
      <div
        data-role={'label'}
        css={dateStringStyle}
        style={{ left: 0, transform: 'translateX(calc(-100% - 15rem / var(--bfs)))' }}>
        {formatTime(store.exportStart)}
      </div>
      <div
        data-role={'label'}
        css={dateStringStyle}
        style={{ right: 0, transform: 'translateX(calc(100% + 15rem / var(--bfs)))' }}>
        {formatTime(store.exportEnd)}
      </div>
      <div css={buttonLabel} style={{ bottom: 0, left: 0, transform: 'translateY(100%)' }}>
        {isTiny && <span style={{ marginLeft: 2 }}>{duration}</span>}
        <button onClick={playExport}>
          <i style={{ marginRight: 2 }} className="mdi mdi-play" />
          {'Воспроизвести'}
        </button>
        <button onClick={deleteExport}>
          <i style={{ marginRight: 2 }} className="mdi mdi-close" />
          {'Сбросить'}
        </button>
      </div>
    </div>
  )
})

const boxShadow =
  '0 0 calc(40rem/var(--bfs)) calc(10rem/var(--bfs)) var(--primary-color), 0 0 calc(30rem/var(--bfs)) calc(5rem/var(--bfs)) var(--primary-color)'

// language=SCSS
const exportRegionStyle = css`
  & {
    position: absolute;
    z-index: 1;
    bottom: 0;
    height: calc(100% - 30rem / var(--bfs));
  }
`

// language=SCSS
const exportRegionHandleStyle = css`
  & {
    touch-action: none;
    cursor: ew-resize;

    position: absolute;
    top: 0;
    height: 100%;
    width: calc(2rem / var(--bfs));
    background: var(--primary-color);
    border-radius: var(--border-radius);

    > svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`

// language=SCSS
const exportRegionFillStyle = css`
  & {
    touch-action: none;
    cursor: grab;

    position: relative;
    width: 100%;
    height: 100%;
    font-size: 12px;
    padding-top: 6px;
    overflow: hidden;
  }

  &:active {
    cursor: grabbing;
  }

  & > span {
    position: absolute;
    top: 23%;
    left: 50%;
    transform: translateX(-50%);
    font-size: calc(12rem / var(--bfs));
    font-weight: 500;
    color: var(--text-color);
  }
`

// language=SCSS
const dateStringStyle = css`
  & {
    position: absolute;
    top: 60%;
    font-size: calc(12rem / var(--bfs));
    color: var(--text-color);
    font-weight: 500;
    pointer-events: none;
  }
`

// language=SCSS
const buttonLabel = css`
  & {
    background: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    position: absolute;
    width: fit-content;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity 200ms ease;
  }

  div:hover > & {
    opacity: 1;
    pointer-events: auto;
    z-index: 1;
  }

  & > button {
    padding: 2px 8px 4px 2px;
    outline: none !important;
    border: none;
    background: transparent;
    color: white;
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
  }

  & > button > i {
    font-size: 12px;
  }

  & > button:hover {
    background: black;
  }

  & > span {
    padding: 2px;
  }
`

// language=SCSS
const exportIncludesStyle = css`
  & {
    position: absolute;
    top: 0;
    //background: var(--primary-color);
    height: 100%;
  }
`

const Handle: FC<JSX.IntrinsicElements['svg']> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      css={handleCss}>
      <path
        d="M4.22185 15.5356L7.75738 12L4.22185 8.46451L5.63606 7.05029L10.5858 12L5.63067 16.9444L4.22185 15.5356Z"
        fill="white"
      />
      <path
        d="M19.7782 8.46451L16.2427 12L19.7782 15.5356L18.364 16.9498L13.4142 12L18.3694 7.05569L19.7782 8.46451Z"
        fill="white"
      />
    </svg>
  )
}

// language=SCSS
const handleCss = css`
  & > path {
    fill: var(--text-color-active-secondary);
  }
`
