type NumberRange = [number, number];

function rangeIncludes(range: NumberRange, value: number) {
  return range[0] <= value && value <= range[1];
}

function after(range: NumberRange, value: number) {
  return range[1] < value;
}

function findClosestRangeIndex(ranges: NumberRange[], value: number) {
  let index = 0;
  while (index < ranges.length && after(ranges[index], value)) {
    index++;
  }
  return index;
}

export function createRangeMap() {
  const ranges: Array<NumberRange> = [];

  function addRange(range: NumberRange): Array<NumberRange> {
    if (ranges.length === 0) {
      ranges.push(range);
      return [range];
    }
    const [A, B] = range;
    const aIndex = findClosestRangeIndex(ranges, A);
    const bIndex = findClosestRangeIndex(ranges, B);
    if (aIndex <= bIndex) {
      const firstRange = ranges[aIndex];
      const lastRange = ranges[bIndex];
      // if startRange does not exist, it means that start of the new range is out of current ranges
      if (!firstRange) {
        ranges.push(range);
        return [range];
      }
      // here are all ranges that inside the new one, except the last
      const includedRanges = ranges.slice(aIndex, bIndex);
      // start is inside the first range
      const aIsBeforeFirst = !rangeIncludes(firstRange, A);
      const bIsInsideLast = !!lastRange && rangeIncludes(lastRange, B);
      // merge ranges
      ranges.splice(
        aIndex,
        // if B is inside last range, we should also merge it
        includedRanges.length + (bIsInsideLast ? 1 : 0),
        [
          // if A is before the first, the new starts with A
          aIsBeforeFirst ? A : firstRange[0],
          // if B is inside the last, the new ends with the last's end
          bIsInsideLast ? lastRange[1] : B
        ]
      );
      // return diff
      return includedRanges.reduce(
        (acc, value, index, array) => {
          if (index === array.length - 1) {
            // if B is inside the last, we only need values until the start of the last
            acc.push([value[1], bIsInsideLast ? lastRange[0] : B]);
          } else {
            // add missing range between current and the next one
            acc.push([value[1], array[index + 1][0]]);
          }
          return acc;
        },
        // if A is before the first range, additional range if included
        aIsBeforeFirst ? [[A, firstRange[0]]] : []
      );
    }
    throw Error('Unexpected state');
  }

  return {
    addRange,
    get values() {
      return ranges.slice();
    }
  };
}
