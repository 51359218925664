import { useCallback, useEffect, useRef, useState } from 'react'
import { getStream, getCameraStreams, saveArchiveRecord } from '../requests/Archive'
import { useLocale } from './useLocale'
import { useApiRepository } from './useApiRepository'
import { IEntity } from '@netvision/lib-api-repo'

export function useStream({ id: cameraId }: IEntity): [string, boolean, IArchiveEntry | null] {
  const { api } = useApiRepository()
  const locale = useLocale()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const loadedStreams = useRef<IStream[]>(null!)
  const [archiveEntry, setArchiveEntry] = useState<IArchiveEntry | null>(null)

  const setArchiveEntity = useCallback(
    (archive: IStream): IArchiveEntry => {
      return {
        entity: archive,
        saveRecord: saveArchiveRecord.bind(undefined, api),
        refresh: () =>
          getStream(api, archive.id).then((archive) => setArchiveEntry(setArchiveEntity(archive!))),
        streams: loadedStreams.current,
        setActiveStream: (stream) => setArchiveEntry(setArchiveEntity(stream)),
        getDvrTimelines: () =>
          getStream(api, archive.id).then((a) => {
            const timelines = a?.dvrTimelines
            if (timelines) {
              archive.dvrTimelines = timelines
            }
            return timelines
          }),
      }
    },
    [api],
  )

  useEffect(() => {
    let mounted = true
    setLoading(true)

    const updateArchiveEntry = (streams: IStream[]) => {
      if (!mounted) return
      if (!streams) {
        setError(locale.archiveIsUnavailable)
        setArchiveEntry(null)
        return
      }

      const validStreams = streams
        .filter((stream) => {
          const hasRanges = Array.isArray(stream.dvrTimelines) && stream.dvrTimelines[0]
          if (!hasRanges) console.warn('stream does not have dvrTimelines', stream)
          return hasRanges
        })
        .sort((a, b) => a.orderIndex - b.orderIndex)

      const [activeStream] = validStreams
      if (!activeStream) {
        console.error('Archive recording is not enabled or the camera has invalid streams')
        setError(locale.archiveIsUnavailable)
        setArchiveEntry(null)
        return
      }

      loadedStreams.current = validStreams
      setArchiveEntry(setArchiveEntity(activeStream))
    }

    getCameraStreams(api, cameraId as string)
      .then(updateArchiveEntry)
      .finally(() => mounted && setLoading(false))

    return () => {
      mounted = false
    }
  }, [locale, cameraId, api, setArchiveEntity])
  return [error, loading, archiveEntry]
}
