import { Instance, types } from 'mobx-state-tree'
import { reaction } from 'mobx'
import { createDisposers } from './utils/disposers'
import { breakDown } from './breakDown'
import { binarySearch, getAllRangesFromStartEnd } from './components/controls/tape/ExportRegion'

const MIN_PIXELS = 65
const EXPORT_MIN = 3 * 1000

// [time in mark, frequency of labeled marls]
const dTimeRange = [
  [1 * 100, 10], // 1 second,
  [1 * 1000, 10], // 1 second,
  [10 * 1000, 6], // 10 second
  [1 * 60 * 1000, 5], // 1 min
  [1 * 60 * 1000, 10], // 1 min
  [2 * 60 * 1000, 5], // 2 min
  [10 * 60 * 1000, 6], // 10 min
  [1 * 60 * 60 * 1000, 6], // 1 hour
  [1 * 60 * 60 * 1000, 12], // 12 hours
]

// scale is estimated in number of milliseconds in 1 pixel
export const SCALE_MIN = (1 * 100 * 10) / MIN_PIXELS
export const SCALE_MAX = (1 * 60 * 60 * 1000 * 2) / MIN_PIXELS

const Range = types
  .model({
    id: types.identifierNumber,
    start: types.number,
    end: types.number,
    streamId: types.optional(types.string, ''),
  })
  .views((self) => {
    return {
      get duration() {
        return self.end - self.start
      },
      includes(time: number, includeEnd = true) {
        if (includeEnd) {
          return self.start <= time && time <= self.end
        } else {
          return self.start <= time && time < self.end
        }
      },
    }
  })

const Ranges = types.array(Range)

export const StoreModel = types
  .model({
    archiveType: types.string,
    activeStreamId: types.string,
    currentRange: types.safeReference(Range),
    nextRange: types.safeReference(Range),
    availableRanges: Ranges,
    currentTime: types.optional(types.number, 0),
    exportStart: types.optional(types.number, 0),
    exportEnd: types.optional(types.number, 0),
    exportStreamUrl: types.optional(types.string, ''),
    width: types.number,
    height: types.number,
    scale: types.number,
    breakLimit: types.number,
    videoRef: types.frozen({}),
    isDateTape: types.boolean,
    isPlaying: types.optional(types.boolean, false),
    isWaiting: types.optional(types.boolean, true),
    fullScreen: types.optional(types.boolean, false),
    isExportPlaying: types.optional(types.boolean, false),
    volume: types.optional(types.number, 0.5),
    isMuted: types.optional(types.boolean, true),
    speed: types.optional(types.number, 1),
    selectionMode: types.optional(types.boolean, false),
    selectionLimit: types.optional(types.number, 0),
    naturalHeight: types.optional(types.number, 0),
    naturalWidth: types.optional(types.number, 0),
    isExportable: types.optional(types.boolean, false),
    isHideScreen: types.optional(types.boolean, false),
    isEnableLockingFeature: types.optional(types.boolean, true),
  })
  .views((self) => {
    return {
      get gluedAvailableRanges(): Array<{ start: number; end: number; streamId: string }> {
        const newList: Array<{ start: number; end: number; streamId: string }> = []
        self.availableRanges.forEach(({ start, end, streamId }, index, arr) => {
          newList.push({ start, end, streamId })
        })
        return newList
      },
      closestTime(time: number, next?: 'prev' | 'next') {
        if (!self.availableRanges.length) return 0
        if (self.availableRanges.some((r) => r.includes(time, false))) {
          return time
        } else {
          const before = self.availableRanges.findIndex((r) => time < r.start)
          if (before === -1) {
            return self.availableRanges[self.availableRanges.length - 1].end
          } else if (before === 0) {
            return self.availableRanges[0].start
          } else {
            const b = self.availableRanges[before]
            const a = self.availableRanges[before - 1]
            return b.start - time < time - a.end ? b.start : a.end
          }
        }
      },
      findRangeIndex(time: number) {
        const index = binarySearch(self.availableRanges, time)
        if (index === -1) return self.availableRanges.findIndex((r) => r.includes(time, true))
        return index
      },
    }
  })
  .views((self) => {
    return {
      get tapeOffset() {
        return (
          Math.floor(self.currentTime / 1000 / 60 / 60 / 24) * 24 * 3600 * 1000 +
          new Date().getTimezoneOffset() * 60 * 1000
        )
      },
      get globalStart() {
        return self.availableRanges[0]?.start || -1
      },
      get globalEnd() {
        const ranges = self.availableRanges
        return ranges[ranges.length - 1]?.end || -1
      },
      get dTime() {
        return (
          dTimeRange.find(([d, b]) => {
            return (d * b) / self.scale > MIN_PIXELS
          }) || dTimeRange[0]
        )
      },
      get exportRanges() {
        if (self.exportStart === 0 && self.exportEnd === 0) {
          return []
        }
        let startIndex = self.availableRanges.findIndex((r) => self.exportStart <= r.end)
        if (startIndex === -1) {
          return []
        }
        let endIndex = self.availableRanges.findIndex((r) => self.exportEnd <= r.end)
        if (endIndex === -1) {
          return []
        } else if (self.availableRanges[endIndex].includes(self.exportEnd)) {
          endIndex = endIndex + 1
        }
        const ranges = self.availableRanges
          .slice(startIndex, endIndex)
          .map(({ start, end }) => ({ start, end }))
        if (ranges.length > 0) {
          const first = ranges[0]
          const last = ranges[ranges.length - 1]
          if (first.start < self.exportStart) {
            first.start = self.exportStart
          }
          if (self.exportEnd < last.end) {
            last.end = self.exportEnd
          }
        }
        return ranges
      },
    }
  })
  .views((self) => {
    let window: IRange | null = null
    return {
      get selectionActive() {
        return self.selectionMode && self.exportStart === 0 && self.exportEnd === 0
      },
      get exportRangesStart() {
        const { exportRanges } = self
        if (exportRanges.length > 0) {
          return exportRanges[0].start
        } else {
          return undefined
        }
      },
      get exportRangesEnd() {
        const { exportRanges } = self
        if (exportRanges.length > 0) {
          return exportRanges[exportRanges.length - 1].end
        } else {
          return undefined
        }
      },
      get visibleTimeRange(): [number, number] {
        const halfInterval = (self.width * self.scale) / 2
        return [
          Math.floor(Math.max(self.globalStart, self.currentTime - halfInterval)),
          Math.ceil(Math.min(self.globalEnd, self.currentTime + halfInterval)),
        ]
      },
      get totalVisibleRange(): [number, number] {
        const halfInterval = (self.width * self.scale) / 2
        return [
          Math.floor(self.currentTime - halfInterval),
          Math.ceil(self.currentTime + halfInterval),
        ]
      },
      get prefetchWindow(): [number, number] {
        const halfInterval = (self.width * self.scale) / 2
        const visibleWindow = [
          Math.floor(Math.max(self.currentTime - halfInterval)),
          Math.ceil(Math.min(self.currentTime + halfInterval)),
        ]
        if (
          window === null ||
          !window.includes(visibleWindow[0]) ||
          !window.includes(visibleWindow[1])
        ) {
          window = Range.create({
            id: visibleWindow[0],
            start: visibleWindow[0] - 2 * halfInterval,
            end: visibleWindow[1] + 2 * halfInterval,
          })
        }
        return [window.start, window.end]
      },
    }
  })
  .actions((self) => {
    const updateCurrentTime = (newCurrentTime: number) => {
      self.currentTime = self.closestTime(newCurrentTime)
      const currentRangeIndex = self.findRangeIndex(self.currentTime)
      self.currentRange = self.availableRanges[currentRangeIndex]
      if (currentRangeIndex + 1 < self.availableRanges.length) {
        self.nextRange = self.availableRanges[currentRangeIndex + 1]
      } else {
        self.nextRange = undefined
      }
    }
    return {
      setSelectionLimit(value: number) {
        self.selectionLimit = value
        if (self.selectionLimit > 0 && self.exportEnd - self.exportStart > self.selectionLimit) {
          self.exportEnd = self.exportStart + self.selectionLimit
        }
      },
      setBreakLimit(limit: number) {
        self.breakLimit = limit
      },
      /**
       * @param limit - in seconds
       * @param ranges - in seconds
       */
      setLiveInterval(
        ranges: Array<{ start: number; duration: number; streamId?: string }>,
      ) {
        self.availableRanges = Ranges.create(
          breakDown(self.breakLimit, ranges).map(({ start, duration, streamId }) => ({
            id: start,
            start: start * 1000,
            end: (start + duration) * 1000,
            streamId: streamId || '',
          })),
        )
      },
      /**
       * @param limit - in seconds
       * @param duration - in seconds
       */
      grow(limit: number, duration: number) {
        limit = limit * 1000
        duration = duration * 1000
        const { availableRanges } = self
        if (availableRanges.length > 0) {
          const last = availableRanges[availableRanges.length - 1]
          last.end = last.start + duration
          if (last.duration > limit) {
            const [newLast, ...rest] = breakDown(limit, [last])
            last.end = newLast.start + newLast.duration
            rest.forEach(({ start, duration, streamId }) => {
              self.availableRanges.push(
                Range.create({ id: start, start, end: start + duration, streamId }),
              )
            })
          }
        }
      },
      setCurrentTime(newCurrentTime: number, source?: 'player' | undefined) {
        if (self.currentRange && self.currentRange.includes(newCurrentTime)) {
          self.currentTime = newCurrentTime
        } else {
          updateCurrentTime(newCurrentTime)
        }
      },
      startNextRange() {
        const { nextRange } = self
        if (nextRange) {
          updateCurrentTime(nextRange.start)
          self.isPlaying = true
        }
      },
      setWidth(value: number) {
        if (value > 0) {
          self.width = value
        }
      },
      setHeight(value: number) {
        if (value > 0) {
          self.height = value
        }
      },
      setScale(newScale: number) {
        if (newScale < SCALE_MIN) {
          self.scale = SCALE_MIN
        } else if (newScale > SCALE_MAX) {
          self.scale = SCALE_MAX
        } else {
          self.scale = newScale
        }
      },
      createExportRegion(start: number) {
        let width = self.exportEnd - self.exportStart
        if (width <= 0) {
          const [d, n] = self.dTime
          width = d * n
        }

        if (self.archiveType === 'Stream' && self.isEnableLockingFeature) {

          if (start + width >= self.globalEnd ) {
            width = self.globalEnd - start
          }

          if (start <= self.globalStart ) {
            start = self.globalStart
          }

          const startIndex = binarySearch(self.availableRanges, start)
          const endIndex = binarySearch(self.availableRanges, start + width)
          const ranges: IRange[] = getAllRangesFromStartEnd(
            startIndex,
            endIndex,
            start,
            width,
            self as IStore
          )
          const blockRangeIndex = ranges.findIndex((r) => r.streamId !== self.activeStreamId)
          if (blockRangeIndex !== -1) {
            const isInStart = ranges[blockRangeIndex].includes(start)
            if (isInStart) {
              width = ranges[blockRangeIndex].end - start
            } else {
              const findLastRangeSequentialWithoutBlockingIndex = () => {
                let result = -1
                for (let index = 0; index < ranges.length; index++) {
                  const { streamId } = ranges[index];
                  if (streamId !== self.activeStreamId) break
                  result = index
                }
                return result
              }
              const nonBlocRangeIndex = findLastRangeSequentialWithoutBlockingIndex()
              if (nonBlocRangeIndex !== -1) {
                const range = ranges[nonBlocRangeIndex]
                width = range.end - start          
              }
            }
          }
        }

        if (width > self.selectionLimit) {
          width = self.selectionLimit
        }

        if (start + width >= self.globalEnd) {
          self.exportEnd = self.globalEnd
          start = self.exportEnd - width
        } else {
          self.exportEnd = start + width
        }
        if (start <= self.globalStart) {
          self.exportStart = self.globalStart
        } else {
          self.exportStart = start
        }
      },
      moveExportRegion(newStart: number) {
        if (newStart < self.globalStart) {
          newStart = self.globalStart
        }
        const width = self.exportEnd - self.exportStart
        let newEnd = newStart + width
        if (newEnd > self.globalEnd) {
          newEnd = self.globalEnd
          newStart = newEnd - width
        }
        self.exportStart = newStart
        self.exportEnd = newEnd
      },
      setExportStart(newStart: number) {
        if (newStart === 0) {
        } else if (newStart < self.globalStart) {
          self.exportStart = self.globalStart
        } else if (newStart > self.globalEnd) {
          self.exportStart = self.globalEnd - EXPORT_MIN
        } else if (newStart > self.exportEnd) {
          self.exportStart = self.exportEnd - EXPORT_MIN
        } else {
          self.exportStart = newStart
        }
        if (self.selectionLimit > 0 && self.exportEnd - self.exportStart > self.selectionLimit) {
          self.exportStart = self.exportEnd - self.selectionLimit
        }
      },
      setExportEnd(newEnd: number) {
        if (newEnd === 0) {
        } else if (newEnd < self.globalStart) {
          self.exportEnd = self.globalStart + EXPORT_MIN
        } else if (newEnd > self.globalEnd) {
          self.exportEnd = self.globalEnd
        } else if (newEnd < self.exportStart) {
          self.exportEnd = self.exportStart + EXPORT_MIN
        } else {
          self.exportEnd = newEnd
        }
        if (self.selectionLimit > 0 && self.exportEnd - self.exportStart > self.selectionLimit) {
          self.exportEnd = self.exportStart + self.selectionLimit
        }
      },
      setVideoRef(newRef: any) {
        self.videoRef = newRef
      },
      setExportStreamUrl(newExportStreamUrl: string) {
        self.exportStreamUrl = newExportStreamUrl
      },
      play() {
        self.isPlaying = true
      },
      pause() {
        self.isPlaying = false
      },
      setIsWaiting(value: boolean) {
        self.isWaiting = value
      },
      playExport() {
        const { exportRanges } = self
        if (exportRanges.length > 0) {
          self.isExportPlaying = true
        }
      },
      stopPlayingExport() {
        self.isExportPlaying = false
      },
      enterFullScreen(source: string) {
        self.fullScreen = true
      },
      exitFullScreen(source: string) {
        self.fullScreen = false
      },
      setVolume(value: number, source?: 'player' | undefined) {
        if (value >= 0 && value <= 1) {
          self.volume = value
        }
      },
      setIsMuted(value: boolean, source?: 'player' | undefined) {
        self.isMuted = value
      },
      setSpeed(value: number) {
        if (value >= 0.25 && value <= 6) {
          self.speed = value
        }
      },
      setSelectionMode(value: boolean) {
        self.selectionMode = value
        if (!value) {
          self.exportEnd = 0
          self.exportStart = 0
        }
      },
      setNaturalSize(width: number, height: number) {
        self.naturalWidth = Math.max(0, width)
        self.naturalHeight = Math.max(0, height)
      },
      setIsExportable(value: boolean) {
        self.isExportable = value
      },
      setIsDateTape(value: boolean) {
        self.isDateTape = value
      },
      setIsEnableLockingFeature(value: boolean) {
        self.isEnableLockingFeature = value
      },
      setIsHideScreen(value: boolean) {
        self.isHideScreen = value
      },
    }
  })
  .actions((self) => {
    const disposers = createDisposers()
    return {
      afterCreate() {
        disposers.add(
          reaction(
            () => [self.exportStart, self.exportEnd],
            ([start, end]) => {
              if (start !== 0 && end !== 0) {
                self.selectionMode = true
              }
            },
          ),
        )
      },
      beforeDestroy() {
        disposers.flush()
      },
    }
  })

export type IStore = Instance<typeof StoreModel>
export type IRange = Instance<typeof Range>

const ensureLimit = (start: number, end: number, time: number) => {
  if (time < start) {
    return start
  } else if (end <= time) {
    return end - 1
  } else {
    return time
  }
}
