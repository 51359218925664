/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {makeButton} from './makeButton';

// language=SCSS
const s = {
  circle: css`
    & {
      stroke: var(--primary-color) !important;
      fill: var(--primary-color) !important;
      fill-opacity: calc(0.3 * var(--glow-power));
    }
  `,
  pause: css`
    & {
      fill: var(--text-color-active-secondary) !important;
    }
  `,
  shadow: css`
    & {
      border-radius: 50%;
      box-shadow: 0 0 calc(21rem / var(--bfs)) calc(7rem / var(--bfs))
        rgba(var(--primary-color-raw), calc(0.5 * var(--glow-power)));
    }
  `
};

export const Pause = makeButton((props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      css={s.shadow}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 1C7.72 1 1 7.72 1 16C1 24.28 7.72 31 16 31C24.28 31 31 24.28 31 16C31 7.72 24.28 1 16 1Z"
        fill="#3C72FF"
        fillOpacity="0.3"
        stroke="#3C72FF"
        strokeWidth="2"
        css={s.circle}
      />
      <rect x="11" y="10" width="4" height="12" fill="white" css={s.pause} />
      <rect x="17" y="10" width="4" height="12" fill="white" css={s.pause} />
    </svg>
  );
});
