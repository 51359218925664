import { isStream } from "../../utils/basicValidators";

function createOverlay() {
  const el = document.createElement('div');
  el.style.position = 'absolute';
  el.style.top = '0';
  el.style.left = '0';
  el.style.width = '100%';
  el.style.height = '100%';
  return el;
}

function removeDbClick(plyr: any) {
  const l = plyr.eventListeners.find(
    (_l: {element: HTMLElement; callback: () => void; options: {}; type: string}) => _l.type === 'dblclick'
  );
  if (l) {
    l.element.removeEventListener(l.type, l.callback, l.options);
  }
}

function getAvailableScaleByWindowSize(dvrTimelines: Ranges, windowSize: number) {
  const delta = 10 + 9 + 2; //startSpace+ 3sigmaSpace + markWidth
  const defaultMarkSizeBySec = 25;
  const scaleByDuration = dvrTimelines.reduce((acc, next) => (acc += next.duration), 0);
  const availableScaleByWindow = windowSize / 2 / defaultMarkSizeBySec;
  const scaleDiff = scaleByDuration - availableScaleByWindow;

  return scaleDiff > 0 ? scaleByDuration + Math.abs(scaleDiff) + delta : scaleByDuration + delta;
}

const INTEGRA_BREAK_LIMIT_SECONDS = 160 
const isIntegraStream = (archiveEntry: IArchiveEntry) => isStream(archiveEntry.entity) && archiveEntry.entity.externalSystemName === 'Integra'

export {createOverlay, removeDbClick, getAvailableScaleByWindowSize, isIntegraStream, INTEGRA_BREAK_LIMIT_SECONDS};
