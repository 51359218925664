import {IEventModel, IStatus} from '../../models';
import {IAdapterFactory} from '../IEventAdapter';
import {ITboEventType} from './ITboEventType';
import {loadEntitiesAsMap} from '../common/loadEntitiesAsMap';
import {ITboEvent} from './ITboEvent';
import {isExact, isShape, isString} from '../../../../utils/basicValidators';
import {parseDate} from '../common/parseDate';

export const createTboAdapter: IAdapterFactory = (statusLocale) => {
  let eventTypesPromise: null | Promise<Map<string, ITboEventType>> = null;
  const settings = {
    useGroups: false
  };
  return {
    settings,
    convert: async (input, signal) => {
      if (!isTboEvent(input)) {
        return null;
      }
      const timestamp = parseDate(input.timestamp);
      if (!timestamp) {
        return null;
      }
      if (!eventTypesPromise) {
        eventTypesPromise = loadEntitiesAsMap<ITboEventType>(
          'TboEventType',
          (en) => en.name,
          (en) => {
            if (en.icon?.color && en.icon.color.startsWith('--')) {
              en.icon.color = `var(${en.icon.color})`;
            }
            return en;
          }
        );
      }
      const eventTypes = await eventTypesPromise;
      if (signal.aborted) {
        return null;
      }
      const eventType = eventTypes.get(input.tboEventType);
      let eventTypeValue: IEventModel['eventType'];
      if (eventType) {
        eventTypeValue = {
          name: eventType.name,
          title: eventType.title,
          color: eventType.icon?.color,
          icon: eventType.icon?.iconClass
        };
      } else {
        eventTypeValue = {
          name: input.tboEventType,
          title: input.tboEventType
        };
      }
      const statusTitle = statusLocale?.[input.status] ?? input.status ?? '';
      const {color, icon} = statusMap[input.status] ?? {};
      const output: IEventModel = {
        id: input.id,
        timestamp: timestamp,
        expires: parseDate(input.dateExpires) ?? undefined,
        eventType: eventTypeValue,
        status: {
          title: statusTitle,
          color,
          icon
        },
        group: {
          id: input.cameraId ?? '',
          title: ''
        },
        bbox: input.bbox,
        originalEvent: input
      };
      return output;
    },
    destroy: () => {}
  };
};

const hasTboType = isShape({id: isString, type: isExact('TboEvent')});
const isTboEvent = hasTboType as (v: unknown) => v is ITboEvent & {[k: string]: unknown};

const statusMap: Partial<Record<string, Omit<IStatus, 'title'>>> = {
  ['NotHandled']: {
    icon: 'mdi-alert',
    color: 'var(--warning-color)'
  },
  ['Handled']: {
    icon: 'mdi-alert',
    color: 'var(--success-color)'
  },
  ['ViolationDetected']: {
    icon: 'mdi-alert',
    color: 'var(--error-color)'
  }
};
