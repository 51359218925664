/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {FC, forwardRef} from 'react';

export type IPlayerButtonProps = JSX.IntrinsicElements['button'] & {
  'data-active'?: boolean;
  tooltip?: string;
  tooltipActive?: boolean;
  tooltipAlign?: 'center' | 'left' | 'right';
};

export const makeButton = (component: FC<JSX.IntrinsicElements['svg']>) => {
  return forwardRef<HTMLButtonElement, IPlayerButtonProps>(
    ({children, 'data-active': active = false, tooltip, tooltipActive, tooltipAlign = 'center', ...rest}, ref) => {
      return (
        <button {...rest} data-active={active} ref={ref} css={buttonCss}>
          {jsx(component)}
          {tooltip && (
            <div data-role={'tooltip'} data-active={!!tooltipActive} data-align={tooltipAlign}>
              {tooltip}
            </div>
          )}
          {children}
        </button>
      );
    }
  ) as FC<IPlayerButtonProps>;
};

// language=SCSS
const buttonCss = css`
  & {
    position: relative;
    margin: 0;
    padding: 0;
    border: none;
    background: transparent;
    height: calc(32rem / var(--bfs));
    width: calc(32rem / var(--bfs));
    border-radius: var(--border-radius-small);
    text-align: left;

    > svg {
      display: block;
      height: 100%;

      path {
        fill: var(--text-color-secondary);
        transition: fill 200ms ease;
      }
    }

    &[data-active='true'] {
      > svg path {
        fill: var(--text-color-active-secondary) !important;
      }
    }

    :hover,
    .hover {
      cursor: pointer;

      > svg path {
        fill: var(--text-color-active-secondary);
      }
    }

    > svg,
    *[data-dimmable] {
      transition: filter 200ms ease;
    }
    :active {
      > svg,
      *[data-dimmable] {
        filter: brightness(0.7);
      }
    }

    :focus {
      outline: none;
      //box-shadow: 0 0 0 calc(2rem / var(--bfs)) rgba(var(--text-color-raw), 0.2);
    }

    > div[data-role='tooltip'] {
      opacity: 0;
      pointer-events: none;
      transition: opacity 200ms ease;

      box-shadow: var(--shadow-raised);

      position: absolute;
      top: 0;
      z-index: 2;

      width: auto;
      white-space: nowrap;

      font-size: calc(13rem / var(--bfs));
      font-weight: 500;

      color: var(--text-color);
      background: var(--surface-b);

      padding: calc(10rem / var(--bfs)) calc(15rem / var(--bfs));
      border-radius: var(--border-radius);

      &[data-active='true'] {
        color: var(--text-color-active);
        background: var(--primary-color);
      }
      &[data-align='center'] {
        text-align: center;
        left: 50%;
        transform: translate(-50%, calc(-100% - 5rem / var(--bfs)));
      }
      &[data-align='right'] {
        text-align: right;
        right: 0;
        transform: translate(0, calc(-100% - 5rem / var(--bfs)));
      }
      &[data-align='left'] {
        text-align: left;
        left: 0;
        transform: translate(0, calc(-100% - 5rem / var(--bfs)));
      }
    }

    :hover > div[data-role='tooltip'] {
      opacity: 1;
    }
  }
`;
