/** @jsx jsx */
import { jsx } from '@emotion/react'

import { useCallback, useState, useLayoutEffect, useEffect } from 'react'
import { Button } from 'primereact/button'

import { useWidgetProps } from '../../Root'

import { StatusInfo } from '../../components/StatusInfo'
import { PreviewOnly } from '../../components/PreviewOnly'
import { Player } from '../../components/Player/Player'

import { useApiRepository, useLocale, useTooEarly } from '../../hooks'
import { getRangeIndex } from '../../utils/getRangeIndex'

import { buttonCss } from './style'
import { entityHookFabric, getArchiveEntity } from './utils'
import { AccessTokenAdapter } from '../../utils/access-token-adapter'
import { LibApiRepository } from '@netvision/lib-api-repo/dist/src/repositories/LibApiRepository'

export const Selector = () => {
  const locale = useLocale()
  // true, when there is a possibility that initialTime is not yet in the archive's availableRange, but will be there soon
  const tooEarly = useTooEarly()

  return tooEarly ? <StatusInfo loading={locale.tooEarly} /> : <Main />
}

const Main = () => {
  const locale = useLocale()
  const {
    props: { cameraId, camera, initialTime, entity, skipPreview = false },
  } = useWidgetProps()

  const archiveEntity = getArchiveEntity(cameraId || camera?.id, entity)
  const entityHook = entityHookFabric(archiveEntity)
  const { api } = useApiRepository()

  // @ts-ignore
  const [archiveError, loading, archiveEntry] = entityHook(archiveEntity)

  const [rangeExists, setRangeExists] = useState(false)
  const [show, setShow] = useState(skipPreview)

  const fetchRangeIndex = useCallback(async (archiveEntry: IArchiveEntry, initialTime = 0) => {
    return await getRangeIndex(archiveEntry, initialTime / 1000)
  }, [])

  useEffect(() => {
    AccessTokenAdapter
      .tokenFetcher(api as LibApiRepository)
  }, [])

  useLayoutEffect(() => {
    archiveEntry &&
      fetchRangeIndex(archiveEntry, initialTime)
        .then((result) => setRangeExists(result === -1))
        .catch(console.error)
  }, [archiveEntry, fetchRangeIndex, initialTime])

  const showPlayerButton = (
    <Button
      css={buttonCss}
      className={'p-button-primary'}
      label={locale.archiveLabel}
      iconPos={'left'}
      icon={'pi pi-play'}
      onClick={() => setShow(true)}
    />
  )

  if (archiveError) return <StatusInfo error={archiveError} />

  if (initialTime) {
    if (!show) {
      const timeIsMissing = initialTime && archiveEntry && rangeExists
      if (timeIsMissing) {
        return (
          <StatusInfo loading={loading} error={timeIsMissing ? locale.missingTime : undefined}>
            {/* hideButton */}
            {/* {!loading && showPlayerButton} */}
          </StatusInfo>
        )
      } else {
        return <PreviewOnly initialTime={initialTime}>{!loading && showPlayerButton}</PreviewOnly>
      }
    }
  }

  if (loading) return <StatusInfo loading={loading ? locale.loadingArchive : false} />

  return archiveEntry && <Player archiveEntry={archiveEntry} />
}
