/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { useEffect, useMemo, useRef } from 'react';
import { Button } from 'primereact/button';

type IncrementalInputProps = {
  value: number
  isFocused: boolean
  digits?: number
  range?: [number, number]
  decimalStep?: number
  setValue: (value: number) => void
  apply: () => void
  switchInput: () => void
  resetFocusState: () => void
}

export const IncrementalInput = ({
  value,
  isFocused,
  digits,
  range,
  decimalStep = 1,
  setValue,
  apply,
  switchInput,
  resetFocusState
}: IncrementalInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const getValidatedValue = (val: number) => {
    if (isNaN(val)) return 0
    if (!range) return val
    const [minValue, maxValue] = range
    if (val > maxValue) return maxValue
    if (val < minValue) return minValue
    return val
  }

  const keyboardEventHandlers = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.code) {
      case 'ArrowUp':
        e.preventDefault()
        setValue(getValidatedValue(value + decimalStep))
        break
      case 'ArrowDown':
        e.preventDefault()
        setValue(getValidatedValue(value - decimalStep))
        break
      case 'Tab':
        e.preventDefault()
        switchInput()
        break
      case 'Enter':
        e.preventDefault()
        apply()
    }
  }

  const digitsLength = (val: number) => {
    return String(val).length || 1
  }

  const inputPrefix = useMemo(() => {
    const valueLength = digitsLength(value)
    return !digits
      ? '' : digits <= valueLength
      ? '' : '0'.repeat(digits - valueLength)
  }, [value, digits])

  const maxLength = useMemo(() => (
    !range || inputPrefix ? Infinity : digitsLength(range[1])
  ), [range, inputPrefix])

  const selectByFocus = () => {
    inputRef.current?.select();
  }

  useEffect(() => {
    isFocused && inputRef.current?.focus()
  }, [isFocused])

  return (
    <div css={incrementalInputCSS}>
      <Button
        type="button"
        icon="pi pi-chevron-up"
        className="p-button-sm p-button-text"
        disabled={range ? value === range[1] : false}
        onClick={() => setValue(value + decimalStep)}
      />
      <input
        size={1}
        type="text"
        ref={inputRef}
        step={decimalStep}
        inputMode="numeric"
        maxLength={maxLength}
        onFocus={selectByFocus}
        onClick={selectByFocus}
        onBlur={resetFocusState}
        value={inputPrefix + value}
        onKeyDown={keyboardEventHandlers}
        className="p-inputtext p-component p-inputnumber-input"
        onInput={(e) => setValue(getValidatedValue(Number(e.currentTarget.value)))}
      />
      <Button
        type="button"
        icon="pi pi-chevron-down"
        className="p-button-sm p-button-text"
        disabled={range ? value === range[0] : false}
        onClick={() => setValue(value - decimalStep)}
      />
    </div>
  )
}

const incrementalInputCSS = css`
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  .p-inputnumber-input {
    text-align: center;
    background-color: transparent;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`
