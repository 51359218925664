type IRange = { start: number; duration: number, streamId?: string }
type ITimeline = IRange[]

export const isTimeline = (v: IRange[]): v is ITimeline =>
  v.every((current, index, arr) => {
    if (index === 0) {
      return true
    } else {
      const prev = arr[index - 1]
      return prev.start + prev.duration <= current.start
    }
  })

export const breakDown = (limit: number, t: ITimeline): ITimeline => {
  if (limit <= 0) {
    return []
  } else {
    return t.reduce((acc, v) => {
      if (v.duration < limit) {
        acc.push(v)
      } else {
        acc.push(...divideRange(limit, v))
      }
      return acc
    }, [] as ITimeline)
  }
}

const divideRange = (limit: number, r: IRange,): ITimeline => {
  const { start, streamId } = r

  const baseNumber = Math.ceil(start / limit) * limit
  const baseDiff = baseNumber - start
  const n = Math.floor(r.duration / limit)

  const arr: ITimeline = []

  if (baseDiff > 0) {
    arr.push({ start: start, duration: baseDiff, streamId })
  }

  for (let i = 0; i < n - 1; i++) {
    arr.push({ start: baseNumber + i * limit, duration: limit, streamId })
  }

  const d = r.duration - (n - 1) * limit - baseDiff
  if (d > limit) {
    arr.push({ start: baseNumber + (n - 1) * limit, duration: limit, streamId })
    arr.push({ start: baseNumber + n * limit, duration: d - limit, streamId })
  } else {
    arr.push({ start: baseNumber + (n - 1) * limit, duration: d, streamId })
  }

  return arr
}
