import { useCallback, useEffect, useState } from 'react'
import { useApiRepository } from './useApiRepository'
import { useLocale } from './useLocale'

export const useUploadedFile = (entity: UploadedFile): [string, boolean, IArchiveEntry | null] => {
  const [archiveEntry, setArchiveEntry] = useState<IArchiveEntry | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const locale = useLocale()
  const { api } = useApiRepository()

  const setArchiveEntity = useCallback((file: UploadedFile): IArchiveEntry => {
    return {
      entity: file,
      getDvrTimelines: () => new Promise(() => [{ start: 0, duration: file.duration || 1 }]),
    }
  }, [])

  useEffect(() => {
    let mounted = true
    setLoading(true)

    const updateArchiveEntry = (file: UploadedFile) => {
      if (!mounted) return

      if (!file) {
        setError(locale.archiveIsUnavailable)
        setArchiveEntry(null)
        return
      }

      setArchiveEntry(setArchiveEntity(file))
    }

    if (!('getEntitiesWithGlobalBatch' in api)) {
      setError(locale.archiveIsUnavailable)
      setArchiveEntry(null)
      throw new Error('api-repo method "getEntitiesWithGlobalBatch" in not defined')
    }

    api
      .getEntitiesWithGlobalBatch(
        { id: entity.id, type: entity.type },
        ['streamUrl'],
        'player-archive',
      )
      .then((res) => updateArchiveEntry({ ...entity, ...res }))
      .finally(() => mounted && setLoading(false))

    return () => {
      mounted = false
    }
  }, [api, entity, locale, setArchiveEntity])

  return [error, loading, archiveEntry]
}
