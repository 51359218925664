/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { useStore } from '../../../hooks/useStore'
import { FC, useEffect, useRef } from 'react'
import { SCALE_MAX, SCALE_MIN } from '../../../StoreModel'
import { observer } from 'mobx-react-lite'
import { VirtualTape } from './VirtualTape'
import { AvailableRanges } from './AvailableRanges'
import { ExportRegion } from './ExportRegion'
import { TapeMoveArea } from './TapeMoveArea'
import { secondsToHms } from '../utils'
import { E2EModule } from '../../../__test__'

export const Tape: FC = observer(function Tape({ children }) {
  const store = useStore()
  const ref = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const node = ref.current
    if (node === null) {
      return undefined
    }
    const onWheel = (e: HTMLElementEventMap['wheel']) => {
      e.preventDefault()
      if (e.shiftKey) {
        // move tape
        const timeShift = store.dTime[0]
        if (e.deltaY > 0) {
          store.setCurrentTime(store.currentTime + timeShift)
        } else {
          store.setCurrentTime(store.currentTime - timeShift)
        }
      } else {
        // zoom
        const ratio = 1.3
        const oldScale = store.scale
        const newScale = e.deltaY > 0 ? oldScale * ratio : oldScale / ratio
        if (newScale <= SCALE_MIN || SCALE_MAX <= newScale) {
          return
        }

        if (e.ctrlKey && e.currentTarget instanceof HTMLElement) {
          // for better UX, assign corresponding time of pointer's position to currentTime with offset
          // in other words use current cursor position as zoom center
          const rect = e.currentTarget.getBoundingClientRect()
          const currentLength = e.clientX - (rect.left + rect.width / 2)
          const timeInterval = currentLength * oldScale
          const newLength = timeInterval / newScale
          const xOffset = newLength - currentLength

          store.setCurrentTime(store.currentTime + xOffset * newScale)
        }
        // update scale
        store.setScale(newScale)
      }
    }
    node.addEventListener('wheel', onWheel, { passive: false })
    return () => {
      // @ts-ignore
      node.removeEventListener('wheel', onWheel, { passive: false })
    }
  }, [store])
  return (
    <div
      ref={ref}
      css={tapeStyle}
      style={{ width: store.width }}
      data-fullscreen={store.fullScreen}>
      <VirtualTape>
        <AvailableRanges />
        {children}
        {store.isDateTape && <ExportRegion />}
      </VirtualTape>
      <TapeMoveArea />
      <CurrentTime />
    </div>
  )
})

// language=SCSS
const tapeStyle = css`
  & {
    height: calc(90rem / var(--bfs));
    background: var(--player-timeline-gradient);
    position: relative;
    overflow: hidden;
  }

  &[data-fullscreen='true'] {
    height: calc(70rem / var(--bfs));
  }
`

const formatTime = (ms: number) => {
  return new Date(ms).toLocaleTimeString('ru-ru', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  })
}
const CurrentTime: FC = observer(() => {
  const store = useStore()
  return (
    <div css={currentTimeMarkerStyle} data-cy={E2EModule.attributes.currentTime}>
      <div>
        {store.isDateTape ? formatTime(store.currentTime) : secondsToHms(store.currentTime / 1000)}
      </div>
    </div>
  )
})

// language=SCSS
const currentTimeMarkerStyle = css`
  & {
    position: absolute;
    width: calc(2rem / var(--bfs));
    border-radius: calc(1rem / var(--bfs));
    height: calc(100% - 20rem / var(--bfs));
    background: var(--text-color);
    left: 50%;
    bottom: 0;
    z-index: 1;

    > div {
      color: var(--text-color);
      font-size: calc(12rem / var(--bfs));
      font-weight: 500;
      position: absolute;
      top: 50%;
      left: calc(10rem / var(--bfs));
    }
  }
`
