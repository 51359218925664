import {useCanvasMountParams} from './useCanvasMountParams';
import {useStore} from '../../../hooks/useStore';
import {useEffect, useState} from 'react';
import {createDisposers} from '../../../utils/disposers';
import {reaction} from 'mobx';
import {isTrackable} from './models';
import {IWidgetProps, RenderModelNode} from '../../../IWidgetProps';
import {getTracksRenderer} from './renderer';
import {IEventModel} from '../models';

export const useCanvas = (event: IEventModel | undefined) => {
  const canvasMountParams = useCanvasMountParams();
  const store = useStore();
  const [naturalHeight, setHeight] = useState(0);
  const [naturalWidth, setWidth] = useState(0);
  useEffect(() => {
    return reaction(
      () => [store.naturalWidth, store.naturalHeight],
      ([width, height]) => {
        setWidth(width);
        setHeight(height);
      }
    );
  }, [store]);
  useEffect(() => {
    const {mountChildren, areas, canvasStyles, overlayContainer, overlayBase} = canvasMountParams();
    const {canvas} = getAreasChildren(areas);
    if (event && canvas && naturalHeight > 0 && naturalWidth > 0) {
      const initState = {
        itemStore: {
          polygonStore: {
            items: [],
            editableLimit: 0
          },
          lineStore: {
            items: [],
            editableLimit: 0
          }
        }
      };
      const d = createDisposers();

      d.add(
        mountChildren(overlayContainer, [
          {
            ...canvas,
            props: {
              overlay: {
                overlayBase,
                naturalHeight,
                naturalWidth
              },
              initState
            },
            onMount: (update) => {
              if (isTrackable(event)) {
                const renderer = getTracksRenderer(event, canvasStyles || {});
                const draw = (time: number) => {
                  update((oldProps) => {
                    return {
                      ...oldProps,
                      currentSnapshot: renderer.getScene(time)
                    };
                  });
                };
                let cancel = animate(store.currentTime, !store.isPlaying, draw);
                d.add(
                  reaction(
                    () => [store.currentTime, store.isPlaying] as const,
                    ([time, playing]) => {
                      cancel();
                      // TODO set to playing
                      cancel = animate(time, true, draw);
                    }
                  )
                );
              }
            }
          }
        ])
      );
      return d.flush;
    }
    return undefined;
  }, [store, canvasMountParams, event, naturalWidth, naturalHeight]);
};

const animate = (offset: number, once: boolean, draw: (time: number) => void) => {
  let start = -1;
  let canceled = false;
  const f = () => {
    requestAnimationFrame((progress) => {
      if (canceled) {
        return;
      }
      if (start === -1) {
        start = progress;
      }
      const time = offset + (progress - start);
      draw(time);
      if (!once) {
        f();
      }
    });
  };
  f();
  return () => {
    canceled = true;
  };
};

function getAreasChildren(areas: IWidgetProps['areas']): {
  canvas: RenderModelNode | null;
} {
  let canvas = null;
  if (Array.isArray(areas)) {
    areas.forEach((area) => {
      if (area.name === 'canvas' && area.children && area.children.length > 0) {
        canvas = area.children[0];
      }
    });
  }
  return {
    canvas
  };
}
