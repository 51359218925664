/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {SCALE_MAX, SCALE_MIN} from '../../StoreModel';
import {FC, useCallback, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useStore} from '../../hooks/useStore';
import {ZoomMinus, ZoomPlus} from '../ui/buttons/Zoom';
import {Slider, SliderProps} from 'primereact/slider';
import {useLocale} from '../../hooks/useLocale';
import {WithPopup} from '../ui/WithPopup';
import {E2EModule} from '../../__test__';

const STEP_RATIO = 1.3;

export const ScaleControl: FC<{compact?: boolean}> = observer(({compact = false}) => {
  const store = useStore();
  const {buttonLabels} = useLocale().panelLocale;
  const minPosition = 0;
  const maxPosition = 10000;
  const minValue = Math.log(SCALE_MIN);
  const maxValue = Math.log(SCALE_MAX);
  const scale = (maxValue - minValue) / (maxPosition - minPosition);
  const onChange = useCallback<Exclude<SliderProps['onChange'], undefined>>(
    (e) => {
      const position = -Number(e.value);
      if (position) {
        const value = Math.exp(minValue + scale * (position - minPosition));
        store.setScale(value);
      }
    },
    [store, minValue, scale]
  );
  const onPlus = useCallback(() => {
    store.setScale(store.scale / STEP_RATIO);
  }, [store]);
  const onMinus = useCallback(() => {
    store.setScale(store.scale * STEP_RATIO);
  }, [store]);
  const onWheel = useCallback(
    (e) => {
      if (e.deltaY < 0) {
        onPlus();
      } else {
        onMinus();
      }
    },
    [onPlus, onMinus]
  );
  const position = (Math.log(store.scale) - minValue) / scale + minPosition;
  const openState = useState(false);
  if (compact) {
    const button = <ZoomPlus tooltip={buttonLabels.zoomPlus} css={compactCss} />;
    const popup = (
      <div css={$popup}>
        <Slider onChange={onChange} value={-position} min={-maxPosition} max={-minPosition} orientation={'vertical'} />
      </div>
    );
    return <WithPopup button={button} popup={popup} state={openState} />;
  }
  return (
    <div onWheel={onWheel} css={scaleCss}>
      <ZoomMinus
        data-cy={E2EModule.attributes.zoomOutTimeLineButton}
        tooltipActive
        tooltip={buttonLabels.zoomMinus}
        onClick={onMinus}
      />
      <Slider
        id={E2EModule.attributes.zoomRangeTimeLine}
        onChange={onChange}
        value={-position}
        min={-maxPosition}
        max={-minPosition}
      />
      <ZoomPlus
        data-cy={E2EModule.attributes.zoomInTimeLineButton}
        tooltipActive
        tooltip={buttonLabels.zoomPlus}
        onClick={onPlus}
      />
    </div>
  );
});

// language=SCSS
const scaleCss = css`
  & {
    display: flex;
    align-items: center;
    width: calc(164rem / var(--bfs));

    > * {
      margin-right: var(--spacer-xs) !important;
    }

    > *:last-child {
      margin-right: 0 !important;
    }

    > div {
      flex-grow: 1;
    }
  }
`;

// language=SCSS
const compactCss = css`
  &[aria-expanded='true'] > div[data-role='tooltip'] {
    opacity: 0;
  }
`;

// language=SCSS
const $popup = css`
  & {
    position: absolute;
    padding: calc(8rem / var(--bfs));
    top: 0;
    left: 50%;
    border-radius: calc(4rem / var(--bfs));
    background: var(--surface-b);
    transform: translateY(-100%) translateX(-50%);

    box-shadow: var(--shadow-raised);
  }
`;
