/** @jsx jsx */
import { css, jsx } from '@emotion/react'

const $root = css`
  overflow: hidden;
  background: var(--surface-a);
  display: flex;
  flex-direction: column;
`

const containerPlayer = css`
  position: relative;
`

const $blockScreen = css`
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 2;
  background: var(--bg);
  color: var(--text-color);
`

const $top = css`
  & {
    display: flex;

    video {
      background-color: var(--player-video-bg);
    }
    > div {
      flex-grow: 1;
    }
    > aside {
      position: relative;
    }
  }
`

const $controls = css`
  position: relative;
  background: var(--surface-a);
  z-index: 2;
`

export { $root, $controls, $top, $blockScreen, containerPlayer }
