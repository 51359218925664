import {useCallback} from 'react';

export function range(start, stop, step) {
  if (typeof stop === 'undefined') {
    // one param defined
    stop = start;
    start = 0;
  }

  if (typeof step === 'undefined') {
    step = 1;
  }

  if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
    return [];
  }

  const result = [];
  for (let i = start; step > 0 ? i < stop : i > stop; i += step) {
    result.push(i);
  }

  return result;
}

export function useHoldAndMove(handleMove, setUp, moveEvent = 'pointermove', stopEvent = 'pointerup') {
  return useCallback(
    (e) => {
      let initialX = 0
      const {clientX: initialCoordX} = e;
      initialX = initialCoordX
      let initialData = typeof setUp === 'function' ? setUp(e) : null;
      function onMove(e) {
        const {clientX} = e
        const update = () => {
          initialX = clientX
          initialData = setUp(e)
        }
        handleMove(initialX - clientX, initialData, update);
      }

      function onRelease() {
        window.removeEventListener(moveEvent, onMove, {capture: true});
        window.removeEventListener(stopEvent, onRelease, {capture: true});
        document.body.style.userSelect = '';
      }

      window.addEventListener(moveEvent, onMove, {capture: true});
      window.addEventListener(stopEvent, onRelease, {capture: true});
      document.body.style.userSelect = 'none';
    },
    [moveEvent, stopEvent, handleMove, setUp]
  );
}

export function secondsToHms(d) {
  d = Number(d);

  let h = Math.floor(d / 3600);
  let m = Math.floor((d % 3600) / 60);
  let s = Math.floor((d % 3600) % 60);

  return ('0' + h).slice(-2) + ':' + ('0' + m).slice(-2) + ':' + ('0' + s).slice(-2);
}
