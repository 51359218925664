/** @jsx jsx */
import {jsx} from '@emotion/react';
import {makeButton} from './makeButton';

export const ZoomMinus = makeButton((props) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3117 17.0799C18.0382 14.4739 17.7535 10.9283 15.4575 8.6323C12.8364 6.01124 8.58685 6.01124 5.96579 8.6323C3.34474 11.2534 3.34474 15.5029 5.96579 18.124C8.26179 20.42 11.8074 20.7047 14.4134 18.9782L22.1017 26.6665L24 24.7682L16.3117 17.0799ZM13.5591 10.5306C15.1318 12.1033 15.1318 14.653 13.5591 16.2256C11.9865 17.7983 9.43677 17.7983 7.86413 16.2256C6.2915 14.653 6.2915 12.1033 7.86413 10.5306C9.43677 8.958 11.9865 8.958 13.5591 10.5306Z"
        fill="white"
      />
      <path d="M28 9.33317V11.9998H20V9.33317H28Z" fill="#959DA8" />
    </svg>
  );
});

export const ZoomPlus = makeButton((props) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3117 17.0799C18.0382 14.4739 17.7535 10.9283 15.4575 8.6323C12.8364 6.01124 8.58685 6.01124 5.96579 8.6323C3.34474 11.2534 3.34474 15.5029 5.96579 18.124C8.26179 20.42 11.8074 20.7047 14.4134 18.9782L22.1017 26.6665L24 24.7682L16.3117 17.0799ZM13.5591 10.5306C15.1318 12.1033 15.1318 14.653 13.5591 16.2256C11.9865 17.7983 9.43677 17.7983 7.86413 16.2256C6.2915 14.653 6.2915 12.1033 7.86413 10.5306C9.43677 8.958 11.9865 8.958 13.5591 10.5306Z"
        fill="white"
      />
      <path
        d="M22.6667 9.33317V6.6665H25.3333V9.33317H28V11.9998H25.3333V14.6665H22.6667V11.9998H20V9.33317H22.6667Z"
        fill="#959DA8"
      />
    </svg>
  );
});
