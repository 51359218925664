import {defaultsDeep} from 'lodash-es';

export type ICanvasOverrideStyles = Partial<typeof defaultStyles>;

export const mergeStyles = (styles: ICanvasOverrideStyles): typeof defaultStyles => {
  return defaultsDeep(styles, defaultStyles);
};

const defaultStyles = {
  area: {
    fillColor: 'rgba(50, 50, 130, 0.1)',
    strokeColor: 'rgba(50, 50, 130, 1)'
  },
  line: {
    strokeColor: 'rgba(50, 50, 130, 1)'
  },
  fallback: {
    fillColor: 'rgba(230, 50, 50, 0.1)',
    strokeColor: 'rgba(230, 50, 50, 1)'
  },
  plate: {
    strokeColor: 'rgba(230, 50, 50, 1)'
  },
  vehicle: {
    strokeColor: 'rgba(230, 50, 50, 1)'
  }
};
