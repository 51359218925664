/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {useStore} from '../../../hooks/useStore';
import {FC, useCallback} from 'react';
import {observer} from 'mobx-react-lite';

export const ExportRegionCreateArea: FC<{startTime: number}> = observer(function ExportRegionCreateArea({startTime}) {
  const store = useStore();
  const onClick = useCallback(
    (e) => {
      const {target, clientX} = e;
      const {left} = target.getBoundingClientRect();
      const offset = left - clientX;
      const exportStart = startTime - offset * store.scale;
      store.createExportRegion(exportStart);
    },
    [store, startTime]
  );
  return (
    <div
      css={exportRegionCreateAreaStyle}
      onClick={onClick}
      style={store.selectionActive ? undefined : {pointerEvents: 'none'}}
    />
  );
});

// language=SCSS
const exportRegionCreateAreaStyle = css`
  & {
    position: relative;
    height: 100%;
    cursor: cell;
  }
`;
