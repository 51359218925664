import {isNumber, isString} from '../../../../utils/basicValidators';

export const parseDate = (v: unknown): number | null => {
  if (isString(v) || isNumber(v)) {
    const time = new Date(v).getTime();
    if (!isNaN(time)) {
      return time;
    }
  }
  return null;
};
