/** @jsx jsx */
import {jsx} from '@emotion/react';
import {makeButton} from './makeButton';

export const Select = makeButton((props) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 11L21.4545 16L26 21L24.1818 23L17.8182 16L24.1888 9.00763L26 11ZM6 21L10.5455 16L6 11L7.81818 9L14.1818 16L7.81125 22.9924L6 21ZM16 20C16.5523 20 17 20.4477 17 21L17 25C17 25.5523 16.5523 26 16 26C15.4477 26 15 25.5523 15 25L15 21C15 20.4477 15.4477 20 16 20ZM17 7C17 6.44772 16.5523 6 16 6C15.4477 6 15 6.44772 15 7L15 11C15 11.5523 15.4477 12 16 12C16.5523 12 17 11.5523 17 11L17 7Z"
        fill="#959DA8"
      />
    </svg>
  );
});
