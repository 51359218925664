import Hls from 'hls.js'
import Plyr from 'plyr'
import { reaction } from 'mobx'
import { onAction } from 'mobx-state-tree'

import { TUnionRepo } from '@netvision/lib-api-repo'

import { createDisposers } from '../../utils/disposers'
import { getStreamParams } from '../../requests/stream'
import { debounce } from '../../utils/debounce'
import { isFile, isNumber, isStream } from '../../utils/basicValidators'
import { convertToPlayerTime, convertToStoreTime } from './utils'

import { IStore } from '../../StoreModel'
import { getStream } from '../../requests/Archive'
import { AccessTokenAdapter } from '../../utils/access-token-adapter'

type Range = { start: number; duration: number }
const blockedStreamCache = new Map()

interface HLSCreatorProps {
  video: HTMLVideoElement
  entity: IArchiveEntry['entity']
  range: Range
  store: IStore
  breakLimit: number
  hlsStreamUrl?: string
}

const createHLS = (config: HLSCreatorProps) => {
  const { breakLimit, entity, range, store, video, hlsStreamUrl } = config
  const { src } = getStreamParams(entity, range, hlsStreamUrl)

  const HLS = new Hls({
    autoStartLoad: false,
    maxBufferLength: 120,
    maxBufferSize: 100,
    xhrSetup(xhr) {
      const authToken = AccessTokenAdapter.getToken()
      if (!authToken) {
        console.warn('Can not get token via MediaTokenAdapter')
        return
      }

      xhr.setRequestHeader('Authorization', `Bearer ${authToken}`)
    },
  })

  HLS.once(Hls.Events.MEDIA_ATTACHED, () => HLS.loadSource(src))
  const isFileEntity = isFile(entity) && !entity.duration

  if (isFileEntity) {
    HLS.on(Hls.Events.BUFFER_APPENDED, (_, data) => {
      //@ts-ignore
      const duration = data.timeRanges.video?.end(0)
      if (duration) {
        store.grow(breakLimit, duration)
      }
    })
  }

  HLS.on(Hls.Events.ERROR, (_, data) => console.warn('Hls error', data))
  HLS.attachMedia(video)
  return HLS
}

export const getStreamUrl = async (streamId: string, api: TUnionRepo) => {
  let stream: IStream | undefined
  if (blockedStreamCache.has(streamId)) {
    stream = blockedStreamCache.get(streamId)
  }

  if (!stream) {
    const streamData = await getStream(api, streamId, 'hlsStreamUrl,dvrTimelines,exportStreamUrl')
    if (!streamData) throw new Error('can not get blocked stream data')

    blockedStreamCache.set(streamId, streamData)
    stream = streamData
  }

  if (!stream) console.error('cannot get blocked stream chunk')
  return stream
}

const getLockStreamUrl = async (
  store: IStore,
  defaultStreamId: string,
  hlsStreamUrl: string,
  api: TUnionRepo,
  defaultExportStreamUrl?: string,
) => {
  const currentStreamId = String(store.currentRange?.streamId)
  const isUsualStream = currentStreamId === defaultStreamId

  if (!currentStreamId) {
    store.setIsHideScreen(true)
    return
  }

  if (store.isHideScreen) store.setIsHideScreen(false)

  if (isUsualStream) {
    if (defaultExportStreamUrl) store.setExportStreamUrl(defaultExportStreamUrl)
    return hlsStreamUrl
  }

  const stream = await getStreamUrl(currentStreamId, api)
  if (stream.exportStreamUrl) store.setExportStreamUrl(stream.exportStreamUrl)
  return stream.hlsStreamUrl || ''
}

export const attachStandardStreamHandler = (params: {
  archiveEntry: IArchiveEntry
  store: IStore
  video: HTMLVideoElement
  plyr: Plyr
  breakLimit: number
  hlsStreamUrl?: string
  api: TUnionRepo
}): (() => void) => {
  const { plyr, archiveEntry, store, video, breakLimit, hlsStreamUrl, api } = params
  const entity = archiveEntry.entity
  const defaultStreamId = entity.id

  const disposers = createDisposers()
  let hlsDisposers = createDisposers()

  disposers.add(() => hlsDisposers.flush())
  disposers.add(() => blockedStreamCache.clear())

  disposers.add(
    reaction(
      () => store.currentRange,
      async (currentRange) => {
        if (!currentRange) {
          console.error(`Current range should exist`)
          return
        }

        hlsDisposers.flush()
        hlsDisposers = createDisposers()

        const hasDuration = isFile(entity) && isNumber(entity.duration)
        const streamUrl = isStream(entity) && store.isEnableLockingFeature
          ? await getLockStreamUrl(
              store,
              defaultStreamId,
              hlsStreamUrl!,
              api,
              entity?.exportStreamUrl,
            )
          : hlsStreamUrl

        if (!streamUrl) {
          console.warn('Can not get stream url')
        }

        const hls = createHLS({
          video,
          entity,
          range: {
            start: Math.round(currentRange.start / 1000),
            duration:
              currentRange.end === store.globalEnd
                ? Infinity
                : Math.round(currentRange.duration / 1000),
          },
          store,
          breakLimit,
          hlsStreamUrl: streamUrl,
        })

        hlsDisposers.add(() => {
          hls?.stopLoad()
          hls?.destroy()
        })

        store.setIsWaiting(true)

        const onCanPlay = () => store.setIsWaiting(false)
        const onEnded = () => store.startNextRange()

        const loadChunks = () =>
          hls!.startLoad(convertToPlayerTime(currentRange.start, store.currentTime, 0))

        plyr.once('canplay', onCanPlay)
        plyr.on('ended', onEnded)
        hasDuration && plyr.on('play', loadChunks)

        hlsDisposers.add(() => {
          store.setIsWaiting(false)
          plyr.off('canplay', onCanPlay)
        })

        hlsDisposers.add(() => {
          plyr.off('ended', onEnded)
          plyr.off('play', loadChunks)
        })

        hls.once(Hls.Events.MANIFEST_PARSED, () => {
          let chunkOffset = 0

          !hasDuration &&
            hls.startLoad(convertToPlayerTime(currentRange.start, store.currentTime, chunkOffset))

          hls.once(Hls.Events.LEVEL_LOADED, (_, data) => {
            const programDateTime = data.details.fragments[0].programDateTime
            chunkOffset = !programDateTime ? 0 : (currentRange.start - programDateTime) / 1000
            if (data.details.live) {
              hls?.on(Hls.Events.LEVEL_LOADED, (_, data) => {
                const { totalduration } = data.details
                const programDateTime = data.details.fragments[0].programDateTime
                chunkOffset = !programDateTime ? 0 : (currentRange.start - programDateTime) / 1000
                store.grow(breakLimit, totalduration - chunkOffset)
              })
            }
          })

          const syncTime = debounce(500, () => {
            plyr.currentTime = convertToPlayerTime(
              currentRange.start,
              store.currentTime,
              chunkOffset,
            )
          })

          hlsDisposers.add(() => syncTime.cancel())
          let seeking = false

          hlsDisposers.add(
            onAction(store, (action) => {
              if (action.name === 'setCurrentTime') {
                const [, source] = action.args as [number, string | undefined]
                if (source !== 'player') {
                  syncTime.call()
                  if (!seeking) {
                    seeking = true
                    const dispose = () => {
                      plyr.off('seeked', onSeeked)
                      plyr.off('play', onPlay)
                    }
                    hlsDisposers.add(dispose)
                    const onPlay = () => {
                      syncTime.flush()
                    }
                    plyr.once('play', onPlay)
                    const onSeeked = () => {
                      seeking = false
                      plyr.off('play', onPlay)
                      hlsDisposers.remove(dispose)
                    }
                    plyr.once('seeked', onSeeked)
                  }
                }
              }
            }),
          )

          const onTimeUpdate = () => {
            if (plyr.currentTime < chunkOffset) {
              plyr.currentTime = chunkOffset
            }
            if (!seeking) {
              const newTime = convertToStoreTime(currentRange.start, plyr.currentTime, chunkOffset)
              store.setCurrentTime(newTime, 'player')
            }
          }

          plyr.on('timeupdate', onTimeUpdate)
          hlsDisposers.add(() => plyr.off('timeupdate', onTimeUpdate))
        })

        if (store.isPlaying) await plyr.play()
      },
      { fireImmediately: true },
    ),
  )
  return disposers.flush
}
