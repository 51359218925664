import { useEffect, useState } from 'react'
import { useWidgetProps } from '../Root'
import { useApiRepository } from './useApiRepository'
import { loadPreview, PREVIEW_INTERVAL } from '../requests/loadPreview'

export const useTooEarly = () => {
  const {
    props: { cameraId, entity, initialTime },
  } = useWidgetProps()
  const { api } = useApiRepository()
  const [tooEarly, setTooEarly] = useState(() => inLimitRange(initialTime))

  useEffect(() => {
    if (!initialTime || !tooEarly || !cameraId || !entity) return
    const abr = new AbortController()

    const retry: () => Promise<unknown> = () =>
      loadPreview(
        api,
        entity,
        initialTime,
        abr.signal,
        false,
      ).catch(() => {
        if (!abr.signal.aborted && inLimitRange(initialTime)) {
          return wait(PREVIEW_INTERVAL * 1000).then(retry)
        }

        return null
      })

    retry().then(() => setTooEarly(false))

    return () => {
      abr.abort()
    }
  }, [cameraId, initialTime, tooEarly, entity, api])

  if (!cameraId) return false

  return tooEarly
}

const inLimitRange = (initialTime: number | undefined) =>
  Boolean(initialTime && isBetween(Date.now() - initialTime, 0, 10 * PREVIEW_INTERVAL * 1000))
const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

function isBetween<T>(value: T, min: T, max: T) {
  return value > min && value < max
}
