import { IEntity } from '@netvision/lib-api-repo'
import { useUploadedFile, useStream, useRecord, useError } from '../../hooks'

export const entityHookFabric = (entity: IEntity) => {
  switch (entity.type) {
    case 'Record':
      return useRecord
    case 'File':
      return useUploadedFile
    case 'Camera':
      return useStream
    case 'AssignmentEvent':
      return useStream
    default:
      return useError
  }
}

export const getArchiveEntity = (cameraId?: string, entity?: ArchiveEntities): IEntity => {
  if (cameraId) return { id: cameraId, type: 'Camera' }
  if (entity) return entity as unknown as IEntity
  return { id: '', type: '' }
}
