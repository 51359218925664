import {IAdapterFactory} from '../IEventAdapter';
import {loadEntitiesAsMap} from '../common/loadEntitiesAsMap';
import {IAssignmentEventType} from './IAssignmentEventType';
import {parseDate} from '../common/parseDate';
import {isArray, isExact, isNumber, isShape, isString, refine} from '../../../../utils/basicValidators';
import {IAssignmentEvent} from '@netvision/lib-api-gateway';
import {IEventModel, IStatus} from '../../models';

export const createAssignmentAdapter: IAdapterFactory = (statusLocale) => {
  let eventTypesPromise: null | Promise<Map<string, IAssignmentEventType>> = null;
  const settings = {
    useGroups: true
  };
  return {
    settings,
    convert: async (input, signal) => {
      if (!eventTypesPromise) {
        eventTypesPromise = loadEntitiesAsMap<IAssignmentEventType>(
          'AssignmentEventType',
          (en) => en.name,
          (en) => {
            if (en.icon?.color && en.icon.color.startsWith('--')) {
              en.icon.color = `var(${en.icon.color})`;
            }
            return en;
          }
        );
      }
      const eventTypes = await eventTypesPromise;
      if (signal.aborted || !isAssignmentEvent(input)) {
        return null;
      }
      const eventType = eventTypes.get(input.eventType);
      let eventTypeValue: IEventModel['eventType'];
      if (eventType) {
        eventTypeValue = {
          name: eventType.name,
          title: eventType.title,
          color: eventType.icon?.color,
          icon: eventType.icon?.iconClass
        };
      } else {
        eventTypeValue = {
          name: input.eventType,
          title: input.eventType
        };
      }
      const timestamp = parseDate(input.timestamp);
      if (!timestamp) {
        return null;
      }
      const statusTitle = statusLocale?.[input.status] ?? input.status ?? '';
      const {color, icon} = statusMap[input.status] ?? {};
      const output: IEventModel = {
        id: input.id,
        timestamp,
        endAt: parseDate(input.endAt) ?? undefined,
        eventType: eventTypeValue,
        status: {
          title: statusTitle,
          color,
          icon
        },
        group: {
          id: input.assignmentId ?? '',
          title: input.assignment?.title ?? ''
        },
        originalEvent: input
      };
      if (input.handlingTimeout) {
        output.expires =
          (input.handlingTimeout ? parseDate(input.handlingTimeout + timestamp) : undefined) ?? undefined;
      }
      if (hasTracks(input)) {
        output.tracks = input.tracks;
      }
      if (input.assignment?.parameters?.points) {
        output.trackableArea = {
          title: input.assignment.title,
          points: input.assignment?.parameters.points
        };
      }
      return output;
    },
    destroy: () => {}
  };
};

const hasAssignmentType = isShape({id: isString, type: isExact('AssignmentEvent')});
const isAssignmentEvent = hasAssignmentType as (v: unknown) => v is IAssignmentEvent & {[k: string]: unknown};

const isBbox = refine(isArray(isNumber), (v): v is [number, number, number, number] => v.length === 4);
const hasTracks = isShape({
  tracks: isArray(
    isShape({
      bbox: isBbox,
      timestamp: isNumber
    })
  )
});

const statusMap: Partial<Record<string, Omit<IStatus, 'title'>>> = {
  ['NotHandled']: {
    icon: 'mdi-alert',
    color: 'var(--warning-color)'
  },
  ['Handled']: {
    icon: 'mdi-alert',
    color: 'var(--success-color)'
  },
  ['ViolationDetected']: {
    icon: 'mdi-alert',
    color: 'var(--error-color)'
  }
};
