/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import React, {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {IStore} from '../../StoreModel';
import {ProgressSpinner} from 'primereact/progressspinner';

// language=SCSS
const $container = css`
  & {
    pointer-events: none;

    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(60rem / var(--bfs)) !important;
    height: calc(60rem / var(--bfs)) !important;
    transform: translateY(-50%) translateX(-50%);
  }
  &[data-visible='true'] {
    opacity: 1;
    svg {
      animation-play-state: running;
    }
  }
  &[data-visible='false'] {
    opacity: 0;
    svg {
      animation-play-state: paused;
    }
  }
`;

// language=SCSS
const $spinner = css`
  body &.p-progress-spinner {
    pointer-events: none;
    display: block;
    font-size: calc(45rem / var(--bfs));
    width: 100% !important;
    height: 100% !important;
    border-radius: calc(20rem / var(--bfs));
    background: rgba(0, 0, 0, 0.1);
  }
  body & > svg {
    font-size: inherit;
    width: 1em !important;
    height: 1em !important;
  }
  body & > svg > circle {
    stroke-width: calc(4rem / var(--bfs));
  }
`;

export const Spinner: FC<{visible?: boolean}> = ({visible = true}) => {
  return (
    <div css={$container} data-visible={visible}>
      <ProgressSpinner css={$spinner} />
    </div>
  );
};

export const PlayerSpinner: FC<{store: IStore}> = observer(({store}) => {
  return <Spinner visible={store.isWaiting} />;
});
