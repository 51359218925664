/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { useLocale } from '../../hooks/useLocale'
import { FC, Fragment, ReactNode, useCallback, useRef, useState } from 'react'
import { removeForbidden } from '../../utils/removeForbidden'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import { IStore } from '../../StoreModel'
import { useStore } from '../../hooks/useStore'
import { observer } from 'mobx-react-lite'
import { genId } from '../../utils/genId'
import { E2EModule } from '../../__test__'
import { useApiRepository, useGetUserInfo } from '../../hooks'
import { getStreamUrl } from '../streamHandlers/standard'

const SaveStatuses = {
  init: 'init',
  processing: 'processing',
  success: 'success',
  error: 'error',
}

export const SaveButton: FC<{ saveRecord: IArchiveEntry['saveRecord'] }> = observer(
  ({ saveRecord }) => {
    const toastRef = useRef<Toast>(null!)
    const api = useApiRepository()
    const locale = useLocale()
    const store = useStore()
    const userInfo = useGetUserInfo()
    const [canExportModal, setVisibleCanExportModal] = useState(false)

    // У пользователя с отсутствием прав, streamId - пустая строка
    const isBlockedSelectRange =
      store.availableRanges
        .filter(({ streamId }) => !streamId)
        .some(
          (r) =>
            r.includes(store.exportRangesStart || 0, true) ||
            r.includes(store.exportEnd || 0, true),
        ) && store.isEnableLockingFeature

    const [dialog, showDialog] = useSaveDialog(
      store,
      useCallback(
        async (description: string) => {
          if (!saveRecord) return Promise.resolve()
          const ranges = store.exportRanges
          let streamId = store.activeStreamId
          if (ranges.length !== 0) {
            const start = ranges[0].start
            const end = ranges[ranges.length - 1].end
            if (store.isEnableLockingFeature) {
              const rangeIndex = store.findRangeIndex(start)
              const range = store.availableRanges[rangeIndex]
              if (range.streamId) streamId = range.streamId
            }
            return saveRecord(
              streamId,
              Math.ceil(start / 1000),
              Math.floor((end - start) / 1000),
              description,
            )
          }
          return Promise.resolve()
        },
        [store, saveRecord],
      ),
    )

    const download = useCallback(async () => {
      const { exportRanges } = store
      if (!store.exportStreamUrl || !exportRanges.length) return
      let exportStreamUrl = store.exportStreamUrl
      const start = exportRanges[0].start
      const end = exportRanges[exportRanges.length - 1].end
      try {
        const userId = localStorage.getItem('netvision:user-id')
        toastRef.current.show({
          severity: 'info',
          summary: 'Загрузка фрагмента начата!',
          life: 3000,
        })
        store.setIsWaiting(true)

        if (store.isEnableLockingFeature) {
          const rangeIndex = store.findRangeIndex(start)
          const range = store.availableRanges[rangeIndex]
          if (!range) throw Error('Can not find stream by binary search')
          const stream = await getStreamUrl(range.streamId, api.api)
          if (stream.exportStreamUrl) exportStreamUrl = stream.exportStreamUrl
        }

        window.open(
          `${exportStreamUrl}&start=${Math.ceil(start / 1000)}&end=${Math.ceil(
            end / 1000,
          )}&subjectTitle=${userInfo?.fullName}&subjectId=${userId}`,
          '_blank',
        )
      } catch (e) {
        console.error(e)
        toastRef.current.show({
          severity: 'error',
          summary: 'Ошибка загрузки архива!',
          detail: 'Пожалуйста повторите позже или обратитесь в службу поддержки',
          life: 3000,
        })
      } finally {
        store.setIsWaiting(false)
      }
    }, [store])

    const unselect = useCallback(() => store.setSelectionMode(false), [store])
    return (
      <Fragment>
        <Toast position="bottom-left" ref={toastRef} />
        {canExportModal && (
          <Dialog
            closable={false}
            appendTo={document.body}
            header={locale.panelLocale.attention}
            onHide={() => setVisibleCanExportModal(false)}
            footer={
              <Button
                label={locale.buttonActions.ok}
                onClick={() => setVisibleCanExportModal(false)}
              />
            }
            visible>
            <div css={blockedDialogContent}>{locale.panelLocale.exportBlocked}</div>
          </Dialog>
        )}

        <Button
          data-cy={E2EModule.attributes.openSaveFragmentModal}
          disabled={store.exportRanges.length === 0}
          className={'p-button-outlined p-button-sm'}
          onClick={isBlockedSelectRange ? () => setVisibleCanExportModal(true) : showDialog}
          label={locale.saveDialogLocale.save}
          css={$disabled}
        />
        {store.exportStreamUrl && (
          <Button
            disabled={store.exportRanges.length === 0}
            className={'p-button-outlined p-button-sm'}
            onClick={isBlockedSelectRange ? () => setVisibleCanExportModal(true) : download}
            label={locale.panelLocale.download}
            css={$disabled}
          />
        )}
        <Button
          disabled={store.exportRanges.length === 0}
          className={'p-button-outlined p-button-secondary p-button-sm'}
          onClick={unselect}
          label={locale.panelLocale.unselectLabel}
          css={$disabled}
        />
        {dialog}
      </Fragment>
    )
  },
)

const $disabled = css`
  & {
    max-width: calc(150rem / var(--bfs));
    opacity: 1;
    transition: border-width 200ms ease-in, margin 200ms ease-in, padding 200ms ease-in,
      max-width 200ms ease-in, opacity 200ms ease-out 200ms;

    :disabled {
      border-width: 0 !important;
      padding: 0 !important;
      margin: 0 !important;
      max-width: 0 !important;
      opacity: 0;
      transition: border-width 200ms ease-out 200ms, margin 200ms ease-out 200ms,
        padding 200ms ease-out 200ms, max-width 200ms ease-out 200ms, opacity 200ms ease-in;
    }
  }
`

function useSaveDialog(
  store: IStore,
  onSave: (description: string) => Promise<void>,
): [ReactNode, () => void] {
  const [id] = useState(genId)
  const locale = useLocale().saveDialogLocale
  const [show, setShow] = useState(false)
  const onShow = useCallback(() => {
    setShow(true)
  }, [])
  const onHide = useCallback(() => {
    setShow(false)
  }, [])
  const reset = useCallback(() => {
    onHide()
    setStatus(SaveStatuses.init)
    setDescription('')
  }, [onHide])
  const [status, setStatus] = useState(SaveStatuses.init)
  const [description, setDescription] = useState('')
  const onDescriptionChange = useCallback((e) => {
    const value = removeForbidden(e.target.value)
    setDescription(value)
  }, [])
  const handleSave = useCallback(() => {
    onSave(description).then(
      () => {
        setStatus(SaveStatuses.success)
      },
      () => {
        setStatus(SaveStatuses.error)
      },
    )
  }, [onSave, description])
  let dialog = null
  if ([SaveStatuses.init, SaveStatuses.processing].includes(status)) {
    const footer = (
      <Fragment>
        <Button
          data-cy={E2EModule.attributes.saveFragmentButton}
          disabled={status === SaveStatuses.processing}
          label={locale.save}
          icon="pi pi-check"
          onClick={handleSave}
        />
        <Button
          disabled={status === SaveStatuses.processing}
          label={locale.cancel}
          className={'p-button-secondary'}
          icon="pi pi-times"
          onClick={onHide}
        />
      </Fragment>
    )
    dialog = (
      <Dialog
        visible={show}
        onHide={onHide}
        modal={true}
        header={locale.header}
        footer={footer}
        closable={false}>
        <div css={dialogContentStyle}>
          <div className={'p-fluid p-formgrid p-input-filled'}>
            <div className={'p-field'}>
              <label htmlFor={id}>{locale.descriptionLabel}</label>
              <InputText
                data-cy={E2EModule.attributes.saveFragmentNameInput}
                id={id}
                type={'text'}
                value={description}
                onChange={onDescriptionChange}
              />
            </div>
          </div>
        </div>
      </Dialog>
    )
  }
  if ([SaveStatuses.success, SaveStatuses.error].includes(status)) {
    const footer = (
      <Fragment>
        <Button label={'OK'} icon="pi pi-check" onClick={reset} />
      </Fragment>
    )
    dialog = (
      <Dialog
        visible={show}
        onHide={onHide}
        header={locale.header}
        footer={footer}
        closable={false}>
        <div css={dialogContentStyle}>
          {status === SaveStatuses.success ? locale.success : locale.error}
        </div>
      </Dialog>
    )
  }
  return [show ? dialog : null, onShow]
}

const dialogContentStyle = css`
  & {
    padding: 0 var(--spacer);
    width: calc(400rem / var(--bfs));
    color: var(--text-color);
  }
  & input {
    width: 100%;
  }
`

const blockedDialogContent = css`
  padding: var(--spacer-xs) var(--spacer);
`
