import {createTboAdapter} from './tbo/createTboAdapter';
import {createAssignmentAdapter} from './assignment/createAssignmentAdapter';
import {IAdapterFactory} from './IEventAdapter';

export const selectAdapter =
  (type: string): IAdapterFactory =>
  (statusLocale) => {
    switch (type) {
      case 'TboEvent':
        return createTboAdapter(statusLocale);
      default:
        return createAssignmentAdapter(statusLocale);
    }
  };
