/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {FC} from 'react';
import {useStore} from '../../../hooks/useStore';

export const Segment: FC<{start: number; end: number}> = ({start, end, children}) => {
  const store = useStore();
  if (start >= end) {
    return null;
  }
  const limits = store.visibleTimeRange;
  start = Math.max(limits[0], start);
  end = Math.min(limits[1], end);
  const left = (start - store.tapeOffset) / store.scale;
  const width = (end - start) / store.scale;
  return (
    <div
      css={segmentCss}
      style={{
        left,
        width
      }}>
      {children}
    </div>
  );
};

// language=SCSS
const segmentCss = css`
  & {
    position: absolute;
    top: 0;
    height: 100%;
  }
`;
