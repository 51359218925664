/** @jsx jsx */
import {jsx} from '@emotion/react';
import {makeButton} from './makeButton';

export const Speed = makeButton((props) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.9999 6.6665C10.1186 6.6665 5.33325 11.4518 5.33325 17.3332C5.33325 23.2145 10.1186 27.9998 15.9999 27.9998C21.8813 27.9998 26.6666 23.2145 26.6666 17.3332C26.6666 11.4518 21.8813 6.6665 15.9999 6.6665ZM15.9999 25.3332C11.5879 25.3332 7.99992 21.7452 7.99992 17.3332C7.99992 12.9212 11.5879 9.33317 15.9999 9.33317C20.4119 9.33317 23.9999 12.9212 23.9999 17.3332C23.9999 21.7452 20.4119 25.3332 15.9999 25.3332Z"
        fill="#959DA8"
      />
      <path
        d="M14.6666 11.9998H17.3333V18.6665H14.6666V11.9998ZM11.9999 2.6665H19.9999V5.33317H11.9999V2.6665Z"
        fill="#959DA8"
      />
      <path d="M25.7235 10.2758L23.0573 7.60948L24.943 5.72389L27.6092 8.3902L25.7235 10.2758Z" fill="#959DA8" />
    </svg>
  );
});
