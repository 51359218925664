/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {useStore} from '../../../hooks/useStore';
import {FC, useCallback} from 'react';
import {useHoldAndMove} from '../utils';
import {observer} from 'mobx-react-lite';
import {E2EModule} from '../../../__test__'

// language=SCSS
const tapeHandleStyle = css`
  & {
    position: absolute;
    bottom: calc(2rem / var(--bfs));
    z-index: 0;
    width: 100%;
    height: calc(100% - 2rem / var(--bfs));
    cursor: move;
  }
`;

export const TapeMoveArea: FC = observer(function TapeMoveArea() {
  const store = useStore();
  const setUp = useCallback(() => {
    return {
      time: store.currentTime
    };
  }, [store]);
  const onMove = useCallback(
    (diff, initialData) => {
      store.setCurrentTime(initialData.time + diff * store.scale);
    },
    [store]
  );
  const onPointerDown = useHoldAndMove(onMove, setUp);
  return (
    <div
      data-cy={E2EModule.attributes.timeLineMoveArea}
      css={tapeHandleStyle}
      onPointerDown={onPointerDown}
      style={store.selectionActive ? {pointerEvents: 'none'} : undefined}
    />
  );
});
