import { CubeMixin, TUnionRepo } from '@netvision/lib-api-repo'
import { LibApiRepository } from '@netvision/lib-api-repo/dist/src/repositories/LibApiRepository'

const isCubeMixinsApi = (api: TUnionRepo): api is CubeMixin & LibApiRepository =>
  'cubeGetEntities' in api

interface IEntity {
  id: string
}

export async function saveArchiveRecord(
  api: TUnionRepo,
  streamId: string,
  from: number,
  duration: number,
  description?: string,
) {
  const saveRecord = {
    start: from,
    end: from + duration,
    description: description || new Date().toISOString(),
  }

  await api.updateEntity({
    type: 'Stream',
    id: streamId,
    saveRecord,
  })
}

export async function getCameraStreams(
  api: TUnionRepo,
  cameraId: IEntity['id'],
): Promise<IStream[]> {
  const hasGlobalBatch = 'getEntitiesWithGlobalBatch' in api
  const hasCubeEntities = 'cubeGetEntities' in api
  if (!hasCubeEntities || !hasGlobalBatch) {
    console.error('Can not implements method getEntitiesWithGlobalBatch or cubeGetEntities')
    return []
  }

  if (!isCubeMixinsApi(api)) return []

  const { results } = await api?.cubeGetEntities<{ id: string }>({
    dimensions: ['Stream.id'],
    filters: [
      {
        member: 'Stream.cameraId',
        operator: 'equals',
        values: [cameraId],
      },
    ],
  })

  const promises = results.map(({ id }) =>
    api.getEntitiesWithGlobalBatch(
      { type: 'Stream', id },
      [
        'hlsStreamUrl',
        'title',
        'streamType',
        'dvrTimelines',
        'id',
        'streamUrl',
        'exportStreamUrl',
        'cameraId',
        'orderIndex',
        'externalSystemName',
      ],
      'player-archive',
    ),
  )

  const streams = (await Promise.all(promises)) as IStream[]
  return streams
}

export async function getStream(api: TUnionRepo, streamId: string, attrs?: string) {
  const { results } = await api.getEntitiesList<IStream>({
    limiter: {
      id: streamId,
      type: 'Stream',
      keyValues: true,
    },
    filter: {
      attrs:
        attrs ||
        'hlsStreamUrl,title,streamType,dvrTimelines,id,streamUrl,exportStreamUrl,cameraId,externalSystemName',
    },
  })

  const [entity] = results
  if (!entity) {
    console.error(Error('There is no archive with such ID'), streamId, entity)
    return null
  }

  if (!Array.isArray(entity.dvrTimelines) || entity.dvrTimelines.length === 0) {
    console.error(Error('Archive does not have any available range'), streamId, entity)
    return null
  }

  entity.dvrTimelines.sort((a, b) => a.start - b.start)
  return entity
}
