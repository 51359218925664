export const debounce = <F extends (...args: any[]) => void>(delay: number, func: F) => {
  let id = -1;
  let call: null | (() => void) = null;
  const invoke = () => call?.();
  return {
    call: new Proxy(func, {
      apply(target, thisArg, argArray) {
        call = () => Reflect.apply(target, thisArg, argArray);
        id = window.setTimeout(invoke, delay);
      }
    }) as F,
    cancel: () => {
      call = null;
      clearTimeout(id);
    },
    flush: () => {
      clearTimeout(id);
      invoke();
    }
  };
};
