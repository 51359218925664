/** @jsx jsx */
import {jsx} from '@emotion/react';
import {FC, useEffect, useState} from 'react';
import {useCanvas} from './canvas/useCanvas';
import {EventMarks} from './EventMarks';
import {HelperStoreProvider} from './useHelperStore';
import {createPortal} from 'react-dom';
import {IEventModel} from './models';
import {selectAdapter} from './eventAdapters/selectAdapter';
import {isShape, isString} from '../../utils/basicValidators';
import {useLocale} from '../../hooks/useLocale';
import {ColorProvider} from './useColors';

export const SingleEvent: FC<{event: unknown; marksPortalNode: HTMLElement}> = ({event, marksPortalNode}) => {
  const {eventStatusLocales} = useLocale();
  const [convertedEvent, setConvertedEvent] = useState<IEventModel | null>(null);
  useEffect(() => {
    if (!hasType(event)) return;
    const adapter = selectAdapter(event.type);
    const abr = new AbortController();
    adapter(eventStatusLocales[event.type]).convert(event, abr.signal).then(setConvertedEvent);
    return () => {
      abr.abort();
    };
  }, [event, eventStatusLocales]);
  return (
    convertedEvent && (
      <HelperStoreProvider>
        <ColorProvider>
          <Canvas event={convertedEvent} />
          {createPortal(<EventMarks events={[convertedEvent]} />, marksPortalNode)}
        </ColorProvider>
      </HelperStoreProvider>
    )
  );
};

const Canvas: FC<{event: IEventModel}> = ({event}) => {
  useCanvas(event);
  return null;
};

const hasType = isShape({
  type: isString
});
