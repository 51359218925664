/** @jsx jsx */
import { css } from '@emotion/react'

// language=SCSS
export const buttonCss = css`
   {
    position: absolute;
    left: 50%;
    bottom: var(--spacer);
    transform: translateX(-50%);
  }
`
