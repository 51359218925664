import {useEffect, useMemo, useRef, useState} from 'react';
import {createCamerasConnection, getSharedNotificationSocket, IEntity, listEntities} from '@netvision/lib-api-gateway';
import {createRangeMap} from '../../utils/rangeMap';
import {IStore} from '../../StoreModel';
import {reaction} from 'mobx';
import {IEventModel} from './models';
import {get, isEqual} from 'lodash-es';
import {selectAdapter} from './eventAdapters/selectAdapter';
import {useLocale} from '../../hooks/useLocale';
import {createDisposers} from '../../utils/disposers';
import {IEventAdapter} from './eventAdapters/IEventAdapter';

export function useEvents(
  store: IStore,
  eventAdapter: IEventAdapter,

  options: {
    cameraId: string;
    eventEntityType: string;
    cameraField: string;
    entityType: IArchiveEntry['entity']['type'];
  }
) {
  const {cameraId, eventEntityType, cameraField, entityType} = options;
  const eventMap = useMemo(() => new Map<string, IEventModel>(), []);
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState<IEventModel[]>([]);

  useEffect(() => eventAdapter.destroy, [eventAdapter]);
  const lastPrefetchWindow = useRef<[number, number]>();
  const addEvents = useMemo(() => {
    return async (evs: IEntity[], signal: AbortSignal) => {
      for (const ev of evs) {
        if (signal.aborted) {
          break;
        }
        const converted = await eventAdapter.convert(ev, signal);
        if (converted) {
          eventMap.set(converted.id, converted);
        }
      }

      if (!signal.aborted) {
        setEvents([...eventMap.values()].sort((a, b) => a.timestamp - b.timestamp));
      }
    };
  }, [setEvents, eventMap, eventAdapter]);
  useEffect(() => {
    const rangeMap = createRangeMap();

    const load = getEventLoader({
      cameraId,
      cameraField,
      eventEntityType,
      entityType
    });

    let cleanUp = createDisposers();
    const dispose = reaction(
      () => store.prefetchWindow,
      (range) => {
        if (lastPrefetchWindow.current && isEqual(range, lastPrefetchWindow.current)) return;
        cleanUp.flush();
        cleanUp = createDisposers();

        const abr = new AbortController();
        cleanUp.add(() => abr.abort());
        lastPrefetchWindow.current = range;
        setLoading(true);
        const rangesToFetch = rangeMap.addRange(range);
        const addingPromises: Promise<void>[] = [];
        const loadingPromises = rangesToFetch.map((range) =>
          load(range, (evt) => {
            const events =
              entityType === 'Record' ? normalizeEventTime(evt as RecordEventType[], store.visibleTimeRange[0]) : evt;
            addingPromises.push(addEvents(events, abr.signal));
          })
        );
        Promise.all(loadingPromises)
          .then(() => Promise.all(addingPromises))
          .finally(() => {
            if (!abr.signal.aborted) {
              setLoading(false);
            }
          });
      },
      {delay: 1000, fireImmediately: true}
    );
    return () => {
      dispose();
      cleanUp.flush();
    };
  }, [addEvents, cameraId, store, cameraField, eventEntityType, entityType]);

  useEffect(() => {
    // use same shared socket
    const socket = getSharedNotificationSocket();
    const abr = new AbortController();
    const dispose = socket.addListener<IEntity>(eventEntityType, (ev) => {
      if (cameraId === get(ev, cameraField)) {
        addEvents([ev], abr.signal);
      }
    });
    return () => {
      dispose();
      abr.abort();
    };
  }, [addEvents, cameraId, cameraField, eventEntityType]);

  return [loading, events] as const;
}

type RecordEventType = IEntity & {timestamp?: number; endAt: number | null};

const normalizeEventTime = (events: RecordEventType[], start: number) => {
  return events.map((evt) => ({
    ...evt,
    timestamp: evt?.timestamp ? evt?.timestamp + start : start,
    endAt: evt?.endAt ? evt?.endAt + start : null
  }));
};

const getEventLoader = (options: {
  cameraId: string;
  eventEntityType: string;
  cameraField: string;
  entityType: IArchiveEntry['entity']['type'];
}) => {
  const {eventEntityType, cameraField, cameraId, entityType} = options;
  // To load everything, multiple request may be needed since max limit is 1000
  // onLoad is triggered after each request
  return ([start, end]: [number, number], onLoad: (events: IEntity[]) => void): Promise<void> => {
    const fetch = (opts: typeof options) => listEntities<IEntity>(createCamerasConnection(), opts);
    const load = (opts: typeof options): Promise<void> => {
      let numberOfRetries = 2;
      return fetch(opts)
        .catch((e) => {
          console.error(e);
          console.warn(`Retry attempts left - ${numberOfRetries}.`);
          if (numberOfRetries === 0) {
            return null;
          } else {
            numberOfRetries -= 1;
            return fetch(opts);
          }
        })
        .then((res) => {
          if (res !== null) {
            onLoad(res.results);
            if (res.count > res.offset + res.limit) {
              return load({
                ...opts,
                offset: res.offset + res.limit
              });
            }
          }
          return Promise.resolve();
        });
    };

    const q =
      entityType === 'Record'
        ? `${cameraField}=='${cameraId}'`
        : `timestamp>${start};timestamp<${end};${cameraField}=='${cameraId}'`;

    const options = {
      type: eventEntityType,
      q,
      limit: 1000,
      offset: 0,
      orderBy: '!timestamp',
      count: true
    };
    return load(options);
  };
};
