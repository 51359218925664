/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {Speed} from '../../ui/buttons/Speed';
import {useLocale} from '../../../hooks/useLocale';
import {WithPopup} from '../../ui/WithPopup';
import {E2EModule} from '../../../__test__';

interface ISpeedOption {
  label: string;
  value: number;
}

export interface VolumeControlProps {
  /** number from 0.5 to 2 */
  value: number;
  onChange: (newValue: number) => void;
}

export const SpeedControl: FC<VolumeControlProps> = ({value, onChange}) => {
  const locale = useLocale();
  const defaultIndex = 3;
  const [speedIndex, setSpeedIndex] = useState(defaultIndex);
  const speedOptions = useMemo<ISpeedOption[]>(
    () => [
      {label: '0.25', value: 0.25},
      {label: '0.5', value: 0.5},
      {label: '0.75', value: 0.75},
      {label: locale.panelLocale.normalSpeed, value: 1},
      {label: '1.25', value: 1.25},
      {label: '1.5', value: 1.5},
      {label: '2', value: 2},
      {label: '4', value: 4},
    ],
    [locale]
  );
  const current = speedOptions[speedIndex];
  useEffect(() => {
    // reset if value is not fro
    if (value !== current.value) {
      setSpeedIndex(defaultIndex);
    }
    // eslint-disable-next-line
  }, [value]);
  useEffect(() => {
    if (value !== current.value) {
      onChange(current.value);
    }
    // eslint-disable-next-line
  }, [current]);

  const openState = useState(false);
  const [, setOpen] = openState;
  const onSelect = useCallback<Exclude<JSX.IntrinsicElements['li']['onClick'], undefined>>(
    (e) => {
      setSpeedIndex(Number(e.currentTarget.dataset['index']));
      setOpen(false);
    },
    [setOpen]
  );
  const button = (
    <Speed
      data-cy={E2EModule.attributes.changeSpeedButton}
      css={s.button}
      aria-haspopup={'listbox'}
      tooltip={'Скорость'}>
      <div css={s.value} data-default={defaultIndex === speedIndex}>{`x ${current.value}`}</div>
    </Speed>
  );
  const content = (
    <div css={s.list}>
      <ul data-cy={E2EModule.attributes.changeSpeedList} role={'listbox'}>
        {speedOptions.map((option, index) => {
          return (
            <li key={index} data-index={index} onClick={onSelect} role={'option'} aria-selected={index === speedIndex}>
              {option.label}
            </li>
          );
        })}
      </ul>
    </div>
  );
  return <WithPopup state={openState} button={button} popup={content} />;
};

// language=SCSS
const s = {
  button: css`
    &[aria-expanded='true'] > div[data-role='tooltip'] {
      opacity: 0;
    }
  `,
  list: css`
    & {
      position: absolute;
      top: 0;
      left: 85%;
      transform: translateY(-100%);

      background: var(--surface-b);
      border-radius: var(--border-radius);
      overflow: hidden;

      box-shadow: var(--shadow-raised);

      > ul {
        list-style: none;
        margin: 0;
        padding: 0;
        cursor: pointer;

        > li {
          padding: calc(5rem / var(--bfs)) calc(10rem / var(--bfs));
          color: var(--text-color-secondary);
          transition: color 200ms ease;

          font-size: 1rem;
          text-align: left;
          font-weight: 500;

          :hover {
            color: var(--text-color);
          }
          :focus {
            color: var(--text-color);
          }
        }

        > li[aria-selected='true'] {
          background: var(--primary-color);
          color: var(--text-color-active);
        }
      }
    }
  `,
  value: css`
    & {
      position: absolute;
      top: 77%;
      left: 85%;
      transform: translateY(-50%);

      border-radius: var(--border-radius);
      padding: calc(2rem / var(--bfs)) calc(6rem / var(--bfs));

      background: var(--primary-color);
      color: var(--text-color-active);
      font-weight: 600;
      font-size: calc(11rem / var(--bfs));
      white-space: nowrap;
    }
    &[data-default='true'] {
      background: var(--surface-a);
      color: var(--text-color-secondary);
    }
  `
};
